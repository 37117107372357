import React, { Component } from 'react';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import "./CarouselSlider.css";

export default class CarouselSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemsAlignment: this.props.sliderOptions.itemsAlignment ?? 'top',
            itemWidth: this.props.sliderOptions.itemWidth ?? undefined,
            responsive: this.props.sliderOptions.responsive ?? undefined,
            size: this.props.size || 'medium'
        };
        this.gliderRef = React.createRef();
        this.setItemsAlignment = this.setItemsAlignment.bind(this);
    }

    addItem(element) {
        this.gliderRef.current.addItem(element);
    }

    removeItem(index) {
        this.gliderRef.current.removeItem(index);
    }

    setItemsAlignment(style) {
        this.setState({
            itemsAlignment: style
        });
    }

    setItemsSize(sizes) {
        this.setState({
            itemWidth: sizes.itemWidth ?? undefined,
            responsive: sizes.responsive ?? undefined,
        });

        //this.gliderRef.current.setOption(options, true);
        //this.gliderRef.current.refresh();
    }

    render() {
        const gliderProps = {
            className: this.props.sliderOptions.sliderClassName ?? '',
            slidesToShow: this.props.sliderOptions.slidesToShow ?? 4,
            draggable: this.props.sliderOptions.draggable ?? true,
            hasArrows: this.props.sliderOptions.hasArrows ?? true,
            scrollLock: this.props.sliderOptions.scrollLock ?? true,
            hasDots: this.props.sliderOptions.hasDots ?? false,
            exactWidth: this.props.sliderOptions.exactWidth ?? undefined,
            arrows: this.props.sliderOptions.sliderNextButton && this.props.sliderOptions.sliderPreviousButton ? {
                prev: this.props.sliderOptions.sliderPreviousButton,
                next: this.props.sliderOptions.sliderNextButton,
            } : undefined,
            itemWidth: this.state.itemWidth,
            responsive: this.state.responsive,
        };


        const wrapperProps = {
            className: this.props.className ?? ''
        };


        const { itemsAlignment } = this.state;

        if (itemsAlignment === 'middle') {
            wrapperProps.className += ' glider-itemsalign-middle';
        } else if (itemsAlignment === 'bottom') {
            wrapperProps.className += ' glider-itemsalign-bottom';
        }


        /*
        if (size === 'small') {
            wrapperProps.className += ' items-size';
        } else if (size === 'large') {
            wrapperProps.className += ' d-flex align-items-end';
        } else {

        }*/

        return (
            <div {...wrapperProps}>
                <Glider ref={this.gliderRef} {...gliderProps}>
                    {this.props.children}
                </Glider>
            </div>
        );
    }    
}
