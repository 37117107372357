import React, { forwardRef } from 'react';
import { Image } from '../../common/UIElements';
import { ProductMediaConfig } from '../../utils/MediaConfigs';

export const ProductDetailsGalleryItem = forwardRef(({ id, file, style, ...props }, ref) => {
    const inlineStyles = {
        width: '100%',
        ...style,
    };
    return (
        <div {...props} className="bg-white rounded box-shadow" style={inlineStyles}>
            <Image fileName={file.fileName}
                isDraft={file.isDraft}
                mediaConfig={ProductMediaConfig.Thumbnail}
                className="d-block position-relative w-100 p-1"
            />
        </div>
    )
});