import React, { Component, Fragment } from 'react';
import { DataSavedAlertMessage } from './DataSavedAlertMessage';
import { DataSubmittedAlertMessage } from './DataSubmittedAlertMessage';
import { DataRejectedAlertMessage } from './DataRejectedAlertMessage';

export class DataDraftAlertMessage extends Component {
    static displayName = DataDraftAlertMessage.name;

    constructor(props) {
        super(props);
        this.onSubmitToReview = this.onSubmitToReview.bind(this);
        this.onRevertBackData = this.onRevertBackData.bind(this);
    }

    onSubmitToReview(event) {
        this.props.onSubmitToReview(event);
    }

    onRevertBackData(event) {
        this.props.onRevertBackData(event);
    }

    render() {
        const { dataDraftStatus, allowSubmitToReview } = this.props;

        console.log(dataDraftStatus);

        if (dataDraftStatus === 0) {
            //data saved// ready to submit
            return (<DataSavedAlertMessage
                onSubmitToReview={this.onSubmitToReview}
                onRevertBackData={this.onRevertBackData}
                allowSubmitToReview={allowSubmitToReview}
            />);
        } else if (dataDraftStatus === 1) {
            //data is submitted//waiting for review by admin
            return (<DataSubmittedAlertMessage onRevertBackData={this.onRevertBackData} />);
        } else if (dataDraftStatus === 2) {
            //data is rejected//you need to change data
            return (<DataRejectedAlertMessage onRevertBackData={this.onRevertBackData} />);
        } else {
            return (<Fragment />);
        }
    }
}
