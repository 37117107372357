import { make } from '../../utilities/Utilities';

/**
 * Working with Block Tunes
 */
export default class TextWithMediaBlockTunes {
    /**
     * @param {object} tune - image tool Tunes managers
     * @param {object} tune.api - Editor API
     * @param {object} tune.actions - list of user defined tunes
     * @param {Function} tune.onChange - tune toggling callback
     */
    constructor({ api, actions, onChange }) {
        this.api = api;
        this.actions = actions;
        this.onChange = onChange;

        //this.buttons = [];
    }

    /**
     * Available TextWithMedia tunes
     *
     * @returns {{name: string, icon: string, title: string}[]}
     */
    static get tunes() {
        return [
            {
                name: 'stretched',
                toggle: true,
                icon: '<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>',
                title: 'Stretched',
            },
            {
                name: 'imageDirection',
                toggle: 'imageDirection',
                value: 'left',
                icon: '<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M16.6743 9.1716L15.26 7.75739L11.0174 12L15.26 16.2426L16.6742 14.8284L13.8458 12L16.6743 9.1716Z" fill="currentColor"/><path d="M9.96265 8.00007V16.0001H7.96265V8.00007H9.96265Z" fill="currentColor"/><path clip-rule="evenodd" d="M1 5C1 2.79086 2.79086 1 5 1H19C21.2091 1 23 2.79086 23 5V19C23 21.2091 21.2091 23 19 23H5C2.79086 23 1 21.2091 1 19V5ZM5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3Z" fill="currentColor" fill-rule="evenodd"/></svg>',
                title: 'Image to the left',
            },
            {
                name: 'imageDirection',
                toggle: 'imageDirection',
                value: 'right',
                icon: '<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7.64429 14.8284L9.0585 16.2426L13.3012 12L9.05853 7.75739L7.64432 9.1716L10.4727 12L7.64429 14.8284Z" fill="currentColor"/><path d="M14.3559 16L14.3559 7.99996H16.3559V16H14.3559Z" fill="currentColor"/><path clip-rule="evenodd" d="M1 5C1 2.79086 2.79086 1 5 1H19C21.2091 1 23 2.79086 23 5V19C23 21.2091 21.2091 23 19 23H5C2.79086 23 1 21.2091 1 19V5ZM5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3Z" fill="currentColor" fill-rule="evenodd"/></svg>',
                title: 'Image to the right',
            },
            {
                name: 'textAlignment',
                toggle: 'textAlignment',
                value: 'left',
                title: 'Align text to left',
                icon: '<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M30 30h-24v4h24v-4zm0-16h-24v4h24v-4zm-24 12h36v-4h-36v4zm0 16h36v-4h-36v4zm0-36v4h36v-4h-36z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>',
            },
            {
                name: 'textAlignment',
                toggle: 'textAlignment',
                value: 'center',
                title: 'Align text to center',
                icon: '<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14 30v4h20v-4h-20zm-8 12h36v-4h-36v4zm0-16h36v-4h-36v4zm8-12v4h20v-4h-20zm-8-8v4h36v-4h-36z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>',
            },
            {
                name: 'textAlignment',
                toggle: 'textAlignment',
                value: 'right',
                title: 'Align text to right',
                icon: '<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M6 42h36v-4h-36v4zm12-8h24v-4h-24v4zm-12-8h36v-4h-36v4zm12-8h24v-4h-24v4zm-12-12v4h36v-4h-36z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>',
            },
            {
                name: 'textboxVerticalAlign',
                toggle: 'textboxVerticalAlign',
                value: 'top',
                icon: '<svg id="Layer_1" height="22" width="22" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Layer_1_1_"><path d="M1,49h48V1H1V49z M3,3h44v44H3V3z"/><rect height="2" width="36" x="7" y="19"/><rect height="2" width="28" x="11" y="13"/><rect height="2" width="36" x="7" y="7"/></g></svg>',
                title: 'Top Text',
            },
            {
                name: 'textboxVerticalAlign',
                toggle: 'textboxVerticalAlign',
                value: 'middle',
                icon: '<svg id="Layer_1" height="22" width="22" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Layer_1_1_"><path d="M1,49h48V1H1V49z M3,3h44v44H3V3z"/><rect height="2" width="36" x="7" y="30"/><rect height="2" width="28" x="11" y="24"/><rect height="2" width="36" x="7" y="18"/></g></svg>',
                title: 'Middle Text',
            },
            {
                name: 'textboxVerticalAlign',
                toggle: 'textboxVerticalAlign',
                value: 'bottom',
                icon: '<svg id="Layer_1" height="22" width="22" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Layer_1_1_"><path d="M49,1H1v48h48V1z M47,47H3V3h44V47z"/><rect height="2" width="36" x="7" y="41"/><rect height="2" width="28" x="11" y="35"/><rect height="2" width="36" x="7" y="29"/></g></svg>',
                title: 'Bottom Text',
            }
        ];
    }

    render(toolData) {
        const tunes = TextWithMediaBlockTunes.tunes.concat(this.actions);

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            toggle: tune.toggle,
            isActive: (tune.value && (toolData[tune.name] === tune.value)) || (tune.toggle === true && toolData[tune.name]),
            onActivate: () => {
                if (typeof tune.action === 'function') {
                    tune.action(tune.name, tune.value);
                    return;
                }
                this.onChange(tune.name, tune.value);
            },
        }));
    }

    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        const confirmed = TextWithMediaBlockTunes.tunes.find(item => item.name === tuneName && item.value === tuneValue);
        if (confirmed !== undefined) {
            return confirmed.value;
        }
        return TextWithMediaBlockTunes.tunes.filter(item => item.name === tuneName)[0].value;
    }

    /**
     * Styles
     *
     * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
     */
    /*
    get CSS() {
        return {
            wrapper: '',
            buttonBase: this.api.styles.settingsButton,
            button: 'textwithmedia-block__tune',
            buttonActive: this.api.styles.settingsButtonActive,
        };
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch
     *
     * @param {ToolData} toolData - generate Elements of tunes
     * @returns {Element}
     */
    /*
    render(toolData) {
        this.buttons = [];

        const wrapper = make('div', this.CSS.wrapper);

        this.renderSection(
            TextWithMediaBlockTunes.tunes.imageDirections.concat(this.actions),
            toolData,
            wrapper);

        this.renderSection(
            TextWithMediaBlockTunes.tunes.textAlignments.concat(this.actions),
            toolData,
            wrapper);

        this.renderSection(
            TextWithMediaBlockTunes.tunes.textboxVerticalAlign.concat(this.actions),
            toolData,
            wrapper);

        this.renderSection(
            TextWithMediaBlockTunes.tunes.general.concat(this.actions),
            toolData,
            wrapper);

        return wrapper;
    }

    renderSection(tunes, toolData, wrapper) {
        const btnsContainer = wrapper.appendChild(make('div', 'cdx-settings-container'));

        tunes.forEach(tune => {
            const title = this.api.i18n.t(tune.title);
            const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
                innerHTML: tune.icon,
                title,
            });

            el.addEventListener('click', () => {
                this.tuneClicked(tune.name, tune.value, tune.action);
            });

            el.dataset.tuneName = tune.name;
            el.dataset.tuneValue = tune.value;

            el.classList.toggle(this.CSS.buttonActive,
                (tune.value === undefined && this.confirmTuneValueOrGetFirstValue(tune.name, toolData[tune.name]) === true) ||
                (tune.value === this.confirmTuneValueOrGetFirstValue(tune.name, toolData[tune.name]))
            );
            //el.classList.toggle(this.CSS.buttonActive, toolData[tune.name]);

            this.buttons.push(el);

            this.api.tooltip.onHover(el, title, {
                placement: 'top',
            });

            btnsContainer.appendChild(el);
        });

        wrapper.appendChild(btnsContainer);
        wrapper.appendChild(make('HR', 'cdx-settings-divider'));
    }

    /**
     * Clicks to one of the tunes
     *
     * @param {string} tuneName - clicked tune name
     * @param {Function} customFunction - function to execute on click
     */
    /*
    tuneClicked(tuneName, tuneValue, customFunction) {
        if (typeof customFunction === 'function') {
            if (!customFunction(tuneName)) {
                return false;
            }
        }

        if (tuneName === 'stretched') {
            const button = this.buttons.find(el => el.dataset.tuneName === tuneName);
            button.classList.toggle(this.CSS.buttonActive, !button.classList.contains(this.CSS.buttonActive));
        } else {
            this.buttons.forEach(button => {
                if (button.dataset.tuneName === tuneName) {
                    button.classList.toggle(this.CSS.buttonActive, button.dataset.tuneValue === tuneValue);
                }
            });
        }

        this.onChange(tuneName, tuneValue);
    }


    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        if (tuneName === 'stretched') {
            if (tuneValue !== undefined)
                return tuneValue === true;
            return false;
        } else if (tuneName === 'imageDirection') {
            return this._getTuneValueOrFirstValue(
                TextWithMediaBlockTunes.tunes.imageDirections,
                tuneValue
            );
        } else if (tuneName === 'textAlignment') {
            return this._getTuneValueOrFirstValue(
                TextWithMediaBlockTunes.tunes.textAlignments,
                tuneValue
            );
        } else if (tuneName === 'textboxVerticalAlign') {
            return this._getTuneValueOrFirstValue(
                TextWithMediaBlockTunes.tunes.textboxVerticalAlign,
                tuneValue
            );
        }
        throw 'No tune can be confirmed';
    }

    _getTuneValueOrFirstValue(tunes, value) {
        if (!Array.isArray(tunes) || tunes.length <= 0) {
            throw 'Tune array is invalid or empty';
        }

        for (let i = 0; i < tunes.length; i++) {
            if (value === tunes[i].value) {
                return tunes[i].value;
            }
        }

        return tunes[0].value;
    }
    */
}
