import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Link } from 'react-router-dom';
import { BrandAnalytics } from './BrandAnalytics';
import { StoreWidget } from './StoreWidget';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import './Dashboard.css';

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            data: null,
            loading: true
        };
        this.populateData = this.populateData.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
        }

        const { data, designerId } = this.state;

        return (
            <Row className="mt-3 mb-5">
                <Col xs="12" className="mb-3">
                    <h1 id="tabelLabel">Dashboard</h1>
                    <h5>Hello { data.ownerFirstName }, here is your dashboard to manage your brand on Flair.</h5>
                </Col>
                <Col xs="7">
                    <div className="bg-white rounded box-shadow p-4 mb-4">
                        <div className="mb-1 text-uppercase font-weight-bold">Brand analytics</div>
                        <FormText color="muted" className="text-left mb-3">
                            Your 6-month brand performance of total views on Flair
                        </FormText>
                        <BrandAnalytics data={data.brandDataAnalytics} />
                    </div>

                    <div className="bg-white rounded box-shadow p-4 mb-4">
                        <div className="mb-1 text-uppercase font-weight-bold">Store orders</div>
                        <FormText color="muted" className="text-left mb-3">
                            List of most recent orders from your store
                        </FormText>
                        <StoreWidget data={data.recentOrders} designerId={designerId} />
                        <FormText color="muted" className="text-left mt-3">
                            <Link to={`/manage/${designerId}/store/orders`} role="button">View all orders</Link>
                        </FormText>
                    </div>
                </Col>
                <Col xs="5">
                    <Row>
                        <Col xs="6">
                            <div className="bg-white rounded box-shadow p-4 mb-4">
                                <div className="mb-1 text-uppercase font-weight-bold text-center">Total views</div>
                                <FormText color="muted" className="text-center">
                                    In the last 6 months
                                </FormText>
                                <div className="text-center mt-3">
                                    <h4>{data.brandDataAnalyticsTotalViews}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="bg-white rounded box-shadow p-4 mb-4">
                                <div className="mb-1 text-uppercase font-weight-bold text-center">Total Orders</div>
                                <FormText color="muted" className="text-center">
                                    Made to your brand store
                                </FormText>
                                <div className="text-center mt-3">
                                    <h4>{data.ordersTotal}</h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {/*
                        <Col xs="6">
                            <div className="bg-white rounded box-shadow p-4 mb-4">
                                <p>Edit the details of your brand showcase page.</p>
                            </div>
                        </Col>
                        */}
                        <Col xs="12">
                            <div className="bg-white rounded box-shadow p-4 mb-4">
                                <div className="mb-1 text-uppercase font-weight-bold text-center">Actions</div>
                                <FormText color="muted" className="text-center">
                                    Recommended actions to complete
                                </FormText>
                                <div className="text-left mt-3">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item"><Link to={`/manage/${designerId}/brand`} className={data.getStarted.hasBrandStory ? 'btn btn-link btn-sm p-0 disabled disable-action' : 'btn btn-link btn-sm p-0' } role="button">Create and submit your brand story</Link></li>
                                        <li className="list-group-item"><Link to={`/manage/${designerId}/collections`} className={data.getStarted.hasCollections ? 'btn btn-link btn-sm p-0 disabled disable-action' : 'btn btn-link btn-sm p-0'} role="button">Create and submit your first collection</Link></li>
                                        <li className="list-group-item"><Link to={`/manage/${designerId}/store/products`} className={data.getStarted.hasProducts ? 'btn btn-link btn-sm p-0 disabled disable-action' : 'btn btn-link btn-sm p-0'} role="button">Create and submit your first product</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('/api/dashboard/' + this.state.designerId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({
            data: data,
            loading: false
        });
    }
}
