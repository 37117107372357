import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import { Redirect } from 'react-router';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false,
            redirect: false
        };

        this.startRedirectTimer = this.startRedirectTimer.bind(this);
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LogoutActions.Logout:
                if (!!window.history.state.state.local) {
                    this.logout(this.getReturnUrl());
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
                }
                break;
            case LogoutActions.LogoutCallback:
                this.processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                this.setState({ isReady: true, message: this.getLogoutMessage() });
                this.startRedirectTimer();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        if (this.id) {
            clearTimeout(this.id)
        }
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to='/' />);
        }

        const { isReady, message } = this.state;
        if (!isReady) {
            return <div></div>
        }

        let m;
        if (!!message) {
            m = message;
        } else {
            const action = this.props.action;
            switch (action) {
                case LogoutActions.Logout:
                    m = "Processing logout";
                case LogoutActions.LogoutCallback:
                    m = "Processing logout callback";
                case LogoutActions.LoggedOut:
                    m = message;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }

        return (
            <div className="main-sign mw-500px p-5">
                <h1 className="text-center"><a className="brand-lg d-inline-block" href="/">Flair Designer Portal</a></h1>
                <p className="text-center">{m}</p>
                {this.id &&
                    <p className="text-center"><a href="/">Automatically redirecting in 5 seconds, or click here</a></p>
                }
            </div>
        );
    }

    async logout(returnUrl) {
        const state = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ message: result.message });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } else {
            this.setState({ message: this.getLogoutMessage() });
            this.startRedirectTimer();
        }
    }

    async processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ isReady: true, authenticated });
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl) {
        return window.location.replace(returnUrl);
    }

    getLogoutMessage() {
        return ("You successfully logged out!");
    }

    startRedirectTimer() {
        if (this.id) {
            clearTimeout(this.id)
        }
        this.id = setTimeout(() => this.setState({ redirect: true }), 5000);
    }
}
