import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';

export class CollectionStory extends Component {
    static displayName = CollectionStory.name;

    constructor(props) {
        super(props);
    }

    render() {
        const { storySetting, designerId, collectionId } = this.props;

        if (!storySetting.hasApprovedStory && !storySetting.hasDraftStory) {
            return (
                <div>
                    <h3>Page</h3>
                    <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                        <div style={{ maxWidth: '400px' }} className="text-center p-4">
                            <p>Enter the editor to create and edit your collection page's content, images, and text.</p>
                            <Link to={`/manage/${designerId}/collections/${collectionId}/story/editor`} className="btn btn-dark mb-3" role="button">Get Started</Link>
                            <FormText color="muted">
                                This space is for all of the text, images, videos, even 3D assets that didn’t fit on your socials. Try to keep it visual, and bring your customer up to date on your latest work -- or give them the chance to see what they’ve missed!
                            </FormText>
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <Fragment>
                <h3>Page</h3>
                <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                    <div style={{ maxWidth: '400px' }} className="text-center p-4">
                        <p>Enter the editor to modify and add content and text to your collection page.</p>
                        <Link to={`/manage/${designerId}/collections/${collectionId}/story/editor`} className="btn btn-dark mb-3" role="button">Edit Collection Page</Link>
                        <FormText color="muted">
                            This space is for all of the text, images, videos, even 3D assets that didn’t fit on your socials. Try to keep it visual, and bring your customer up to date on your latest work -- or give them the chance to see what they’ve missed!
                        </FormText>
                    </div>
                </div>
                {/*
                <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                    {
                        storySetting.hasApprovedStory &&
                        <Row>
                            <Col xs="8">
                                old story
                        </Col>
                            <Col xs="4">
                                <Link to={`/`} className="btn btn-primary" role="button">Preview</Link>
                            </Col>
                        </Row>
                    }
                    {
                        storySetting.hasDraftStory &&
                        <Row>
                            <Col xs="8">
                                Enter the editor to modify and add content and text to your collection story.
                            </Col>
                            <Col xs="4">
                                <Link to={`/`} className="btn btn-primary" role="button">Preview</Link>
                            </Col>
                        </Row>
                    }
                    <hr />
                    <Link to={`/manage/${designerId}/collections/${collectionId}/story/editor`} className="btn btn-primary" role="button">Edit Story</Link>
                </div>
                */}
            </Fragment>
        );
    }
}
