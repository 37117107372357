import React, { Component, Fragment } from 'react';

import { MContext } from '../../common/HelpProvider';

export default class StoreSettingsHelp extends Component {

    constructor(props) {
        super(props);
    }

    static get FocusType() {
        return {
            NONE: null,
            DEFAULT_DELIVERY: 'title',
            DEFAULT_DELIVERY_SHORT: 'name',
            DEFAULT_PRODUCT_RETURN_TYPE: 'tagline',
            SHIPPING_COST: 'desc'
        };
    }

    renderInitalPageHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Store Settings</h5>
                    <p>The default global settings for your store on Flair, where you can set e.g. standardised messages and text for delivery, return policy and shipping.</p>
                    <p>These settings will automatically be applied for any new products you add. However, you can still customise each product to have different settings from these default global settings.</p>
                    <p>Hit the 'Save changes' button after you make your changes, and then click on 'Submit for review' button for content to be reviewed and published.</p>
                </div>
            </Fragment>
        );
    }

    renderDefaultDeliveryHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_title_0nMeb8Oza" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Default Delivery Message</h5>
                    <p>Include as much detail as possible surrounding the delivery process by your brand that is universal across your products.</p>
                    <p>Be as descriptive as possible to set the right expectations for the customer.</p>
                    <p>If any individual product has a different delivery message you can still customise it manually under the product details page.</p>
                </div>
            </Fragment>
        );
    }

    renderDefaultDeliveryShortHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_title_0nMeb8Oza" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Default Delivery Message Short</h5>
                    <p>A short summary of the universal delivery process by your brand that will be displayed in customers' basket and order details. E.g. 'Standard free delivery'</p>
                    <p>If any individual product has a different short delivery message you can still customise it manually under the product details page.</p>
                </div>
            </Fragment>
        );
    }

    renderDefaultProductReturnHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_title_0nMeb8Oza" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Default Product Return Policy</h5>
                    <p>To simplify and instil confidence and trust into a wider range of potential customers in buying from emerging designers, Flair has a standardised return policy options. You can specify what type of return all your products are eligible for - whether it's a full refund, partial refund or no refund.</p>
                    <p>Depending on your making process, we strongly recommend full refund for a better customer experience and wider brand reach.</p>
                    <p>If any individual product has a different return policy you can still customise it manually under the product details page.</p>
                    <ul>
                        <li>Full refund: product is eligible for a full refund within 14 days of the purchase date. This does not include the shipping fee.</li>
                        <li>Half refund: product is eligible for a 50% refund. The other 50% goes towards the designer's material and labour costs.</li>
                        <li>No refund: product is not eligible for a refund.</li>
                    </ul>
                </div>
            </Fragment>
        );
    }

    renderShippingCostHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_title_0nMeb8Oza" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Shipping Cost</h5>
                    <p>The cost for your brand to ship products to customers.</p>
                    <p>You can choose to set your shipping cost, however, consumers are often attracted to free shipping. We therefore recommend setting shipping as £0 (free) and working the cost in to the price of the product instead.</p>
                </div>
            </Fragment>
        );
    }

    render() {
        const { focus } = this.props;
        return (
            <div className="sticky-top pt-5">
                <MContext.Consumer>
                    {(context) => (
                        <div>
                            {context.state.help === StoreSettingsHelp.FocusType.NONE &&
                                this.renderInitalPageHelp()
                            }
                            {context.state.help === StoreSettingsHelp.FocusType.DEFAULT_DELIVERY &&
                                this.renderDefaultDeliveryHelp()
                            }
                            {context.state.help === StoreSettingsHelp.FocusType.DEFAULT_DELIVERY_SHORT &&
                                this.renderDefaultDeliveryShortHelp()
                            }
                            {context.state.help === StoreSettingsHelp.FocusType.DEFAULT_PRODUCT_RETURN_TYPE &&
                                this.renderDefaultProductReturnHelp()
                            }
                            {context.state.help === StoreSettingsHelp.FocusType.SHIPPING_COST &&
                                this.renderShippingCostHelp()
                            }
                        </div>
                    )}
                </MContext.Consumer>
            </div>
        );
    }
}
