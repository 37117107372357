import css from './Index.css';
import TextWithMediaBlockUI from './UI';
import TextWithMediaBlockTunes from './Tunes';
import Uploader from '../../utilities/uploader/Uploader';

export default class TextWithMediaBlock {
    static get draftBaseUrl() {
        return "https://ik.imagekit.io/flair/draft/tr:f-auto,q-80,w-1400,c-at_max/";
    }
    static get liveBaseUrl() {
        return "https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1400,c-at_max/";
    }

    static get isReadOnlySupported() {
        return true;
    }

    static get toolbox() {
        return {
            icon: '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;"><path class="st0" d="M21.6,3.9c0-2.2-1.7-3.9-3.9-3.9H3.9C1.7,0,0,1.7,0,3.9v13.8c0,2.2,1.7,3.9,3.9,3.9h5.9l0.4-1.7 c0,0,0-0.1,0-0.1H3.9c-0.2,0-0.5,0-0.7-0.1l7-6.8l0.1-0.1c0.3-0.3,0.8-0.2,1.2,0.1l2.5,2.4l1.3-1.3l-2.5-2.5l-0.2-0.1 c-1.1-0.9-2.6-0.8-3.6,0.1l-7,6.8c-0.1-0.2-0.1-0.5-0.1-0.7V3.9c0-1.2,0.9-2.1,2.1-2.1h13.8c1.2,0,2.1,0.9,2.1,2.1v6 c0.6-0.2,1.2-0.3,1.8-0.3V3.9z M17.4,6.9c0-1.5-1.2-2.7-2.7-2.7S12,5.4,12,6.9s1.2,2.7,2.7,2.7S17.4,8.4,17.4,6.9z M13.8,6.9 c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9C14.2,7.8,13.8,7.4,13.8,6.9z M19.3,11.6l-7.1,7.1 c-0.4,0.4-0.7,0.9-0.8,1.5l-0.5,2.2c-0.2,1,0.6,1.8,1.6,1.6l2.2-0.5c0.6-0.1,1.1-0.4,1.5-0.8l7.1-7.1c1.1-1.1,1.1-2.8,0-3.9 C22.1,10.5,20.4,10.5,19.3,11.6z"/></svg>',
            title: 'Text With Image',
        };
    }

    static get DEFAULT_DATA() {
        return {
            input_placeholder: 'Text here...',
            text: '<p><br></p>',
            stretched: false,
            imageDirection: 'left',
            textAlignment: 'left',
            textboxVerticalAlign: 'middle'
        }
    }

    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;

        /**
         * Tool's initial config
         */
        this.config = {
            endpoints: config.endpoints || '',
            additionalRequestData: config.additionalRequestData || {},
            additionalRequestHeaders: config.additionalRequestHeaders || {},
            field: config.field || 'image',
            types: config.types || 'image/*',
            //captionPlaceholder: this.api.i18n.t(config.captionPlaceholder || 'IMAGE CAPTION'),
            textPlaceholder: this.api.i18n.t(config.textPlaceholder || TextWithMediaBlock.DEFAULT_DATA.input_placeholder),
            buttonContent: config.buttonContent || '',
            uploader: config.uploader || undefined,
            actions: config.actions || [],

            designerId: config.designerId,
            id: config.id,
            storyType: config.storyType,

        };

        /**
         * Module for file uploading
         */
        this.uploader = new Uploader({
            config: this.config,
            onUpload: (response) => this.onUpload(response),
            onError: (error) => this.uploadingFailed(error),
        });



        /**
         * Module for working with UI
         */
        this.ui = new TextWithMediaBlockUI({
            api,
            config: this.config,
            onSelectFile: () => {
                this.uploader.uploadSelectedFile({
                    onPreview: (src) => {
                        this.ui.showPreloader(src);
                    },
                });
            },
            readOnly,
        });

        /**
         * Module for working with tunes
         */
        this.tunes = new TextWithMediaBlockTunes({
            api,
            actions: this.config.actions,
            onChange: (tuneName, value) => this.tuneToggled(tuneName, value),
        });

        /**
         * Set saved state
         */
        this._data = {};
        this.data = data;
        //console.log(this.data);
    }

    /**
     * Renders Block content
     *
     * @public
     *
     * @returns {HTMLDivElement}
     */
    render() {
        return this.ui.render(this.data);
    }

    /**
     * Validate data: check if block exists
     *
     * @param {BlockData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        return (savedData.file && savedData.file.url) ||
            (savedData.filedraft && savedData.filedraft.url) ||
            savedData.text;
    }

    /**
     * Return Block data
     *
     * @public
     *
     * @returns {BlockData}
     */
    save() {
        //const caption = this.ui.nodes.caption;
        //this._data.caption = caption.innerHTML;

        const text = this.ui.nodes.textEl;

        this.data.text = text.innerHTML;

        return this.data;
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch
     *
     * @public
     *
     * @returns {Element}
     */
    renderSettings() {
        return this.tunes.render(this.data);
    }

    /**
     * Fires after clicks on the Toolbox Icon
     * Initiates click on the Select File button
     *
     * @public
     */
    appendCallback() {
        this.ui.nodes.fileButton.click();
    }

    /**
     * Specify paste substitutes
     *
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
     */
    static get pasteConfig() {
        return {
            /**
             * Paste HTML into Editor
             */
            tags: ['img', 'P'],

            /**
             * Paste URL of banner into the Editor
             */
            patterns: {
                banner: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i,
            },

            /**
             * Drag n drop file from into the Editor
             */
            files: {
                mimeTypes: ['image/*'],
            },
        };
    }

    static get enableLineBreaks() {
        return true;
    }

    /**
     * Specify paste handlers
     *
     * @public
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @param {CustomEvent} event - editor.js custom paste event
     *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
     * @returns {void}
     */
    async onPaste(event) {
        console.log(event);
        switch (event.type) {
            case 'tag': {
                const banner = event.detail.data;

                /** Images from PDF */
                if (/^blob:/.test(banner.src)) {
                    const response = await fetch(banner.src);
                    const file = await response.blob();

                    this.uploadFile(file);
                    break;
                }

                this.uploadUrl(banner.src);
                break;
            }
            case 'pattern': {
                const url = event.detail.data;

                this.uploadUrl(url);
                break;
            }
            case 'file': {
                const file = event.detail.file;

                this.uploadFile(file);
                break;
            }
        }
    }

    /**
 * Sanitizer rules
 */
    static get sanitize() {
        return {
            filedraft: {},
            file: {},
            text: {
                p: {},
                br: {}
            },
            stretched: {},
            imageDirection: {},
            textAlignment: {},
            textboxVerticalAlign: {}
        };
    }


    normalizeData(data) {
        const newData = {};

        if (typeof data !== 'object') {
            data = {};
        }

        newData.file = data.file && data.file.url ? {
            url: data.file.url
        } : {};

        newData.filedraft = data.filedraft && data.filedraft.url ? {
            url: data.filedraft.url
        } : {}

        newData.text = data.text || TextWithMediaBlock.DEFAULT_DATA.text;
        newData.stretched = data.stretched !== undefined ? data.stretched : TextWithMediaBlock.DEFAULT_DATA.stretched;
        newData.imageDirection = data.imageDirection !== undefined ? data.imageDirection : TextWithMediaBlock.DEFAULT_DATA.imageDirection;
        newData.textAlignment = data.textAlignment !== undefined ? data.textAlignment : TextWithMediaBlock.DEFAULT_DATA.textAlignment;
        newData.textboxVerticalAlign = data.textboxVerticalAlign !== undefined ? data.textboxVerticalAlign : TextWithMediaBlock.DEFAULT_DATA.textboxVerticalAlign;
        return newData;
    }


    /**
     * Private methods
     * ̿̿ ̿̿ ̿̿ ̿'̿'\̵͇̿̿\з= ( ▀ ͜͞ʖ▀) =ε/̵͇̿̿/’̿’̿ ̿ ̿̿ ̿̿ ̿̿
     */

    /**
     * Stores all Tool's data
     *
     * @private
     *
     * @param {BlockData} data - data in Tool format
     */
    set data(data) {
        this._data = Object.assign({}, this.data, data);
        this._data = this.normalizeData(this._data);

        //this._data.file = data.file || null;
        //this._data.filedraft = data.filedraft || null;

        if (this._data.filedraft && this._data.filedraft.url) {
            this.ui.fillMedia(TextWithMediaBlock.draftBaseUrl + this._data.filedraft.url);
        } else if (this._data.file && this._data.file.url) {
            this.ui.fillMedia(TextWithMediaBlock.liveBaseUrl + this._data.file.url);
        } else {
            this.ui.fillMedia('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" style="background-color:%23333" width="16" height="16" viewBox="0 0 100 100"%3e%3c/svg%3e');
        }

        //this._data.caption = data.caption || '';
        //this.ui.fillCaption(this._data.caption);
        //this._data.text = data.text || '';
        this.ui.fillText(this._data.text);

        /*
        TextWithMediaBlockTunes.tunes.forEach(({ name: tune }) => {
            const value = typeof data[tune] !== 'undefined' ? data[tune] === true || data[tune] === 'true' : false;

            this.setTune(tune, value);
        });
        */
    }

    /**
     * Return Tool data
     *
     * @private
     *
     * @returns {BlockData}
     */
    get data() {
        return this._data;
    }

    /**
     * Set new file
     *
     * @private
     *
     * @param {object} file - uploaded file data
     
    set media(file) {
      this._data.file = file || {};
  
      if (file && file.url) {
        this.ui.fillMedia(file.url);
      }
    }*/
    set mediaDraft(file) {
        if (file && file.url) {
            this.data.filedraft = file;
            this.ui.fillMedia(TextWithMediaBlock.draftBaseUrl + file.url);
        }
    }


    /**
     * File uploading callback
     *
     * @private
     *
     * @param {UploadResponseFormat} response - uploading server response
     * @returns {void}
     */
    onUpload(response) {
        if (response.success && response.file) {
            this.mediaDraft = response.file;
        } else {
            this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
        }
    }

    /**
     * Handle uploader errors
     *
     * @private
     * @param {string} errorText - uploading error text
     * @returns {void}
     */
    uploadingFailed(errorText) {
        console.log('Media Tool: uploading failed because of', errorText);

        this.api.notifier.show({
            message: this.api.i18n.t('Couldn’t upload media. Please try another.'),
            style: 'error',
        });
        this.ui.hidePreloader();
    }

    /**
     * Callback fired when Block Tune is activated
     *
     * @private
     *
     * @param {string} tuneName - tune that has been clicked
     * @returns {void}
     */
    tuneToggled(tuneName,  value) {
        // inverse tune state
        this.setTune(tuneName, value);
    }

    /**
     * Set one tune
     *
     * @param {string} tuneName - {@link BlockTunes.tunes}
     * @param {boolean} value - tune state
     * @returns {void}
     */
    setTune(tuneName, value) {
        if (tuneName === 'stretched') {
            this.data[tuneName] = !this.data[tuneName];

            /**
             * Wait until the API is ready
             */
            Promise.resolve().then(() => {
                const blockId = this.api.blocks.getCurrentBlockIndex();

                this.api.blocks.stretchBlock(blockId, this.data[tuneName]);
            })
                .catch(err => {
                    console.error(err);
                });
        } else {
            this.data[tuneName] = this.tunes.confirmTuneValueOrGetFirstValue(tuneName, value);
        }

        //this.ui.applyTune(tuneName, value);
        this.ui.applyTunesView(this.data);
    }

    /**
     * Show preloader and upload file
     *
     * @param {File} file - file that is currently uploading (from paste)
     * @returns {void}
     */
    uploadFile(file) {
        this.uploader.uploadByFile(file, {
            onPreview: (src) => {
                this.ui.showPreloader(src);
            },
        });
    }

    /**
     * Show preloader and upload by target url
     *
     * @param {string} url - url pasted
     * @returns {void}
     */
    uploadUrl(url) {
        this.ui.showPreloader(url);
        this.uploader.uploadByUrl(url);
    }
}