import React, { Component } from 'react';
import { Container } from 'reactstrap';
import './AnonymousLayout.css';

export class AnonymousLayout extends Component {
    static displayName = AnonymousLayout.name;

    render() {
        return (
            <div className="a-l">
                {this.props.children}
            </div>
        );
    }
}
