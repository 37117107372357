import { BlobServiceClient } from '@azure/storage-blob';

const storageAccountName = "flairstorage";
const containerName = "draftfiles";

// return list of blobs in container to display
/*
const getBlobsInContainer = async (containerClient) => {
    const returnedBlobUrls = [];

    // get list of blobs in container
    // eslint-disable-next-line
    for await (const blob of containerClient.listBlobsFlat()) {
        // if image is public, just construct URL
        returnedBlobUrls.push(
            `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
        );
    }

    return returnedBlobUrls;
};
*/

const createBlobInContainer = async (containerClient, file, filename, userId, progress, successed, failed) => {

    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(filename);

    // set mimetype as determined from browser with file upload control
    const options = {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        onProgress: (ev) => {
            console.log(`${filename} uploaded ${ev.loadedBytes} bytes`);
            if(progress) progress(ev, (ev.loadedBytes/file.size)*100);
        },
        blobHTTPHeaders: { blobContentType: file.type }
    };

    // upload file
    await blobClient.uploadBrowserData(file, options).then(response =>{
        console.log(response);
        if(response.errorCode != null){
            console.log(`Uploading ${filename} failed`);
            if(failed) failed();
            return;
        }
        console.log(`Uploading ${filename} successed`);
        if(successed) successed();
    });
    await blobClient.setMetadata({ UserId: userId });
};

const uploadFileToBlob = async (storageAccountUrl, storageContainerName, storageSasToken, file, filename, userId, progress, successed, failed) => {
    if (!file) return null;

    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(
        `${storageAccountUrl}/?${storageSasToken}`
    );
    // get Container - full public read access
    const containerClient = blobService.getContainerClient(storageContainerName);

    // upload file
    await createBlobInContainer(containerClient, file, filename, userId, progress, successed, failed);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
    return true;
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;