import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class ConnectionHubHome extends Component {
    static displayName = ConnectionHubHome.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="my-5 py-5 mx-auto text-center" style={{ maxWidth: '600px' }}>
                <h1 className="my-4">CONNECTION HUB</h1>
                <h3 className="my-4">Coming Soon</h3>
                <div>
                    <p>Building a brand, developing products, managing the business and marketing, accessing technology, manufacturing, delivery services are among many of the things you need to do to manage your brand. We understand how hard this is for emerging brands and fashion graduates.</p>
                    <p>At Flair, we are building a Connection Hub with the aim of helping designers at different stages of building and growing their brands through connecting them to various services in the supply chain; establishing partnerships with manufacturers, fabric/material providers, product development consultants, technology providers (hardware and software), photographers and studios, delivery services, etc.</p>
                    <p>We want to give the power back to the designers and democratise the industry!</p>
                    <hr />
                    <p>As a Flair designer, you will be able to access all the tools we will add to our ecosystem as and when they will be available. If can help us grow the network or simply need support, please contact the team on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>, and we will do our best to assist.</p>
                </div>
            </div>
        );
    }
}
