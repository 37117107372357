//export const ApplicationName = 'FlairPortal.Web';
//    ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,

const liveDediaUrlprefix = 'https://ik.imagekit.io/flair';
const draftDediaUrlprefix = 'https://ik.imagekit.io/flair/draft';

export const getMediaUrl = function getMediaUrl(fileName, mediaConfig, isDraft = false, version = null) {
    version = version ? `/${version}` : '';
    if (isDraft) {
        return `${draftDediaUrlprefix}${mediaConfig}${version}/${fileName}`;
    }
    return `${liveDediaUrlprefix}${mediaConfig}${version}/${fileName}`;
};


export const BrandMediaConfig = {
    Logo: '/tr:f-auto,q-80,h-450,w-450,c-at_max',
    Portrait: `/tr:f-auto,q-80,w-1400,h-1400,ar-1-1`,
};

export const CollectionMediaConfig = {
    Thumbnail: `/tr:f-auto,q-80,w-1000,h-1000,ar-1-1`,
};

export const ProductMediaConfig = {
    Thumbnail: `/tr:f-auto,q-80,w-600,h-900,ar-2-3`,
};

