import React, { Component } from 'react';
import { Button, Alert } from 'reactstrap';

export class DataSubmittedAlertMessage extends Component {
    static displayName = DataSubmittedAlertMessage.name;

    constructor(props) {
        super(props);
        this.onRevertBackData = this.onRevertBackData.bind(this);
    }

    onRevertBackData(event) {
        this.props.onRevertBackData(event);
    }

    render() {
        return (
            <Alert color="success sticky-top">
                <h5 className="alert-heading">Waiting for review!</h5>
                <p>
                    We have received your changes. After being reviewed, your updated page will go live on Flair. You can still continue to edit and re-submit for review at any time.
                </p>
                <hr />
                <div className="mb-0 d-flex align-items-center">
                    <Button size="sm" color="danger" outline onClick={(event) => { if (window.confirm('Are you sure you wish to discard these new changes? This action will cancel the review and will revert the page back to its previous live state.')) this.onRevertBackData(event) }}>Discard changes</Button>
                </div>
            </Alert>
        );
    }
}
