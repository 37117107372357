import css from './Index.css';
import ImagesCarouselBlockUI from './UI';
import ImagesCarouselBlockTunes from './Tunes';
import Uploader from '../../utilities/uploader/Uploader';

export default class ImagesCarouselBlock {

    static get isReadOnlySupported() {
        return true;
    }

    static get toolbox() {
        return {
            icon: '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M4 19h2c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2h2c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2h-2c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2zM20 7v10h-2V7h2zM8 5h8l.001 14H8V5zM4 7h2v10H4V7z"/></svg>',
            title: 'Images Carousel',
        };
    }

    static get DEFAULT_DATA() {
        return {
            size: 'medium',
            itemsAlignment: 'top'
        }
    }


    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;

        /**
         * Tool's initial config
         */
        this.config = {
            endpoints: config.endpoints || '',
            additionalRequestData: config.additionalRequestData || {},
            additionalRequestHeaders: config.additionalRequestHeaders || {},
            field: config.field || 'image',
            types: config.types || 'image/*',
            buttonContent: config.buttonContent || '',
            uploader: config.uploader || undefined,
            actions: config.actions || [],

            designerId: config.designerId,
            id: config.id,
            storyType: config.storyType,
        };

        /**
         * Set saved state
         */
        this._data = {};
        this.data = data;



        /**
         * Module for file uploading
         
        this.uploader = new Uploader({
            config: this.config,
            onUpload: (response) => this.onUpload(response),
            onError: (error) => this.uploadingFailed(error),
        });
        */

        this.ui = new ImagesCarouselBlockUI({
            api: this.api,
            config: this.config,
            data: this.data,
            setImageDraftData: this.setImageDraftData.bind(this),
            readOnly: this.readOnly,
        });


        this.tunes = new ImagesCarouselBlockTunes({
            api: this.api,
            actions: this.config.actions,
            onChange: (tuneName, value) => this.tuneToggled(tuneName, value),
        });
    }

    /**
     * Renders Block content
     *
     * @public
     *
     * @returns {HTMLDivElement}
     */
    render() {
        //this.setTune('size', this.data.size);
        //this.setTune('itemsAlignment', this.data.itemsAlignment);

        return this.ui.render(this.data);
    }

    /**
     * Validate data: check if Banner exists
     *
     * @param {BannerBlockData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        if (savedData.images && Array.isArray(savedData.images) &&
            savedData.images.length > 0) {
            return true;
            //return savedData.images.every(obj =>
            //    (obj.file && obj.file.url) ||
            //    (obj.filedraft && obj.filedraft.url)
            //);
        }
        return false;
    }

    /**
     * Return Block data
     *
     * @public
     *
     * @returns {BannerBlockData}
     */
    save() {
        return Object.assign({}, {
            images: this.data.images,
            size: this.data.size,
            itemsAlignment: this.data.itemsAlignment
        });
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch banner
     *
     * @public
     *
     * @returns {Element}
     */
    renderSettings() {
        return this.tunes.render(this.data);
    }

    /**
     * Fires after clicks on the Toolbox Banner Icon
     * Initiates click on the Select File button
     *
     * @public
     */
    appendCallback() {
        this.ui.imageItems[0].nodes.fileButton.click();
    }

    /**
     * Specify paste substitutes
     *
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
     */
    static get pasteConfig() {
        return {
            /**
             * Paste HTML into Editor
             */
            tags: ['img'],

            /**
             * Paste URL of banner into the Editor
             */
            patterns: {
                image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i,
            },

            /**
             * Drag n drop file from into the Editor
             */
            files: {
                mimeTypes: ['image/*'],
            },
        };
    }

    /**
     * Specify paste handlers
     *
     * @public
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @param {CustomEvent} event - editor.js custom paste event
     *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
     * @returns {void}
     */
    async onPaste(event) {
        switch (event.type) {
            case 'tag': {
                const image = event.detail.data;

                /** Images from PDF */
                if (/^blob:/.test(image.src)) {
                    const response = await fetch(image.src);
                    const file = await response.blob();

                    //this.uploadFile(file);
                    break;
                }

                //this.uploadUrl(image.src);
                break;
            }
            case 'pattern': {
                const url = event.detail.data;

                //this.uploadUrl(url);
                break;
            }
            case 'file': {
                const file = event.detail.file;

                //this.uploadFile(file);
                break;
            }
        }
    }



    static get sanitize() {
        return {
            images: {},
            size: {},
            itemsAlignment: {}
        };
    }

    normalizeData(data) {
        const newData = {};

        if (typeof data !== 'object') {
            data = {};
        }

        newData.images = [];
        if (data.images && Array.isArray(data.images) &&
            data.images.length > 0) {
            data.images.forEach(obj => {
                newData.images.push({
                    file: obj.file && obj.file.url ? {
                        url: obj.file.url
                    } : {},
                    filedraft: obj.filedraft && obj.filedraft.url ? {
                        url: obj.filedraft.url
                    } : {}
                });
            });
        } else {
            //insert one empty image
            newData.images.push({
                file: {}
            });
        }

        newData.size = data.size !== undefined ? data.size : ImagesCarouselBlock.DEFAULT_DATA.size;
        newData.itemsAlignment = data.itemsAlignment !== undefined ? data.itemsAlignment : ImagesCarouselBlock.DEFAULT_DATA.itemsAlignment;

        return newData;
    }


    /**
     * Stores all Tool's data
     *
     * @private
     *
     * @param {BannerBlockData} data - data in Banner Tool format
     */
    set data(data) {
        this._data = Object.assign({}, this.data, data);
        this._data = this.normalizeData(this._data);




        /*


        this._data.images.forEach((obj, i) => {
            if (obj.filedraft && obj.filedraft.url) {
                this.ui.fillImage(i, ImagesCarouselBlock.draftBaseUrl + obj.filedraft.url);
            } else if (obj.file && obj.file.url) {
                this.ui.fillImage(i, ImagesCarouselBlock.liveBaseUrl + obj.file.url);
            } else {
                this.ui.fillImage(i, 'data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" style="background-color:%23333" width="7" height="16" viewBox="0 0 100 100"%3e%3c/svg%3e');
            }
        });*/

    }

    /**
     * Return Tool data
     *
     * @private
     *
     * @returns {BannerBlockData}
     */
    get data() {
        return this._data;
    }
    

    addImage(triggerUploadDialog) {
        if (this.data.images && Array.isArray(this.data.images) &&
            this.data.images.length > 12) {

            this.api.notifier.show({
                message: this.api.i18n.t('You have reached the maximum number of images allowed in the Carousel.'),
                style: 'error',
            });

            return false;
        }


        const defaultData = {
            file: {}
        };

        const uiImage = this.ui.addImage(defaultData);

        if (uiImage) {
            this.data.images.push(defaultData);
            if(triggerUploadDialog === true) {
                uiImage.nodes.fileButton.click();
            }
        } else {
            console.log('Images Carousel Tool: could not add a new image. Check UI');

            this.api.notifier.show({
                message: this.api.i18n.t('Couldn’t add a new image to the Carousel, please try again.'),
                style: 'error',
            });

            return false;
        }

        return true;
    }

    addMultipleImages(num) {
        if(num < 0 || num > 10) return;

        for(var x=0; x<num; x++) {
            if(this.addImage(false) === false) {
                return false;
            }
        }
        return true;
    }

    removeLastImage() {
        if (!this.data.images || !Array.isArray(this.data.images) ||
            this.data.images.length <= 1) {

            this.api.notifier.show({
                message: this.api.i18n.t('At least one image should be in the Carousel. You can delete the entire block instead.'),
                style: 'error',
            });

            return;
        }

        if (this.ui.removeLastImage()) {
            //remove last item 
            this.data.images.pop();
        } else {
            console.log('Images Carousel Tool: could not remove the last image. Check UI');

            this.api.notifier.show({
                message: this.api.i18n.t('Couldn’t remove the last image from the Carousel, please try again.'),
                style: 'error',
            });
        }
    }









    /**
     * Set new banner file
     *
     * @private
     *
     * @param {object} file - uploaded file data
     
    set banner(file) {
      this._data.file = file || {};
  
      if (file && file.url) {
        this.ui.fillBanner(file.url);
      }
    }*/
    setImageDraftData(index, file) {
        if (file && file.url && index >= 0 &&
            this.data.images && index < this.data.images.length) {
            this._data.images[index].filedraft = file;
        }
    }


    /**
     * Callback fired when Block Tune is activated
     *
     * @private
     *
     * @param {string} tuneName - tune that has been clicked
     * @returns {void}
     */
    tuneToggled(tuneName, value) {
        if (tuneName === 'size' || tuneName === 'itemsAlignment') {
            this.setTune(tuneName, this.tunes.confirmTuneValueOrGetFirstValue(tuneName, value));
        } else {
            this.setTune(tuneName, !this.data[tuneName]);
        }
    }

    /**
     * Set one tune
     *
     * @param {string} tuneName - {@link BannerBlockTunes.tunes}
     * @param {boolean} value - tune state
     * @returns {void}
     */
    setTune(tuneName, value) {
        this.data[tuneName] = value;

        this.ui.applyTune(tuneName, value);

        if (tuneName === 'stretched') {
            /**
             * Wait until the API is ready
             */
            Promise.resolve().then(() => {
                const blockId = this.api.blocks.getCurrentBlockIndex();

                this.api.blocks.stretchBlock(blockId, value);
            })
                .catch(err => {
                    console.error(err);
                });
        }
        else if (tuneName === 'addImage') {
            this.addImage(true);
        }
        else if (tuneName === 'addThreeImages') {
            this.addMultipleImages(3);
        }
        else if (tuneName === 'removeImage') {
            this.removeLastImage();
        }
    }

    /*
    /**
     * Show preloader and upload banner file
     *
     * @param {File} file - file that is currently uploading (from paste)
     * @returns {void}
     *
    uploadFile(file) {
        this.uploader.uploadByFile(file, {
            onPreview: (src) => {
                this.ui.showPreloader(XXXXXINDEX, src);
            },
        });
    }

    **
     * Show preloader and upload banner by target url
     *
     * @param {string} url - url pasted
     * @returns {void}
     *
    uploadUrl(url) {
        this.ui.showPreloader(url);
        this.uploader.uploadByUrl(url);
    }*/

}