import React, { Component } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Redirect, Link } from 'react-router-dom';
import { SidePanel } from '../../common/SidePanel';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import uploadFileToBlob from '../../utils/AzureBlob';
import { validateImage } from '../../utils/Validation';

export class AddCollection extends Component {
    static displayName = AddCollection.name;

    constructor(props) {
        super(props);

        this.state = {
            designerId: props.match.params.designerId,
            error: null,
            complete: false,
            loading: false,
            uploadPercentage: null,
            redirect: null,
            title: '',
            description: '',
            thumbnail: null
        };

        this.setError = this.setError.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.uploadProgress = this.uploadProgress.bind(this);
    }

    componentDidMount() {
    }

    setError(error) {
        this.setState({
            error: error,
            loading: false, uploadPercentage: null
        });
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    async handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        this.setState({
            loading: true, uploadPercentage: 0
        });

        const thumbnailValid = await validateImage(this.state.thumbnail, 500, 500);
        console.log(thumbnailValid);
        if (!thumbnailValid.result) {
            this.setError(thumbnailValid.error);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch(`/api/brand/${this.state.designerId}/collections/new`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                title: this.state.title,
                description: this.state.description,
                thumbnailFileName: this.state.thumbnail.name
            })
        });

        try {
            const data = await response.json();
            if (data && data.result) {
                //added
                console.log('Collection submitted');
                console.log(data);
                await this.uploadImages(data);
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }

    async uploadImages(data) {
        console.log('Uploading images');

        //upload logo
        await uploadFileToBlob(
            data.azureStorage.accountUrl,
            data.azureStorage.containerName,
            data.thumbnail.storageSasToken,
            this.state.thumbnail,
            data.thumbnail.fileName,
            data.userId,
            this.uploadProgress,
            function () {
                //success
                this.setState({
                    redirect: `/manage/${data.designerId}/collections/${data.id}/story/editor`,
                    complete: true
                });
            }.bind(this),
            function () {
                //failed
                this.setError(`Failed to upload logo`);
            }.bind(this)
        );
    }

    uploadProgress(ev, percentage) {
        this.setState({ uploadPercentage: Math.round(percentage) });
        console.log(`${percentage}%`);
    }



    render() {
        if (this.state.complete) {
            return <Redirect to={this.state.redirect} />
        }

        const { designerId, loading, uploadPercentage } = this.state;

        return (
            <SidePanel closeUrl={`/manage/${designerId}/collections`} pageLoading={loading}>
                <div>
                    <h1>New collection</h1>
                    <p>Adding a new collection.</p>
                    <Form onSubmit={this.handleSubmit}>

                        <FormGroup>
                            <Label for="Title">Title</Label>
                            <Input type="text" name="title" id="Title" placeholder="Title"
                                maxLength="100" required
                                onChange={this.handleInputChange}
                                value={this.state.title} />
                            <FormText color="muted">
                                The name of your collection, project, or installment.
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="Description">Description</Label>
                            <Input type="textarea" name="description" id="Description" placeholder="Description"
                                maxLength="400" required
                                onKeyDown={this.handleSingleLineTextArea}
                                onChange={this.handleInputChange}
                                value={this.state.description} />
                            <FormText color="muted">
                                Briefly summarise the key concepts or features of your collection.
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="Thumbnail">Thumbnail</Label>
                            <input type="file" name="thumbnail" id="Thumbnail" className="form-control-file"
                                onChange={this.handleInputChange}
                                accept="image/*"
                                required />
                            <FormText color="muted">
                                Choose an image for the thumbnail that best represents your collection.<br />
                                Acceptable formats: JPEG, PNG and SVG. Upload the highest image resolution, smaller images may become pixelated when they are resized.
                            </FormText>
                        </FormGroup>

                        <Button className="mt-3" color="dark" size="sm" disabled={loading}>{!loading || uploadPercentage === null ? 'Create collection and edit the page' : 'Uploading ' + uploadPercentage + '%'}</Button>
                    </Form>

                </div>
            </SidePanel>
        );
    }
}
