import { make } from '../../utilities/Utilities';

/**
 * Working with Block Tunes
 */
export default class BannerBlockTunes {
    /**
     * @param {object} tune - image tool Tunes managers
     * @param {object} tune.api - Editor API
     * @param {object} tune.actions - list of user defined tunes
     * @param {Function} tune.onChange - tune toggling callback
     */
    constructor({ api, actions, onChange }) {
        this.api = api;
        this.actions = actions;
        this.onChange = onChange;
        //this.buttons = [];
    }


    /**
     * Available Banner tunes
     *
     * @returns {{name: string, icon: string, title: string}[]}
     */
    static get tunes() {
        return [
            {
                name: 'stretched',
                icon: '<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>',
                title: 'Stretched',
                toggle: true,
            },
            {
                name: 'showCaption',
                icon: '<?xml version="1.0" ?><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11,9 L9,9 L9,10 L7,10 L7,7 L17,7 L17,10 L15,10 L15,9 L13,9 L13,15 L14,15 L14,17 L10,17 L10,15 L11,15 L11,9 Z M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M4,4 L4,20 L20,20 L20,4 L4,4 Z" fill-rule="evenodd"/></svg>',
                title: 'Text caption',
                toggle: true,
            }
        ];
    }

    render(toolData) {
        const tunes = BannerBlockTunes.tunes.concat(this.actions);

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            toggle: tune.toggle,
            isActive: toolData[tune.name],
            onActivate: () => {
                if (typeof tune.action === 'function') {
                    tune.action(tune.name);
                    return;
                }
                this.onChange(tune.name);
            },
        }));
    }

    /**
     * Styles
     *
     * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
     */
    /*
  get CSS() {
    return {
      wrapper: 'cdx-settings-container',
      buttonBase: this.api.styles.settingsButton,
      button: 'banner-tool__tune',
      buttonActive: this.api.styles.settingsButtonActive,
    };
  }*/

    /**
     * Makes buttons with tunes: add background, add border, stretch banner
     *
     * @param {BannerToolData} toolData - generate Elements of tunes
     * @returns {Element}
     */
    /*
  render(toolData) {
    const wrapper = make('div', this.CSS.wrapper);

    this.buttons = [];

      const tunes = BannerBlockTunes.tunes.concat(this.actions);

    tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title);
      const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
        innerHTML: tune.icon,
        title,
      });

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name, tune.action);
      });

      el.dataset.tune = tune.name;
      el.classList.toggle(this.CSS.buttonActive, toolData[tune.name]);

      this.buttons.push(el);

      this.api.tooltip.onHover(el, title, {
        placement: 'top',
      });

      wrapper.appendChild(el);
    });

    wrapper.appendChild(make('HR', 'cdx-settings-divider'));

    return wrapper;
  }
   */

    /**
     * Clicks to one of the tunes
     *
     * @param {string} tuneName - clicked tune name
     * @param {Function} customFunction - function to execute on click
     */
    /*
    tuneClicked(tuneName, customFunction) {
      if (typeof customFunction === 'function') {
        if (!customFunction(tuneName)) {
          return false;
        }
      }
  
      const button = this.buttons.find(el => el.dataset.tune === tuneName);
  
      button.classList.toggle(this.CSS.buttonActive, !button.classList.contains(this.CSS.buttonActive));
  
      this.onChange(tuneName);
    }
      */
}