import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Link } from 'react-router-dom';

export class StoreWidget extends Component {
    static displayName = StoreWidget.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            data: props.data, loading: false
        };
    }

    render() {
        const { data, designerId } = this.state;

        if (!data || data.length <= 0) {
            return (
                <div className="my-5 text-weight-bold text-center">
                    No orders yet!
                </div>
            );
        }

        return (
            <div className="table-responsive">
                <table className="table table-hover table-sm table-borderless table-striped">
                    <caption>Most recent orders from your store</caption>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Products</th>
                            <th scope="col">Cost</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((entry, i) =>
                            <tr key={i}>
                                <td>{entry.orderPublicId}</td>
                                <td>{entry.shippingAddressFirstName} {entry.shippingAddressLastName}</td>
                                <td>{entry.orderItems.length}</td>
                                <td>{entry.packageTotalCost}</td>
                                <td>{ }</td>
                                <td>
                                    <Link to={`/manage/${designerId}/store/orders/${entry.id}/details`}>View</Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}
