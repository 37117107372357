import React, { Component } from 'react';
import BreadcrumbNav from './BreadcrumbNav';
import { AccountNav } from './AccountNav';

export class TopNavMenu extends Component {
    static displayName = TopNavMenu.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="d-md-flex d-none">
                <div>
                    <BreadcrumbNav />
                </div>
                <div className="ml-auto">
                    <AccountNav />
                </div>
            </div>
        );
    }
}
