import css from './Index.css';
import BannerBlockUI from './UI';
import BannerBlockTunes from './Tunes';
import Uploader from '../../utilities/uploader/Uploader';

export default class BannerBlock {
    static get draftBaseUrl() {
        return "https://ik.imagekit.io/flair/draft/tr:f-auto,q-80,w-1920,c-at_max/";
    }
    static get liveBaseUrl() {
        return "https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1920,c-at_max/";
    }
    static get isReadOnlySupported() {
        return true;
    }

    static get isReadOnlySupported() {
        return true;
    }

    static get toolbox() {
        return {
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"/></svg>',
            title: 'Image Banner',
        };
    }

    static get DEFAULT_DATA() {
        return {
            input_placeholder: 'Text here...',
            stretched: true,
            showCaption: false,
        }
    }


    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;

        /**
         * Tool's initial config
         */
        this.config = {
            endpoints: config.endpoints || '',
            additionalRequestData: config.additionalRequestData || {},
            additionalRequestHeaders: config.additionalRequestHeaders || {},
            field: config.field || 'image',
            types: config.types || 'image/*',
            captionPlaceholder: this.api.i18n.t(config.captionPlaceholder || 'IMAGE CAPTION'),
            buttonContent: config.buttonContent || '',
            uploader: config.uploader || undefined,
            actions: config.actions || [],

            designerId: config.designerId,
            id: config.id,
            storyType: config.storyType,

        };

        /**
         * Module for file uploading
         */
        this.uploader = new Uploader({
            config: this.config,
            onUpload: (response) => this.onUpload(response),
            onError: (error) => this.uploadingFailed(error),
        });


        /**
         * Module for working with UI
         */
        this.ui = new BannerBlockUI({
            api,
            config: this.config,
            onSelectFile: () => {
                this.uploader.uploadSelectedFile({
                    onPreview: (src) => {
                        this.ui.showPreloader(src);
                    },
                });
            },
            readOnly,
        });

        /**
         * Module for working with tunes
         */
        this.tunes = new BannerBlockTunes({
            api,
            actions: this.config.actions,
            onChange: (tuneName) => this.tuneToggled(tuneName),
        });

        /**
         * Set saved state
         */
        this._data = {};
        this.data = data;
    }

    /**
     * Renders Block content
     *
     * @public
     *
     * @returns {HTMLDivElement}
     */
    render() {
        console.log('render');
        return this.ui.render(this.data);
    }

    /**
     * Validate data: check if Banner exists
     *
     * @param {BannerBlockData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        return (savedData.file && savedData.file.url) ||
            (savedData.filedraft && savedData.filedraft.url);
    }

    /**
     * Return Block data
     *
     * @public
     *
     * @returns {BannerBlockData}
     */
    save() {
        const caption = this.ui.nodes.caption;

        this._data.caption = caption.innerHTML;

        return this.data;
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch banner
     *
     * @public
     *
     * @returns {Element}
     */
    renderSettings() {
        console.log('renderSettings');
        return this.tunes.render(this.data);
    }

    /**
     * Fires after clicks on the Toolbox Banner Icon
     * Initiates click on the Select File button
     *
     * @public
     */
    appendCallback() {
        this.ui.nodes.fileButton.click();
    }

    /**
     * Specify paste substitutes
     *
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
     */
    static get pasteConfig() {
        return {
            /**
             * Paste HTML into Editor
             */
            tags: ['img'],

            /**
             * Paste URL of banner into the Editor
             */
            patterns: {
                banner: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i,
            },

            /**
             * Drag n drop file from into the Editor
             */
            files: {
                mimeTypes: ['image/*'],
            },
        };
    }

    /**
     * Specify paste handlers
     *
     * @public
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @param {CustomEvent} event - editor.js custom paste event
     *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
     * @returns {void}
     */
    async onPaste(event) {
        switch (event.type) {
            case 'tag': {
                const banner = event.detail.data;

                /** Images from PDF */
                if (/^blob:/.test(banner.src)) {
                    const response = await fetch(banner.src);
                    const file = await response.blob();

                    this.uploadFile(file);
                    break;
                }

                this.uploadUrl(banner.src);
                break;
            }
            case 'pattern': {
                const url = event.detail.data;

                this.uploadUrl(url);
                break;
            }
            case 'file': {
                const file = event.detail.file;

                this.uploadFile(file);
                break;
            }
        }
    }



    static get sanitize() {
        return {
            filedraft: {},
            file: {},
            caption: {
                br: {}
            },
            showCaption: {},
            stretched: {},
        };
    }

    normalizeData(data) {
        const newData = {};

        if (typeof data !== 'object') {
            data = {};
        }

        newData.file = data.file && data.file.url ? {
            url: data.file.url
        } : {};

        newData.filedraft = data.filedraft && data.filedraft.url ? {
            url: data.filedraft.url
        } : {}

        newData.caption = data.caption || '';

        newData.stretched = data.stretched !== undefined ? data.stretched : BannerBlock.DEFAULT_DATA.stretched;
        newData.showCaption = data.showCaption !== undefined ? data.showCaption : BannerBlock.DEFAULT_DATA.showCaption;

        return newData;
    }


    /**
     * Stores all Tool's data
     *
     * @private
     *
     * @param {BannerBlockData} data - data in Banner Tool format
     */
    set data(data) {
        this._data = Object.assign({}, this.data, data);
        this._data = this.normalizeData(this._data);

        if (this._data.filedraft && this._data.filedraft.url) {
            this.ui.fillBanner(BannerBlock.draftBaseUrl + this._data.filedraft.url);
        } else if (this._data.file && this._data.file.url) {
            this.ui.fillBanner(BannerBlock.liveBaseUrl + this._data.file.url);
        } else {
            this.ui.fillBanner('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" style="background-color:%23333" width="16" height="7" viewBox="0 0 100 100"%3e%3c/svg%3e');
        }

        this.ui.fillCaption(this._data.caption);

        BannerBlockTunes.tunes.forEach(({ name: tune }) => {
            const value = typeof this._data[tune] !== 'undefined' ? this._data[tune] === true || this._data[tune] === 'true' : false;

            this.setTune(tune, value);
        });
    }

    /**
     * Return Tool data
     *
     * @private
     *
     * @returns {BannerBlockData}
     */
    get data() {
        return this._data;
    }

    /**
     * Set new banner file
     *
     * @private
     *
     * @param {object} file - uploaded file data
     
    set banner(file) {
      this._data.file = file || {};
  
      if (file && file.url) {
        this.ui.fillBanner(file.url);
      }
    }*/
    set bannerDraft(file) {
        if (file && file.url) {
            this._data.filedraft = file;
            this.ui.fillBanner(BannerBlock.draftBaseUrl + file.url);
        }
    }

    /**
     * File uploading callback
     *
     * @private
     *
     * @param {UploadResponseFormat} response - uploading server response
     * @returns {void}
     */
    onUpload(response) {
        if (response.success && response.file) {
            this.bannerDraft = response.file;
        } else {
            this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
        }
    }

    /**
     * Handle uploader errors
     *
     * @private
     * @param {string} errorText - uploading error text
     * @returns {void}
     */
    uploadingFailed(errorText) {
        console.log('Banner Tool: uploading failed because of', errorText);

        this.api.notifier.show({
            message: this.api.i18n.t('Couldn’t upload banner. Please try another.'),
            style: 'error',
        });
        this.ui.hidePreloader();
    }

    /**
     * Callback fired when Block Tune is activated
     *
     * @private
     *
     * @param {string} tuneName - tune that has been clicked
     * @returns {void}
     */
    tuneToggled(tuneName) {
        // inverse tune state
        this.setTune(tuneName, !this.data[tuneName]);
    }

    /**
     * Set one tune
     *
     * @param {string} tuneName - {@link BannerBlockTunes.tunes}
     * @param {boolean} value - tune state
     * @returns {void}
     */
    setTune(tuneName, value) {
        this._data[tuneName] = value;

        this.ui.applyTune(tuneName, value);

        if (tuneName === 'stretched') {
            /**
             * Wait until the API is ready
             */
            Promise.resolve().then(() => {
                const blockId = this.api.blocks.getCurrentBlockIndex();

                this.api.blocks.stretchBlock(blockId, value);
            })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    /**
     * Show preloader and upload banner file
     *
     * @param {File} file - file that is currently uploading (from paste)
     * @returns {void}
     */
    uploadFile(file) {
        this.uploader.uploadByFile(file, {
            onPreview: (src) => {
                this.ui.showPreloader(src);
            },
        });
    }

    /**
     * Show preloader and upload banner by target url
     *
     * @param {string} url - url pasted
     * @returns {void}
     */
    uploadUrl(url) {
        this.ui.showPreloader(url);
        this.uploader.uploadByUrl(url);
    }
}