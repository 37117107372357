import React, { Component } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css'

export default class VideoPlayer extends Component {

    constructor(props) {
        super(props);

        try {
            if (props.className && props.className.includes('vjs-theme-sea')) {
                require('@videojs/themes/dist/sea/index.css');
            }
        } catch {
            console.log('video theme could not load');
        }

        this.state = {
            classNames: props.className ?
                'video-js ' + props.className : 'video-js',
        };
        this.handleMetadata = this.handleMetadata.bind(this);
    }

    componentDidMount() {
        this.player = videojs(this.videoNode, this.props.videoOptions, () => {
            //videojs.log('onPlayerReady', this);
            if (this.props.onReady) {
                this.props.onReady(this);
            }
        });
        this.player.reloadSourceOnError({
            /* Minimum amount of seconds that must pass before another reload
             * will be attempted, stops cascading errors */
            errorInterval: 5
        });

    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    handleMetadata(event) {
        if (this.props.onLoadedMetadata) {
            this.props.onLoadedMetadata(event.currentTarget);
        }
    }

    setControlBar(show) {
        if (show === true) {
            this.player.controlBar.show();
        } else {
            this.player.controlBar.hide();
        }
    }

    setLoop(loop) {
        if (loop === true) {
            this.player.loop(true);
        } else {
            this.player.loop(false);
        }
    }

    setPoster(url) {
        if (url) {
            this.player.poster(url);
        } else {
            this.player.poster(null);
        }
    }

    setSource(url) {
        if (url) {
            this.player.src(url);
        } else {
            this.player.src(null);
        }
    }

    getCurrentTime() {
        return  this.player.currentTime();
    }

    render() {
        const { classNames } = this.state;

        return (
            <div data-vjs-player>
                <video ref={node => this.videoNode = node} className={classNames} onLoadedMetadata={this.handleMetadata}></video>
            </div>
        );
    }
}