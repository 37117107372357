import React from 'react';
import ReactDOM from 'react-dom';
import { make } from '../../utilities/Utilities';
import VideoPlayer from '../../../common/VideoPlayer';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class VideoBlockUI {

    /**
     * @param {object} ui - Banner tool Ui module
     * @param {object} ui.api - Editor.js API
     * @param {BannerConfig} ui.config - user config
     * @param {Function} ui.onSelectFile - callback for clicks on Select file button
     * @param {boolean} ui.readOnly - read-only mode flag
     */
    constructor({ api, config, onSelectFile, readOnly }) {
        this.api = api;
        this.config = config;
        this.onSelectFile = onSelectFile;
        this.readOnly = readOnly;
        this.nodes = {
            wrapper: make('div', [this.CSS.baseClass, ...this.CSS.wrapper]),
            blockContainer: make('div', this.CSS.blockContainer),
            blockRow: make('div', this.CSS.blockRow),
            blockCol: make('div', this.CSS.blockCol),

            videoContainer: make('div', this.CSS.videoContainer),

            fileButton: this.createFileButton(),
            videoPreloaderContainer: make('div', this.CSS.videoPreloaderContainer),
            videoPreloader: make('div', this.CSS.videoPreloader),
            videoPreloaderCounter: make('small', this.CSS.videoPreloaderCounter),
            videoPreloaderBg: make('img', ['w-100', 'd-block'], {
                src: 'data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" style="background-color:%23333" width="16" height="7" viewBox="0 0 100 100"%3e%3c/svg%3e'
            }),

            videoPlayerHolder: make('div', 'w-100', {}, { "data-vjs-player": true }),

            captionContainer: make('div', this.CSS.captionContainer),
            caption: make('small', [this.CSS.input, this.CSS.caption], {
                contentEditable: !this.readOnly,
            }),
        };

        /**
         * Create base structure
         *  <wrapper>
         *    <image-container>
         *      <image-preloader />
         *      <image-filebutton />
         *    </image-container>
         *    <caption />
         *    <select-file-button />
         *  </wrapper>
         */
        this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
        this.nodes.captionContainer.appendChild(this.nodes.caption);

        this.nodes.videoPreloader.appendChild(this.nodes.videoPreloaderCounter);
        this.nodes.videoPreloaderContainer.appendChild(this.nodes.videoPreloader);
        this.nodes.videoPlayerHolder.appendChild(this.nodes.videoPreloaderBg);

        this.nodes.videoContainer.appendChild(this.nodes.videoPreloaderContainer);
        this.nodes.videoContainer.appendChild(this.nodes.fileButton);
        this.nodes.videoContainer.appendChild(this.nodes.videoPlayerHolder);

        this.nodes.blockCol.appendChild(this.nodes.videoContainer);
        this.nodes.blockCol.appendChild(this.nodes.captionContainer);

        this.nodes.blockRow.appendChild(this.nodes.blockCol);
        this.nodes.blockContainer.appendChild(this.nodes.blockRow);
        this.nodes.wrapper.appendChild(this.nodes.blockContainer);

        this.videoPlayerElement = undefined;
    }

    /**
     * CSS classes
     *
     * @returns {object}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            button: this.api.styles.button,

            /**
             * Tool's classes
             */
            wrapper: ['video-tool', 'my-5', 'px-0'],
            blockContainer: ['block-tool__video', 'px-0'],
            blockRow: ['row', 'm-0'],
            blockCol: ['col-12', 'p-0'],

            videoContainer: 'position-relative',

            fileButton: ['block-tool__video-filebtn', 'd-flex', 'align-items-center'],
            videoPreloaderContainer: ['block-tool__video-preloader', 'd-flex', 'align-items-center'],
            videoPreloader: ['block-tool__video-preloader-inner', , 'd-flex', 'align-items-center'],
            videoPreloaderCounter: ['text-muted', 'm-auto'],

            //videoPlayerEl: ['video-js', 'vjs-theme-sea', 'w-100', 'd-block'],

            captionContainer: ['block-tool__caption', 'my-1', 'mx-4'],
            caption: 'text-muted',
        };
    };

    /**
     * Ui statuses:
     * - empty
     * - uploading
     * - filled
     *
     * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
     */
    static get status() {
        return {
            EMPTY: 'empty',
            UPLOADING: 'loading',
            FILLED: 'filled',
        };
    }

    /**
     * Renders tool UI
     *
     * @param {BannerToolData} toolData - saved tool data
     * @returns {Element}
     */
    render(toolData) {
        if (!toolData.file || Object.keys(toolData.file).length === 0) {
            this.toggleStatus(VideoBlockUI.status.EMPTY);
        } else {
            this.toggleStatus(VideoBlockUI.status.UPLOADING);
        }

        return this.nodes.wrapper;
    }

    /**
     * Creates upload-file button
     *
     * @returns {Element}
     */
    createFileButton() {
        //const button = make('div', [ this.CSS.button ]);
        const button = make('div', this.CSS.fileButton);

        //button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select a Banner d')}`;
        button.innerHTML = '<span class="cdx-button">Upload Video</span>';

        button.addEventListener('click', () => {
            this.onSelectFile();
        });

        return button;
    }

    /**
     * Shows uploading preloader
     *
     * @param {string} src - preview source
     * @returns {void}
     */
    showPreloader(src) {
        //this.nodes.bannerPreloader.style.backgroundImage = `url(${src})`;

        this.toggleStatus(VideoBlockUI.status.UPLOADING);
    }

    /**
     * Hide uploading preloader
     *
     * @returns {void}
     */
    hidePreloader() {
        this.nodes.videoPreloader.style.backgroundImage = '';
        this.toggleStatus(VideoBlockUI.status.EMPTY);
    }

    /**
     * Shows a banner
     *
     * @param {string} url - image source
     * @returns {void}
     */
    fillVideo(url) {
        if (this.videoPlayerElement) {
            this.videoPlayerElement.setSource(url);
            return;
        }

        const videoOptions = {
            fluid: true,
            autoplay: false,
            controls: true,
            sources: [{
                src: url,
                //type: 'video/mp4'
            }]

        };

        const attributes = {
            className: 'video-js vjs-theme-sea',
            videoOptions: videoOptions,
            onReady: function(player) {
            }.bind(this),
            onLoadedMetadata: function(target) {
                this.toggleStatus(VideoBlockUI.status.FILLED);

                /**
                 * Preloader does not exists on first rendering with presaved data
                 */
                if (this.nodes.videoPreloader) {
                    this.nodes.videoPreloader.style.backgroundImage = '';
                }
            }.bind(this),
        };

        //TODO
//        if (this.nodes.bannerEl) {
//            this.nodes.bannerContainer.removeChild(this.nodes.bannerEl);
//        }

        const player = React.createElement(VideoPlayer, attributes);

        this.videoPlayerElement = ReactDOM.render(player, this.nodes.videoPlayerHolder);
        console.log(this.videoPlayerElement);

    }

    /**
     * Shows caption input
     *
     * @param {string} text - caption text
     * @returns {void}
     */
    fillCaption(text) {
        if (this.nodes.caption) {
            this.nodes.caption.innerHTML = text;
        }
    }


    fillThumbnail(url) {
        if (this.videoPlayerElement) {
            this.videoPlayerElement.setPoster(url);
        }
    }


    updateUploadProgress(ev, percentage) {
        this.nodes.videoPreloaderCounter.innerText = Math.trunc(percentage) + "%";
    }


    /**
     * Changes UI status
     *
     * @param {string} status - see {@link VideoBlockUI.status} constants
     * @returns {void}
     */
    toggleStatus(status) {
        for (const statusType in VideoBlockUI.status) {
            if (Object.prototype.hasOwnProperty.call(VideoBlockUI.status, statusType)) {
                this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${VideoBlockUI.status[statusType]}`, status === VideoBlockUI.status[statusType]);
            }
        }
    }

    /**
     * Apply visual representation of activated tune
     *
     * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
     * @param {boolean} status - true for enable, false for disable
     * @returns {void}
     */
    applyTune(tuneName, status) {
        if (tuneName === 'uploadVideo' || tuneName === 'captureThumbnail') {
            return;
        }

        if (tuneName === 'stretched') {
            this.nodes.blockContainer.classList.toggle('container', !!!status);
            this.nodes.blockContainer.classList.toggle('vid-fullscreen', !!status);
        }
        else if (tuneName === 'style') {
            this.nodes.blockCol.classList.toggle('col-md-6',
                status === 'half_right' || status === 'half_left' || status === 'half');
            this.nodes.blockCol.classList.toggle('offset-md-6', status === 'half_right');
            this.nodes.blockCol.classList.toggle('offset-md-3', status === 'half');
        }

        this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${tuneName}`, status);
    }


    set videoPlayerElement(player) {
        this._videoPlayerEl = player;
    }

    get videoPlayerElement() {
        return this._videoPlayerEl;
    }
}