import React, { Component, Fragment } from 'react';
import StoryEditor from '../storyeditor/StoryEditor';
import { Alert } from 'reactstrap';
import qs from 'qs';

export class TempAdminStory extends Component {
    static displayName = TempAdminStory.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            storyType: props.match.params.storyType,
            query: props.location.search,
            data: null,
            loading: true,
            error: null
        };
        this.populateData = this.populateData.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        if (this.state.loading) {
            return (<p><em>Loading...</em></p>);
        }
        return (
            <StoryEditor
                designerId={this.state.designerId}
                storyType={this.state.storyType}
                data={this.state.data}
                saveEditorData={this.saveEditorData}
                backLink={`/`}
            />
        );
    }

    async populateData() {        const query = qs.parse(this.state.query, { ignoreQueryPrefix: true });

        let acceptedEntryTypesArr;
        if(!Array.isArray(query.acceptedEntryTypes)) {
            acceptedEntryTypesArr = !!query.acceptedEntryTypes ? [query.acceptedEntryTypes] : []; }
        else {
            acceptedEntryTypesArr = query.acceptedEntryTypes;
        }
        const acceptedEntryTypesStr = qs.stringify({ acceptedEntryTypes: acceptedEntryTypesArr }, { indices: false });
      
        const response = await fetch(`/api/tempadmin/story/brand/${this.state.designerId}/${query.api}?${acceptedEntryTypesStr}`, {
        });
        const data = await response.json();
        console.log(data);
        this.setState({ data: data, loading: false });
    }

    saveEditorData() {
        alert("You can't save the story here.");
        return false;
    }



}
