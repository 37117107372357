import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import { DataDraftAlertMessage } from '../alert/DataDraftAlertMessage';

import ContentEditable from 'react-contenteditable';

import StoreSettingsHelp from './StoreSettingsHelp';
import { HelpProvider } from '../../common/HelpProvider';
import { MContext } from '../../common/HelpProvider'

export class StoreSettingsDetails extends Component {
    static displayName = StoreSettingsDetails.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            data: null,
            loading: true,
            error: null,
            helpFocus: null
        };
        this.populateData = this.populateData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmitToReview = this.onSubmitToReview.bind(this);
        this.onRevertBackData = this.onRevertBackData.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.error !== prevState.error &&
            this.state.error && this.state.error.length > 0) {
            this.scrollPageToTop();
        }
    }

    setError(error) {
        this.setState({
            error: error
        })
    }

    scrollPageToTop() {
        console.log('scroll up');
        setTimeout(function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 500);
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            case 'radio':
                value = parseInt(target.value);
                break;
            case 'number':
                value = parseFloat(target.value);
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    }


    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
        }

        const { data, designerId, loading } = this.state;

        return (
            <HelpProvider>
                <Row className="mt-3 mb-5">
                    <Col xs="8">
                        <h1><span className="dep-title">STORE</span><span>Settings</span></h1>
                        <p>Edit the default settings of your store.</p>
                        <DataDraftAlertMessage
                            onSubmitToReview={this.onSubmitToReview}
                            onRevertBackData={this.onRevertBackData}
                            dataDraftStatus={data.dataDraftStatus}
                            allowSubmitToReview={data.allowSubmitToReview}
                        />
                        <div className="mt-3 mb-5">
                            <MContext.Consumer>
                                {(context) => (
                                    <Form onSubmit={this.handleSubmit}>
                                        <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                                            <FormGroup>
                                                <Label for="DefaultProductDeliveryMessage">Default delivery message <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                                <ContentEditable
                                                    id="DefaultProductDeliveryMessage" name="defaultProductDeliveryMessage"
                                                    className="w-100 form-control texteditor"
                                                    html={data.defaultProductDeliveryMessage || ""}
                                                    disabled={false}
                                                    onChange={this.handleInputChange}
                                                    autoFocus={true}
                                                    maxLength="1000" required
                                                    onFocus={() => context.setHelp(StoreSettingsHelp.FocusType.DEFAULT_DELIVERY)}
                                                    onBlur={() => context.setHelp(StoreSettingsHelp.FocusType.NONE)}
                                                />
                                                <FormText color="muted">
                                                    1000 characters
                                                </FormText>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="DefaultProductDeliveryMessageShort">Delivery message short <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                                <Input type="input" name="defaultProductDeliveryMessageShort" id="DefaultProductDeliveryMessageShort"
                                                    placeholder="Example: Standard free delivery"
                                                    value={data.defaultProductDeliveryMessageShort || ""}
                                                    maxLength="50"
                                                    onChange={this.handleInputChange}
                                                    onFocus={() => context.setHelp(StoreSettingsHelp.FocusType.DEFAULT_DELIVERY_SHORT)}
                                                    onBlur={() => context.setHelp(StoreSettingsHelp.FocusType.NONE)}
                                                />
                                                <FormText color="muted">
                                                    50 characters
                                                </FormText>
                                            </FormGroup>
                                            <hr />
                                            <FormGroup>
                                                <Label for="DefaultProductReturnType">Default product return policy <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                            type="radio" name="defaultProductReturnType"
                                                            id="DefaultProductReturnType" value="0"
                                                            onChange={this.handleInputChange}
                                                            checked={data.defaultProductReturnType === 0}
                                                        />
                                                        <label className="form-check-label" htmlFor="DefaultProductReturnType">No Refund</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                            type="radio" name="defaultProductReturnType"
                                                            id="DefaultProductReturnType2" value="1"
                                                            onChange={this.handleInputChange}
                                                            checked={data.defaultProductReturnType === 1}
                                                        />
                                                        <label className="form-check-label" htmlFor="DefaultProductReturnType2">Partial Refund</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                            type="radio" name="defaultProductReturnType"
                                                            id="DefaultProductReturnType3" value="2"
                                                            onChange={this.handleInputChange}
                                                            checked={data.defaultProductReturnType === 2}
                                                        />
                                                        <label className="form-check-label" htmlFor="DefaultProductReturnType3">Full Refund</label>
                                                    </div>
                                                </div>
                                                <FormText color="muted">
                                                    Specify the return policy for your brand's products. See Flair's full <a href="https://flair.fashion/return-policy" target="_blank">Return Policy</a> for more details. Flair has standardised return policy options to simplify the customer experience and build trust.
                                                </FormText>
                                            </FormGroup>
                                            <hr />
                                            <FormGroup>
                                                <Label for="ShippingCost">Shipping cost <span className="icon icon-help" role="img" aria-label="Help"></span></Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">£</div>
                                                    </div>
                                                    <Input type="number" name="shippingCost" id="ShippingCost"
                                                        placeholder="Example £15"
                                                        value={data.shippingCost || 0}
                                                        maxLength="8" required
                                                        onChange={this.handleInputChange}
                                                        style={{ maxWidth: '140px' }}
                                                        className="simpletxt"
                                                        onFocus={() => context.setHelp(StoreSettingsHelp.FocusType.SHIPPING_COST)}
                                                        onBlur={() => context.setHelp(StoreSettingsHelp.FocusType.NONE)}
                                                    />
                                                </div>
                                                <FormText color="muted">
                                                </FormText>
                                            </FormGroup>

                                            <div className="p-4 mb-5 formbox-savebtn">
                                                <div className="row">
                                                    <div className="col-7"></div>
                                                    <div className="col-5 text-right"><Button color="dark" size="sm" disabled={loading}>{!loading ? 'Save changes' : 'Loading..'}</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </MContext.Consumer>
                        </div>
                    </Col>
                    <Col xs="4">
                        <StoreSettingsHelp />
                    </Col>
                </Row>
            </HelpProvider>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/store/settings`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({ data: data, loading: false });
    }


    async handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const { data } = this.state;

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch(`/api/brand/${this.state.designerId}/store/settings/edit`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                defaultProductDeliveryMessage: data.defaultProductDeliveryMessage,
                defaultProductDeliveryMessageShort: data.defaultProductDeliveryMessageShort,
                defaultProductReturnType: data.defaultProductReturnType,
                shippingCost: data.shippingCost
            })
        });

        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added
                console.log('store settings submitted');
                console.log(newDta);

                this.setState({ data: newDta.storeSettings, loading: false });

                this.scrollPageToTop();
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }

    async onSubmitToReview(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/${this.state.designerId}/store/settings/edit/submittoreview`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({ data: newDta.storeSettings, loading: false });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }

    async onRevertBackData(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/${this.state.designerId}/store/settings/edit/revertback`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({ data: newDta.storeSettings, loading: false });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }
}
