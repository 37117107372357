import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import BlockEditor from './BlockEditor';
import './StoryEditor.css';
import './Story.css';

export class StoryEditor extends Component {
    static displayName = StoryEditor.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            id: props.id,
            storyType: props.storyType,
            saveBtnText: "Save",
            saveBtnDisabled: false
        };
        this.editorRef = React.createRef();
        this.onSaveButton = this.onSaveButton.bind(this);
        this.saveEditorData = this.saveEditorData.bind(this);
    }

    onSaveButton = () => {
        this.setState({
            saveBtnText: "Saving..",
            saveBtnDisabled: true
        });
        this.editorRef.current.triggerSaveData();
    };

    saveEditorData(data) {
        this.props.saveEditorData(data, function () {
            console.log('FINISHED');
            this.setState({
                saveBtnText: "Saved!"
            });
            this.editorRef.current.dataSavedSuccessful();
            setTimeout(function () {
                this.setState({
                    saveBtnText: "Save",
                    saveBtnDisabled: false
                });
            }.bind(this), 1500);
        }.bind(this));
    }

    render() {
        return (
            <Container fluid={true} className="px-4">
                <div className="fixed-top">
                    <div className="d-inline-block editor-topmenu box-shadow">
                        Portal Editor 
                        <Link to={this.props.backLink} className="btn btn-dark btn-sm mx-1">Back</Link>
                        <Button color="dark" size="sm" onClick={this.onSaveButton} disabled={this.state.saveBtnDisabled}>{this.state.saveBtnText}</Button>
                    </div>
                </div>
                <div className="mt-5 pt-1 story">
                    <BlockEditor
                        ref={this.editorRef}
                        data={this.props.data}
                        designerId={this.state.designerId}
                        id={this.state.id}
                        storyType={this.state.storyType}
                        saveData={this.saveEditorData}
                    />
                </div>
            </Container>
        );
    }
}

export default StoryEditor;