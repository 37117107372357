import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import ContentEditable from 'react-contenteditable';

import ProductDetailsHelp from './ProductDetailsHelp';
import { MContext } from '../../common/HelpProvider'

import './ProductDetailsForm.css';

export class ProductDetailsForm extends Component {
    static displayName = ProductDetailsForm.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            productId: props.productId,
            data: props.data,
            loading: false,
            error: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentWillReceiveProps(props) {
        //this.setState({ data: props.data })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false });
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            case 'radio':
                value = parseInt(target.value);
                break;
            case 'number':
                value = parseFloat(target.value);
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { data, loading } = this.state;
        return (
            <MContext.Consumer>
                {(context) => (
                    <Form onSubmit={this.handleSubmit}>
                        <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                            <FormGroup>
                                <Label for="Title">Product title <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <Input type="input" name="title" id="Title"
                                    placeholder="Product title"
                                    value={data.title}
                                    maxLength="100" required readOnly
                                    className="bg-white"
                                    onChange={this.handleInputChange}
                                    onFocus={() => context.setHelp(ProductDetailsHelp.FocusType.TITLE)}
                                    onBlur={() => context.setHelp(ProductDetailsHelp.FocusType.NONE)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="Productlink" className="mb-0">Status</Label>
                                {data.isVisible &&
                                    (<div>
                                        <Badge color="success">Product is live</Badge><br />
                                        <a href={`https://flair.fashion/p/${data.id}/${data.friendlyUrlName}`} target="_blank" id="Productlink" className="form-text text-muted">https://flair.fashion/p/{data.id}/{data.friendlyUrlName}</a>
                                    </div>)
                                }
                                {!data.isVisible &&
                                    (<div>
                                        <Badge color="danger">Product is not live</Badge><br />
                                    </div>)
                                }
                            </FormGroup>
                            <hr />
                            <FormGroup>
                                <Label for="Tagline">Tagline <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <Input type="textarea" name="tagline" id="Tagline"
                                    value={data.tagline || ""}
                                    maxLength="100" required
                                    onKeyDown={this.handleSingleLineTextArea}
                                    onChange={this.handleInputChange}
                                    onFocus={() => context.setHelp(ProductDetailsHelp.FocusType.TAGLINE)}
                                    onBlur={() => context.setHelp(ProductDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                    100 characters
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="UnitPrice">Pricing <span className="icon icon-help" role="img" aria-label="Help"></span></Label>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">£</div>
                                    </div>
                                    <Input type="number" name="unitPrice" id="UnitPrice"
                                        placeholder="Example £100"
                                        value={data.unitPrice || ""}
                                        maxLength="8" required
                                        onChange={this.handleInputChange}
                                        style={{ maxWidth: '140px' }}
                                        className="simpletxt"
                                        onFocus={() => context.setHelp(ProductDetailsHelp.FocusType.PRICING)}
                                        onBlur={() => context.setHelp(ProductDetailsHelp.FocusType.NONE)}
                                    />
                                </div>
                            </FormGroup>

                            <hr />
                            <FormGroup>
                                <Label for="ProductType">Product Type <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="productType"
                                            id="ProductType" value="0"
                                            onChange={this.handleInputChange}
                                            checked={data.productType === 0}
                                        />
                                        <label className="form-check-label" htmlFor="ProductType">Ready-made</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="productType"
                                            id="ProductType2" value="1"
                                            onChange={this.handleInputChange}
                                            checked={data.productType === 1}
                                        />
                                        <label className="form-check-label" htmlFor="ProductType2">Made-to-order</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="productType"
                                            id="ProductType3" value="2"
                                            onChange={this.handleInputChange}
                                            checked={data.productType === 2}
                                        />
                                        <label className="form-check-label" htmlFor="ProductType3">Custom-made</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="productType"
                                            id="ProductType4" value="3"
                                            onChange={this.handleInputChange}
                                            checked={data.productType === 3}
                                        />
                                        <label className="form-check-label" htmlFor="ProductType4">Pre-order</label>
                                    </div>
                                </div>
                                <FormText color="muted">
                                    Select product type depending on your making and manufacturing process, as well as lead time.
                                </FormText>
                            </FormGroup>

                            <hr />
                            <FormGroup>
                                <Label for="ReturnType">Return policy <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="returnType"
                                            id="ReturnType" value="0"
                                            onChange={this.handleInputChange}
                                            checked={data.returnType === 0}
                                        />
                                        <label className="form-check-label" htmlFor="ReturnType">No Refund</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="returnType"
                                            id="ReturnType2" value="1"
                                            onChange={this.handleInputChange}
                                            checked={data.returnType === 1}
                                        />
                                        <label className="form-check-label" htmlFor="ReturnType2">Partial Refund</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio" name="returnType"
                                            id="ReturnType3" value="2"
                                            onChange={this.handleInputChange}
                                            checked={data.returnType === 2}
                                        />
                                        <label className="form-check-label" htmlFor="ReturnType3">Full Refund</label>
                                    </div>
                                </div>
                                <FormText color="muted">
                                    Specify the return policy for the product. See Flair's full <a href="https://flair.fashion/return-policy" target="_blank">Return Policy</a> for more details.
                                </FormText>
                            </FormGroup>

                            <hr />

                            <FormGroup>
                                <Label for="Description">Description <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <ContentEditable
                                    id="Description" name="description"
                                    className="w-100 form-control texteditor"
                                    html={data.description}
                                    disabled={false}
                                    onChange={this.handleInputChange}
                                    autoFocus={true}
                                    maxLength="2000" required
                                    onFocus={() => context.setHelp(ProductDetailsHelp.FocusType.DESCRIPTION)}
                                    onBlur={() => context.setHelp(ProductDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                    2000 characters
                                </FormText>
                            </FormGroup>

                            <div className="p-4 mb-5 formbox-savebtn">
                                <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-5 text-right"><Button color="dark" size="sm" disabled={loading}>{!loading ? 'Save changes' : 'Loading..'}</Button></div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </MContext.Consumer>
        );
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true });

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const { data } = this.state;

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                title: data.title,
                tagline: data.tagline,
                unitPrice: data.unitPrice,
                description: data.description,
                productType: data.productType,
                returnType: data.returnType
            })
        });


        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added
                console.log('Product submitted');
                console.log(newDta);

                //this.setState({ data: newDta.product, loading: false });
                this.setState({ loading: false });

                //this.props.scrollPageToTop();
                this.props.updateData(function () {
                    //update button state
                });
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }
}
