import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import StoryEditor from '../../storyeditor/StoryEditor';

export class CollectionStoryEditor extends Component {
    static displayName = CollectionStoryEditor.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            collectionId: props.match.params.collectionId,
            data: null,
            loading: true,
            error: null
        };
        this.populateData = this.populateData.bind(this);
        this.saveEditorData = this.saveEditorData.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.error !== prevState.error &&
            this.state.error && this.state.error.length > 0) {
            this.scrollPageToTop();
        }
    }

    setError(error) {
        this.setState({
            error: error
        })
    }

    scrollPageToTop() {
        console.log('scroll up');
        setTimeout(function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 500);
    }

    render() {
        if (this.state.loading) {
            return (<p><em>Loading...</em></p>);
        }
        return (
            <StoryEditor
                designerId={this.state.designerId}
                id={this.state.collectionId}
                storyType={1}
                data={this.state.data}
                saveEditorData={this.saveEditorData}
                backLink={`/manage/${this.state.designerId}/collections/${this.state.collectionId}/details`}
            />
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/collections/${this.state.collectionId}/story`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        console.log('here');
        const data = await response.json();
        console.log(data);
        this.setState({ data: data, loading: false });
    }

    async saveEditorData(data, finishCallback) {
        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch(`/api/brand/${this.state.designerId}/collections/${this.state.collectionId}/story/edit`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(data)
        });

        const newDta = await response.json();
        console.log(newDta);
        //this.setState({ data: newDta, loading: false });

        //this.scrollPageToTop();

        //console.log(data);
        finishCallback();
    }



}
