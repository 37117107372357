import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';

import ContentEditable from 'react-contenteditable';
import ProductDetailsHelp from './ProductDetailsHelp';
import { MContext } from '../../common/HelpProvider'

export class ProductDetailsDelivery extends Component {
    static displayName = ProductDetailsDelivery.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            productId: props.productId,
            data: props.data,
            loading: false,
            error: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({ data: props.data })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { data, loading } = this.state;

        let deliveryMessage, deliveryMessageShort;

        if (data.deliveryMessage) {
            deliveryMessage = data.deliveryMessage;
        } else if (data.defaultDeliveryMessage) {
            deliveryMessage = data.defaultDeliveryMessage;
        } else {
            deliveryMessage = "";
        }

        if (data.deliveryMessageShort) {
            deliveryMessageShort = data.deliveryMessageShort;
        } else if (data.defaultDeliveryMessage) {
            deliveryMessageShort = data.defaultDeliveryMessageShort;
        } else {
            deliveryMessageShort = "";
        }

        return (
            <MContext.Consumer>
                {(context) => (
                    <Form onSubmit={this.handleSubmit}>
                        <h3>Delivery information</h3>
                        <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                            <FormGroup>
                                <Label for="DeliveryMessage">Delivery Message</Label>
                                <ContentEditable
                                    id="DeliveryMessage" name="deliveryMessage"
                                    className="w-100 form-control texteditor"
                                    html={deliveryMessage || ""}
                                    disabled={false}
                                    onChange={this.handleInputChange}
                                    autoFocus={true}
                                    maxLength="1000" required
                                    onFocus={() => context.setHelp(ProductDetailsHelp.FocusType.DELIVERY)}
                                    onBlur={() => context.setHelp(ProductDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                    1000 characters
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="DeliveryMessageShort">Delivery Message Short</Label>
                                <Input type="input" name="deliveryMessageShort" id="DeliveryMessageShort"
                                    value={deliveryMessageShort}
                                    placeholder="Example: Standard free delivery"
                                    maxLength="50" required
                                    onChange={this.handleInputChange}
                                />
                                <FormText color="muted">
                                    50 characters
                                </FormText>
                            </FormGroup>

                            <div className="p-4 mb-5 formbox-savebtn">
                                <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-5 text-right"><Button color="dark" size="sm" disabled={loading}>{!loading ? 'Save changes' : 'Loading..'}</Button></div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </MContext.Consumer>
        );
    }

    async handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        this.setState({ loading: true });

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const { data } = this.state;

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                deliveryMessage: data.deliveryMessage,
                deliveryMessageShort: data.deliveryMessageShort,
            })
        });


        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added
                console.log('Product submitted');
                console.log(newDta);

                //this.setState({ data: newDta.product, loading: false });

                this.setState({ loading: false });

                //this.props.scrollPageToTop();
                this.props.updateData(function () {
                    //update button state
                });
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }
}
