import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import './HomeFlairPillar.css';

export function HomeFlairPillar(props) {
    const [isToggled, setToggled] = useState(props.initalState ? props.initalState : false);
    const toggleTrueFalse = (e) => { e.preventDefault(); setToggled(!isToggled); return false; }
    return (
        <section className="mb-0">
            <div className="flair-pillar">
                <h3 className="text-uppercase h5"><a href="#" onClick={toggleTrueFalse} className={`pt-2 pb-1 d-block t ${isToggled ? 'active' : ''}`}>{props.title}</a></h3>
                <Collapse isOpen={isToggled}>
                    <div className="mb-0">
                        {props.children}
                    </div>
                </Collapse>
            </div>
        </section>
    );
}
