import { make } from '../../utilities/Utilities';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class TextWithMediaBlockUI {
    /**
     * @param {object} ui - tool Ui module
     * @param {object} ui.api - Editor.js API
     * @param {Config} ui.config - user config
     * @param {Function} ui.onSelectFile - callback for clicks on Select file button
     * @param {boolean} ui.readOnly - read-only mode flag
     */
    constructor({ api, config, onSelectFile, readOnly }) {
        this.api = api;
        this.config = config;
        this.onSelectFile = onSelectFile;
        this.readOnly = readOnly;
        this.nodes = {
            wrapper: make('div', [this.CSS.baseClass, ...this.CSS.wrapper]),
            wrapperContainer: make('div', this.CSS.wrapperContainer),
            wrapperRow: make('div', this.CSS.wrapperRow),

            textContainer: make('div', this.CSS.textContainer),
            textInner: make('div', this.CSS.textInner),
            textEl: make('div', [this.CSS.input], {
                contentEditable: !this.readOnly,
            }),

            mediaContainer: make('div', this.CSS.mediaContainer),
            fileButton: this.createFileButton(),
            mediaEl: undefined,
            mediaPreloaderContainer: make('div', this.CSS.mediaPreloaderContainer),
            mediaPreloader: make('div', this.CSS.mediaPreloader),
            //captionContainer: make('div', this.CSS.captionContainer),
            //caption: make('small', [this.CSS.input, this.CSS.caption], {
            //  contentEditable: !this.readOnly,
            //}),
        };

        /**
         * Create base structure
         *  <wrapper>
         *    <image-container>
         *      <image-preloader />
         *      <image-filebutton />
         *    </image-container>
         *    <caption />
         *    <select-file-button />
         *  </wrapper>
         */
        //this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
        //this.nodes.captionContainer.appendChild(this.nodes.caption);

        if (!this.readOnly) {
            this.onKeyUp = this.onKeyUp.bind(this);
            this.nodes.textEl.addEventListener('keyup', this.onKeyUp);
        }


        this.nodes.textEl.dataset.placeholder = this.config.textPlaceholder;
        this.nodes.textInner.appendChild(this.nodes.textEl);
        this.nodes.textContainer.appendChild(this.nodes.textInner);

        this.nodes.mediaPreloaderContainer.appendChild(this.nodes.mediaPreloader);

        this.nodes.mediaContainer.appendChild(this.nodes.mediaPreloaderContainer);
        this.nodes.mediaContainer.appendChild(this.nodes.fileButton);

        this.nodes.wrapperRow.appendChild(this.nodes.textContainer);
        this.nodes.wrapperRow.appendChild(this.nodes.mediaContainer);
        //this.nodes.wrapper.appendChild(this.nodes.fileButton);

        this.nodes.wrapperContainer.appendChild(this.nodes.wrapperRow);
        this.nodes.wrapper.appendChild(this.nodes.wrapperContainer);
    }

    /**
     * CSS classes
     *
     * @returns {object}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            button: this.api.styles.button,

            /**
             * Tool's classes
             */
            wrapper: ['textwithmedia-block', 'my-5', 'px-0'],
            wrapperContainer: ['w-100', 'px-0'],
            wrapperRow: ['row', 'd-flex'],//'my-4',

            textContainer: ['textwithmedia-block__text', 'col-12', 'col-md-6', 'mb-3', 'd-flex'],
            textInner: ['textwithmedia-block__sidetxt'],

            mediaContainer: ['textwithmedia-block__media', 'col-12', 'col-md-6', 'mb-3'],
            fileButton: ['textwithmedia-block__media-filebtn', 'd-flex', 'align-items-center'],
            mediaPreloaderContainer: ['textwithmedia-block__media-preloader', 'd-flex', 'align-items-center'],
            mediaPreloader: 'textwithmedia-block__media-preloader-inner',
            mediaEl: ['textwithmedia-block__media-picture', 'w-100', 'd-block'],
            //captionContainer: ['textwithmedia-block-caption','my-1','mx-4'],
            //caption: 'text-muted',
        };
    };

    /**
     * Ui statuses:
     * - empty
     * - uploading
     * - filled
     *
     * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
     */
    static get status() {
        return {
            EMPTY: 'empty',
            UPLOADING: 'loading',
            FILLED: 'filled',
        };
    }


    /**
     * Check if text content is empty and set empty string to inner html.
     * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
     *
     * @param {KeyboardEvent} e - key up event
     */
    onKeyUp(e) {
        e = e || window.event

        if (e.key === 'Enter' || e.keyCode === 13) {
            document.execCommand('formatBlock', false, 'p');
            return false;
        }

        /*
        if (e.code !== 'Backspace' && e.code !== 'Delete') {
            return;
        }

        const { textContent } = this._element;

        if (textContent === '') {
            this._element.innerHTML = '';
        }*/
    }


    /**
     * Renders tool UI
     *
     * @param {ToolData} toolData - saved tool data
     * @returns {Element}
     */
    render(toolData) {
        if (!toolData.file || Object.keys(toolData.file).length === 0) {
            this.toggleStatus(TextWithMediaBlockUI.status.EMPTY);
        } else {
            this.toggleStatus(TextWithMediaBlockUI.status.UPLOADING);
        }

        this.applyTunesView(toolData);

        return this.nodes.wrapper;
    }

    /**
     * Creates upload-file button
     *
     * @returns {Element}
     */
    createFileButton() {
        //const button = make('div', [ this.CSS.button ]);
        const button = make('div', this.CSS.fileButton);

        //button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select a Banner d')}`;
        button.innerHTML = '<span class="cdx-button">Upload Image</span>';

        button.addEventListener('click', () => {
            this.onSelectFile();
        });

        return button;
    }

    /**
     * Shows uploading preloader
     *
     * @param {string} src - preview source
     * @returns {void}
     */
    showPreloader(src) {
        this.nodes.mediaPreloader.style.backgroundImage = `url(${src})`;

        this.toggleStatus(TextWithMediaBlockUI.status.UPLOADING);
    }

    /**
     * Hide uploading preloader
     *
     * @returns {void}
     */
    hidePreloader() {
        this.nodes.mediaPreloader.style.backgroundImage = '';
        this.toggleStatus(TextWithMediaBlockUI.status.EMPTY);
    }

    /**
     * Shows media
     *
     * @param {string} url - image source
     * @returns {void}
     */
    fillMedia(url) {
        /**
         * Check for a source extension to compose element correctly: video tag for mp4, img — for others
         */
        const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

        const attributes = {
            src: url,
        };

        /**
         * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
         * - IMG: load
         * - VIDEO: loadeddata
         *
         * @type {string}
         */
        let eventName = 'load';

        /**
         * Update attributes and eventName if source is a mp4 video
         */
        if (tag === 'VIDEO') {
            /**
             * Add attributes for playing muted mp4 as a gif
             *
             * @type {boolean}
             */
            attributes.autoplay = true;
            attributes.loop = true;
            attributes.muted = true;
            attributes.playsinline = true;

            /**
             * Change event to be listened
             *
             * @type {string}
             */
            eventName = 'loadeddata';
        }

        /**
         * Compose tag with defined attributes
         *
         * @type {Element}
         */

        if (this.nodes.mediaEl) {
            this.nodes.mediaContainer.removeChild(this.nodes.mediaEl);
        }

        this.nodes.mediaEl = make(tag, this.CSS.mediaEl, attributes);

        /**
         * Add load event listener
         */
        this.nodes.mediaEl.addEventListener(eventName, () => {
            this.toggleStatus(TextWithMediaBlockUI.status.FILLED);

            /**
             * Preloader does not exists on first rendering with presaved data
             */
            if (this.nodes.mediaPreloader) {
                this.nodes.mediaPreloader.style.backgroundImage = '';
            }
        });

        this.nodes.mediaContainer.appendChild(this.nodes.mediaEl);
    }

    /**
    * Shows caption input
    *
    * @param {string} text - caption text
    * @returns {void}
    */
    fillText(text) {
        if (this.nodes.textEl) {
            this.nodes.textEl.innerHTML = text;
        }
    }



    /**
     * Shows caption input
     *
     * @param {string} text - caption text
     * @returns {void}
     */
    fillCaption(text) {
        if (this.nodes.caption) {
            this.nodes.caption.innerHTML = text;
        }
    }

    /**
     * Changes UI status
     *
     * @param {string} status - see {@link TextWithMediaBlockUI.status} constants
     * @returns {void}
     */
    toggleStatus(status) {
        for (const statusType in TextWithMediaBlockUI.status) {
            if (Object.prototype.hasOwnProperty.call(TextWithMediaBlockUI.status, statusType)) {
                this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${TextWithMediaBlockUI.status[statusType]}`, status === TextWithMediaBlockUI.status[statusType]);
            }
        }
    }

    /**
     * Apply visual representation of activated tune
     *
     * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
     * @param {boolean} status - true for enable, false for disable
     * @returns {void}
     */
    applyTunesView(toolData) {
        //this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${tuneName}`, status);

        if (toolData.stretched === true) {
            this.nodes.wrapperContainer.classList.toggle('container', false);
            this.nodes.wrapperContainer.classList.toggle('container-fluid', true);
        } else {
            this.nodes.wrapperContainer.classList.toggle('container', true);
            this.nodes.wrapperContainer.classList.toggle('container-fluid', false);
        }

        if (toolData.imageDirection === 'right') {
            this.nodes.wrapperRow.classList.toggle('flex-row-reverse', false);
            if (toolData.stretched === true) {
                this.nodes.textInner.classList.toggle('smallbox', true);
                this.nodes.textInner.classList.toggle('ml-auto', true);
                this.nodes.textInner.classList.toggle('mr-md-4', true);
                this.nodes.textInner.classList.toggle('mr-lg-5', true);
                this.nodes.textInner.classList.toggle('ml-md-4', false);
                this.nodes.textInner.classList.toggle('ml-lg-5', false);
            } else {
                this.nodes.textInner.classList.toggle('smallbox', false);
                this.nodes.textInner.classList.toggle('ml-auto', false);
                this.nodes.textInner.classList.toggle('mr-md-4', false);
                this.nodes.textInner.classList.toggle('mr-lg-5', false);
                this.nodes.textInner.classList.toggle('ml-md-4', false);
                this.nodes.textInner.classList.toggle('ml-lg-5', false);
            }
        } else {
            this.nodes.wrapperRow.classList.toggle('flex-row-reverse', true);
            if (toolData.stretched === true) {
                this.nodes.textInner.classList.toggle('smallbox', true);
                this.nodes.textInner.classList.toggle('ml-auto', false);
                this.nodes.textInner.classList.toggle('mr-md-4', false);
                this.nodes.textInner.classList.toggle('mr-lg-5', false);
                this.nodes.textInner.classList.toggle('ml-md-4', true);
                this.nodes.textInner.classList.toggle('ml-lg-5', true);
            } else {
                this.nodes.textInner.classList.toggle('smallbox', false);
                this.nodes.textInner.classList.toggle('ml-auto', true);
                this.nodes.textInner.classList.toggle('mr-md-4', false);
                this.nodes.textInner.classList.toggle('mr-lg-5', false);
                this.nodes.textInner.classList.toggle('ml-md-4', false);
                this.nodes.textInner.classList.toggle('ml-lg-5', false);
            }
        }

        if (toolData.textboxVerticalAlign === 'top') {
            this.nodes.textContainer.classList.toggle('align-items-end', false);
            this.nodes.textContainer.classList.toggle('align-items-start', true);
            this.nodes.textContainer.classList.toggle('align-items-center', false);
        } else if (toolData.textboxVerticalAlign === 'bottom') {
            this.nodes.textContainer.classList.toggle('align-items-end', true);
            this.nodes.textContainer.classList.toggle('align-items-start', false);
            this.nodes.textContainer.classList.toggle('align-items-center', false);
        } else {
            this.nodes.textContainer.classList.toggle('align-items-end', false);
            this.nodes.textContainer.classList.toggle('align-items-start', false);
            this.nodes.textContainer.classList.toggle('align-items-center', true);
        }

        if (toolData.textAlignment === 'center') {
            this.nodes.textInner.classList.toggle('text-center', true);
            this.nodes.textInner.classList.toggle('text-right', false);
            this.nodes.textInner.classList.toggle('text-left', false);
        } else if (toolData.textAlignment === 'right') {
            this.nodes.textInner.classList.toggle('text-center', false);
            this.nodes.textInner.classList.toggle('text-right', true);
            this.nodes.textInner.classList.toggle('text-left', false);
        }
        else {
            this.nodes.textInner.classList.toggle('text-center', false);
            this.nodes.textInner.classList.toggle('text-right', false);
            this.nodes.textInner.classList.toggle('text-left', true);
        }
    }
}

