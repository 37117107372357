import React, { Component, Fragment } from 'react';

import { MContext } from '../../common/HelpProvider';

export default class BrandDetailsHelp extends Component {

    constructor(props) {
        super(props);
    }

    static get FocusType() {
        return {
            NONE: null,
            BRAND_TITLE: 'title',
            DESIGNER_NAME: 'name',
            TAGLINE: 'tagline',
            DESCRIPTION: 'desc',
            LOGO: 'logo',
            PORTRAIT: 'portrait',
            STORY: 'story',
        };
    }

    renderInitalPageHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Brand Showcase</h5>
                    <p>Use this guide to edit your brand showcase page.</p><p>Add details about your brand and brand story to clearly communicate your core values, mission, process and concepts.</p>
                    <p>The brand story is key to connect with consumers and grow your brand.</p>
                    <p>Hit the 'Save changes' button after you make changes to each section of the showcase. And once all sections are complete, click the 'Submit for review' button for content to be reviewed and published.</p>
                </div>
            </Fragment>
        );
    }

    renderBrandTitleHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_title_0nMeb8Oza" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Brand Showcase</h5>
                    <p>Use this guide to edit your brand showcase page.</p><p>Add details about your brand and brand story to clearly communicate your core values, mission, process and concepts.</p>
                    <p>The brand story is key to connect with consumers and grow your brand.</p>
                    <p>Hit the 'Save changes' button after you make changes to each section of the showcase. And once all sections are complete, click the 'Submit for review' button for content to be reviewed and published.</p>
                </div>
            </Fragment>
        );
    }

    renderDesignerNameHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/designer_name_D5VzUZYFF" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Designer Name/(s)</h5>
                    <p>Introduce your customers to the person/people behind your brand.</p>
                    <p>If the brand is a collaboration between two designers, you can add ‘&‘.<br />E.g. ‘Debra Ward & Mary Cueva’.</p>
                </div>
            </Fragment>
        );
    }

    renderTaglineHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_tag_65zosXT0n" className="w-100" />
                <div className="mt-4 px-3">
                    <h5 className="mt-4">Tip: Brand Tagline</h5>
                    <p>A short, punchy paragraph to highlight the essence of your brand.</p>
                </div>
            </Fragment>
        );
    }

    renderDescHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_desc_fW_w9HXj-.jpg" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Brand Description</h5>
                    <p>2-3 paragraphs briefly describing your brand’s most notable details. This will be used outside of your Showcase page as a standalone text to describe your brand (e.g. product page).</p>
                </div>
            </Fragment>
        );
    }

    renderLogoHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_logo_FHFEnsqHp.jpg" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Brand Logo</h5>
                    <p>Upload your logo to strengthen brand recognition - make sure the image quality is good and resolution high to make the best impression.</p>
                </div>
            </Fragment>
        );
    }

    renderPortraitHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_portrait_5vuFnbmHN" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Designer Portrait</h5>
                    <p>People connect with people - choose an image that represents you and your brand. Make sure the image quality is good and resolution high.</p>
                </div>
            </Fragment>
        );
    }

    renderStoryHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_500,q_auto:best/v1625662320/DesignerPortal/Tips/dsigner-details1.jpg" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Brand Story</h5>
                    <p>The brand storytelling is the most important part of the Showcase. This is where you can upload content - text, images, videos and even 3D assets, to best relay your brand story and mission.</p>
                </div>
            </Fragment>
        );
    }

    renderStoryHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_500,q_auto:best/v1625662320/DesignerPortal/Tips/dsigner-details1.jpg" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: University</h5>
                    <p>This is where you add the university/ies the designer(s) of the brand studied at.</p>
                </div>
            </Fragment>
        );
    }

    render() {
        const { focus } = this.props;
        return (
            <div className="sticky-top pt-5">
                <MContext.Consumer>
                    {(context) => (
                        <div>
                            {context.state.help === BrandDetailsHelp.FocusType.NONE &&
                                this.renderInitalPageHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.BRAND_TITLE &&
                                this.renderBrandTitleHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.DESIGNER_NAME &&
                                this.renderDesignerNameHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.TAGLINE &&
                                this.renderTaglineHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.DESCRIPTION &&
                                this.renderDescHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.LOGO &&
                                this.renderLogoHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.PORTRAIT &&
                                this.renderPortraitHelp()
                            }
                            {context.state.help === BrandDetailsHelp.FocusType.STORY &&
                                this.renderStoryHelp()
                            }
                        </div>
                    )}
                </MContext.Consumer>
            </div>
        );
    }
}

