import React, { Component, Fragment } from 'react';

import { MContext } from '../../common/HelpProvider';

export default class CollectionDetailsHelp extends Component {

    constructor(props) {
        super(props);
    }

    static get FocusType() {
        return {
            NONE: null,
            COLLECTION_TITLE: 'title',
            DESCRIPTION: 'desc',
            THUMBNAIL: 'thumbnail',
            STORY: 'story',
        };
    }

    renderInitalPageHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Collection/collection_W6DwKAUbF" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Collection Vault</h5>
                    <p>Welcome to the Collection Vault! Your brand is constantly developing and evolving. The collection archive gives you the opportunity to keep customers up to date with your current collections and projects while preserving the old ones.</p>
                    <p>Where your brand showcase page will remain relatively constant, the collection reflects the ever-changing life of your brand. This might include but is not limited to seasonal collections, non-seasonal product installments, or large projects.</p>
                    <p>Hit the 'Save changes' button after you make changes to each section of the collection. And once all sections are complete, click the 'Submit for review' button for content to be reviewed and published.</p>
                </div>
            </Fragment>
        );
    }

    renderTitleHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Collection/collection_title_AD7wlVW-s" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Collection title</h5>
                    <p>Add the name of your collection, project, or installment.</p>
                </div>
            </Fragment>
        );
    }

    renderDescHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Collection/collection_W6DwKAUbF" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Collection description</h5>
                    <p>Briefly summarise the key concepts or features of your collection in one paragraph. Keep it short and sweet!</p>
                </div>
            </Fragment>
        );
    }

    renderThumbnailHelp() {
        return (
            <Fragment>
                <h5>TIPS: Collection thumbnail</h5>
                <p>Choose an image for the thumbnail that best represents your collection. Make sure the image quality is good and resolution high.</p>
                <img src="https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_500,q_auto:best/v1625662320/DesignerPortal/Tips/dsigner-details1.jpg" className="w-100" />
            </Fragment>
        );
    }

    renderStoryHelp() {
        return (
            <Fragment>
                <h5>TIPS: Collection page:</h5>
                <p>Edit the collection page, you can upload content - text, images, videos and even 3D assets.</p>
                <img src="https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_500,q_auto:best/v1625662320/DesignerPortal/Tips/dsigner-details1.jpg" className="w-100" />
            </Fragment>
        );
    }

    render() {
        const { focus } = this.props;
        return (
            <div className="sticky-top pt-5">
                <MContext.Consumer>
                    {(context) => (
                        <div>
                            {context.state.help === CollectionDetailsHelp.FocusType.NONE &&
                                this.renderInitalPageHelp()
                            }
                            {context.state.help === CollectionDetailsHelp.FocusType.COLLECTION_TITLE &&
                                this.renderTitleHelp()
                            }
                            {context.state.help === CollectionDetailsHelp.FocusType.DESCRIPTION &&
                                this.renderDescHelp()
                            }
                            {context.state.help === CollectionDetailsHelp.FocusType.THUMBNAIL &&
                                this.renderThumbnailHelp()
                            }
                            {context.state.help === CollectionDetailsHelp.FocusType.STORY &&
                                this.renderStoryHelp()
                            }
                        </div>
                    )}
                </MContext.Consumer>
            </div>
        );
    }
}
