import { make } from '../../utilities/Utilities';

/**
 * Working with Block Tunes
 */
export default class ImagesCarouselBlockTunes {
    /**
     * @param {object} tune - image tool Tunes managers
     * @param {object} tune.api - Editor API
     * @param {object} tune.actions - list of user defined tunes
     * @param {Function} tune.onChange - tune toggling callback
     */
    constructor({ api, actions, onChange }) {
        this.api = api;
        this.actions = actions;
        this.onChange = onChange;
        //this.buttons = [];
    }

    /**
     * Available Banner tunes
     *
     * @returns {{name: string, icon: string, title: string}[]}
     */
    static get tunes() {
        return [
            {
                name: 'addImage',
                icon: '<svg fill="#fff" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L15.5 15.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 19H19M22 19H19M19 19V16M19 19V22" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Add image to end',
            },
            {
                name: 'addThreeImages',
                icon: '<svg fill="#fff" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L15.5 15.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 19H19M22 19H19M19 19V16M19 19V22" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Add 3 images to end',
            },
            {
                name: 'removeImage',
                icon: '<svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L14 14.8182" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.8786 21.1213L19 19M21.1213 16.8787L19 19M19 19L16.8786 16.8787M19 19L21.1213 21.1213" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Remove last image',
            },
            {
                name: 'size',
                toggle: 'size',
                value: 'small',
                title: 'Size small',
                icon: '<svg fill="none" height="16" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L21 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
                name: 'size',
                toggle: 'size',
                value: 'medium',
                title: 'Size medium',
                icon: '<svg fill="none" height="20" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L21 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
                name: 'size',
                toggle: 'size',
                value: 'large',
                title: 'Size large',
                icon: '<svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L21 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
                name: 'itemsAlignment',
                toggle: 'itemsAlignment',
                value: 'top',
                icon: '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.5,2H1.5a.5.5,0,0,1,0-1h21a.5.5,0,0,1,0,1Z"/><path d="M18,15H14V4h4Zm.5-12h-5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,18.5,3Z"/><path d="M10,22H6V4h4Zm.5-19h-5a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,10.5,3Z"/></svg>',
                title: 'Alignment to top',
            },
            {
                name: 'itemsAlignment',
                toggle: 'itemsAlignment',
                value: 'middle',
                icon: '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.5,11.5H19v-6a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v6H11V1.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v10H1.5a.5.5,0,0,0,0,1H5v10a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-10h2v6a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-6h3.5a.5.5,0,0,0,0-1ZM10,22H6V2h4Zm8-4H14V6h4Z"/></svg>',
                title: 'Alignment to middle',
            },
            {
                name: 'itemsAlignment',
                toggle: 'itemsAlignment',
                value: 'bottom',
                icon: '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.5,23H1.5a.5.5,0,0,1,0-1h21a.5.5,0,0,1,0,1Z"/><path d="M18,20H14V9h4Zm.5-12h-5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V8.5A.5.5,0,0,0,18.5,8Z"/><path d="M10,20H6V2h4Zm.5-19h-5a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V1.5A.5.5,0,0,0,10.5,1Z"/></svg>',
                title: 'Alignment to bottom',
            }
        ];
    }

    render(toolData) {
        const tunes = ImagesCarouselBlockTunes.tunes.concat(this.actions);

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            toggle: tune.toggle,
            isActive: tune.value && toolData[tune.name] === tune.value,
            onActivate: () => {
                if (typeof tune.action === 'function') {
                    tune.action(tune.name);
                    return;
                }
                this.onChange(tune.name, tune.value);
            },
        }));
    }

    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        const confirmed = ImagesCarouselBlockTunes.tunes.find(item => item.name === tuneName && item.value === tuneValue);
        if (confirmed !== undefined) {
            return confirmed.value;
        }
        return ImagesCarouselBlockTunes.tunes.filter(item => item.name === tuneName)[0].value;
    }

    /**
     * Styles
     *
     * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
     */
    /*
    get CSS() {
        return {
            wrapper: 'cdx-settings-container',
            buttonBase: this.api.styles.settingsButton,
            button: 'imagescarousel-tool__tune',
            buttonActive: this.api.styles.settingsButtonActive,
            tunerSectionWrapper: 'cdx-settings-inner',
            tunerSectionTitle: ['small', 'text-muted', 'pt-1', 'pr-1', 'pl-1', 'pb-0', 'text-center'],
        };
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch banner
     *
     * @param {BannerToolData} toolData - generate Elements of tunes
     * @returns {Element}
     */
    /*
    render(toolData) {
        this.buttons = [];

        const wrapper = make('div', this.CSS.wrapper);

        wrapper.appendChild(make('div', this.CSS.tunerSectionTitle, {
            innerHTML: 'Image'
        }));

        this.renderSection(
            ImagesCarouselBlockTunes.tunes.imagesTunes.concat(this.actions),
            toolData,
            wrapper);

        wrapper.appendChild(make('div', this.CSS.tunerSectionTitle, {
            innerHTML: 'Image size'
        }));

        this.renderSection(
            ImagesCarouselBlockTunes.tunes.sizes.concat(this.actions),
            toolData,
            wrapper);

        wrapper.appendChild(make('div', this.CSS.tunerSectionTitle, {
            innerHTML: 'Image alignment'
        }));

        this.renderSection(
            ImagesCarouselBlockTunes.tunes.itemsAlignments.concat(this.actions),
            toolData,
            wrapper);

        return wrapper;
    }

    /*
    renderSection(tunes, toolData, wrapper) {
        const btnsContainer = wrapper.appendChild(make('div', this.CSS.tunerSectionWrapper));

        tunes.forEach(tune => {
            const title = this.api.i18n.t(tune.title);
            const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
                innerHTML: tune.icon,
                title,
            });

            el.addEventListener('click', () => {
                this.tuneClicked(tune.name, tune.value, tune.action);
            });

            el.dataset.tuneName = tune.name;
            el.dataset.tuneValue = tune.value;

            el.classList.toggle(this.CSS.buttonActive,
                (tune.value === undefined && this.confirmTuneValueOrGetFirstValue(tune.name, toolData[tune.name]) === true) ||
                (tune.value === this.confirmTuneValueOrGetFirstValue(tune.name, toolData[tune.name]))
            );
            //el.classList.toggle(this.CSS.buttonActive, toolData[tune.name]);

            this.buttons.push(el);

            this.api.tooltip.onHover(el, title, {
                placement: 'top',
            });

            btnsContainer.appendChild(el);
        });

        wrapper.appendChild(btnsContainer);
        wrapper.appendChild(make('HR', 'cdx-settings-divider'));
    }



    /**
     * Clicks to one of the tunes
     *
     * @param {string} tuneName - clicked tune name
     * @param {Function} customFunction - function to execute on click
     */
    /*
    tuneClicked(tuneName, tuneValue, customFunction) {
        if (typeof customFunction === 'function') {
            if (!customFunction(tuneName)) {
                return false;
            }
        }

        if (tuneName === 'size' || tuneName === 'itemsAlignment') {
            this.buttons.forEach(button => {
                if (button.dataset.tuneName === tuneName) {
                    button.classList.toggle(this.CSS.buttonActive, button.dataset.tuneValue === tuneValue);
                }
            });
        } else if (tuneName === 'addImage' || tuneName === 'removeImage') {
            //don't highlight button
        } else {
            const button = this.buttons.find(el => el.dataset.tuneName === tuneName);
            button.classList.toggle(this.CSS.buttonActive, !button.classList.contains(this.CSS.buttonActive));
        }

        this.onChange(tuneName, tuneValue);
    }


    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        if (tuneName === 'size') {
            return this._getTuneValueOrFirstValue(
                ImagesCarouselBlockTunes.tunes.sizes,
                tuneValue
            );
        }
        else if (tuneName === 'itemsAlignment') {
            return this._getTuneValueOrFirstValue(
                ImagesCarouselBlockTunes.tunes.itemsAlignments,
                tuneValue
            );
        } else {
            if (tuneValue !== undefined)
                return tuneValue === true;
            return false;
        }
    }

    _getTuneValueOrFirstValue(tunes, value) {
        if (!Array.isArray(tunes) || tunes.length <= 0) {
            throw 'Tune array is invalid or empty';
        }

        for (let i = 0; i < tunes.length; i++) {
            if (value === tunes[i].value) {
                return tunes[i].value;
            }
        }

        return tunes[0].value;
    }
    */
}
