import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'

export class ApplicationRejectedPage extends Component {
    static displayName = ApplicationRejectedPage.name;

    render() {
        return (
            <div className="main-sign mw-600px p-5 text-center">
                <h1><span className="brand-lg d-inline-block">Flair Designer Portal</span></h1>
                <h4>Your application is rejected</h4>
                <p>
                    Unfortunately your application has been rejected after reviewing all the details you submitted.
                </p>
                <p><a href="mailto:contact@flair.fashion" target="_blank">Get in touch with us to discuss the review.</a></p>
            </div>
        );
    }
}