import React, { useState } from 'react';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
    MeasuringStrategy
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from '@dnd-kit/sortable';

import { ProductDetailsGallerySortableItem } from './ProductDetailsGallerySortableItem';
import { ProductDetailsGalleryItem } from './ProductDetailsGalleryItem';


export function ProductDetailsGallerySortable(props) {

    const [activeId, setActiveId] = useState(null);
    const [activeFile, setActiveFile] = useState(null);
    //const [items, setItems] = useState(props.media);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );

    const measuringConfig = {
        droppable: {
            strategy: MeasuringStrategy.Always,
        }
    };

    function handleDragStart(event) {
        //const { active } = event;
        //setActiveId(active.id);
        setActiveId(event.active.id);
        setActiveFile(props.media.find(i => i.file.fileName === event.active.id).file);
        //console.log("state: ");
        //console.log(items);
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const newList = ((oldList) => {
                //const oldIndex = items.indexOf(active.id);
                //const newIndex = items.indexOf(over.id);
                const { newIndex, oldIndex } = findMediaIndex(active.id, over.id);
                const newItems = arrayMove(oldList, oldIndex, newIndex);
                return newItems;
            })(props.media);

            props.updateMedia(newList);
        }

        setActiveId(null);
        setActiveFile(null);
    }

    function handleRemoveItem(fileName) {
        //alert('test');
        const newItems = props.media.filter(media => media.file.fileName !== fileName);
        //console.log("remove item");
        //console.log(newItems);
        props.updateMedia(newItems);
    }

    if (!props.media) {
        return (
            <div />
        );
    }

    console.log("updated sortable gallery");
    console.log(props.media);

    return(
        <DndContext
            measuring={measuringConfig}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}>
            <SortableContext
                items={props.media.map(function (m) { return m.file.fileName; })}
                strategy={rectSortingStrategy}>
                <Grid>
                    {props.media.map((m) =>
                        <ProductDetailsGallerySortableItem key={m.file.fileName}
                            id={m.file.fileName} file={m.file} removeItem={handleRemoveItem} />)}
                </Grid>
            </SortableContext>
            <DragOverlay>
                {activeId ? <ProductDetailsGalleryItem file={activeFile} className="dnd-overlay" /> : null}
            </DragOverlay>
        </DndContext>
    );


    function findMediaIndex(activeId, overId) {
        console.log("searching: " + activeId + " :::: " + overId);
        let newIndex = -1, oldIndex = -1;
        if (!props.media || props.media.length <= 0)
            return { newIndex, oldIndex }
        for (let i = 0; i < props.media.length; i++) {
            if (activeId === props.media[i].file.fileName) {
                oldIndex = i;
            } else if (overId === props.media[i].file.fileName) {
                newIndex = i;
            }
            if (oldIndex > 0 && newIndex > 0) break;
        }
        return { newIndex, oldIndex };
    }
}


function Grid({ children }) {
    return (
        <div
            style={{
                width: '100%',
                display: 'inline-grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: 10,
            }}
        >
            {children}
        </div>
    );
}
