import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import CollectionDetailsHelp from './CollectionDetailsHelp';

import { MContext } from '../../common/HelpProvider';


export class CollectionDetailsForm extends Component {
    static displayName = CollectionDetailsForm.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            collectionId: props.collectionId,
            data: props.data,
            loading: false,
            error: null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentWillReceiveProps(props) {
        //this.setState({ data: props.data })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false });
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }));

        //this.props.onChange(name, value);
    }


    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { data, loading } = this.state;
        return (
            <MContext.Consumer>
                {(context) => (
                    <Form onSubmit={this.handleSubmit}>
                        <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                            <FormGroup>
                                <Label for="Title">Title <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <Input type="input" name="title" id="Title"
                                    placeholder="Collection title"
                                    value={data.title}
                                    maxLength="100"
                                    required readOnly
                                    className="bg-white"
                                    onChange={this.handleInputChange}
                                    onFocus={() => context.setHelp(CollectionDetailsHelp.FocusType.COLLECTION_TITLE)}
                                    onBlur={() => context.setHelp(CollectionDetailsHelp.FocusType.NONE)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="collectionlink" className="mb-0">Status</Label>
                                {data.isVisible &&
                                    (<div>
                                        <Badge color="success">Collection is live</Badge><br />
                                        <a href={`https://flair.fashion/${data.designerFriendlyUrlName}/collection/${data.id}/${data.friendlyUrlName}`} target="_blank" id="collectionlink" className="form-text text-muted">https://flair.fashion/{data.designerFriendlyUrlName}/collection/{data.id}/{data.friendlyUrlName}</a>
                                    </div>)
                                }
                                {!data.isVisible &&
                                    (<div>
                                        <Badge color="danger">Collection is not live</Badge>
                                    </div>)
                                }
                            </FormGroup>
                            <hr />
                            <FormGroup>
                                <Label for="Description">Description <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <Input type="textarea" name="description" id="Description"
                                    value={data.description}
                                    maxLength="400" required
                                    onKeyDown={this.handleSingleLineTextArea}
                                    onChange={this.handleInputChange}
                                    onFocus={() => context.setHelp(CollectionDetailsHelp.FocusType.DESCRIPTION)}
                                    onBlur={() => context.setHelp(CollectionDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                        400 characters<br />
                                    Briefly summarise the key concepts or features of your collection.
                                </FormText>
                            </FormGroup>

                            <div className="p-4 mb-5 formbox-savebtn">
                                <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-5 text-right"><Button color="dark" size="sm" disabled={loading}>{!loading ? 'Save changes' : 'Loading..'}</Button></div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </MContext.Consumer>
        );
    }


    async handleSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true });

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const { data } = this.state;

        const response = await fetch(`/api/brand/${this.state.designerId}/collections/${this.state.collectionId}/edit`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                title: data.title,
                description: data.description
            })
        });


        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added
                console.log('Collection submitted');
                console.log(newDta);

                this.setState({ data: newDta.collection, loading: false });

                //this.props.scrollPageToTop();

                this.props.updateData(function () {
                    //update button state
                });
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }
}
