import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthorizeRoute from '../../api-authorization/AuthorizeRoute';
import authService from '../../api-authorization/AuthorizeService';
import { AddProduct } from './AddProduct';
import { TransitionRoutes } from '../../common/TransitionRoutes';

import { Image } from '../../common/UIElements';
import { ProductMediaConfig } from '../../utils/MediaConfigs';

export class ProductsList extends Component {
    static displayName = ProductsList.name;

    constructor(props) {
        super(props);
        this.state = {
            products: [], loading: true,
            designerId: props.match.params.designerId
        };
        this.populateData = this.populateData.bind(this);
    }
    
    componentDidMount() {
        this.populateData();
    }

    static renderDataTable(list, designerId) {
        return (
            <div>
                <h1><span className="dep-title">STORE</span><span>Product Manager</span></h1>
                <div style={{ maxWidth: '800px' }}>
                    <p>Add and manage your products in the Product Manager</p>
                </div>
                <Link to={`/manage/${designerId}/store/products/new`} className="btn btn-dark btn-sm mb-4">New product</Link>
                <div className="row mt-4">
                    {list.map((entry, i) =>
                        <div key={i} className="col-lg-3 col-md-6">
                            <div className="card mb-5 bg-white rounded box-shadow">
                                <div className="pb-3">
                                    <div className="mb-3 w-100 overflow-hidden position-relative text-center">
                                        <Link to={`/manage/${designerId}/store/products/${entry.id}/details`}>
                                            <Image fileName={entry.thumbnail ?
                                                entry.thumbnail.fileName :
                                                "Designer_Portal/General/no_product_image_dDPCtZRlN.png"
                                                    }
                                                isDraft={entry.thumbnail ? entry.thumbnail.isDraft : true}
                                                mediaConfig={ProductMediaConfig.Thumbnail}
                                                className="d-block position-relative w-100 pt-1 px-1"
                                            />
                                        </Link>
                                    </div>
                                    <div className="d-flex no-block align-items-center">
                                        <div className="px-3">
                                            <div className="mb-0 text-weight-bold">{entry.title}</div>
                                            <div>
                                                {entry.isVisible &&
                                                    <span className="badge badge-pill badge-success mr-1">Live</span>}
                                                {!entry.isVisible &&
                                                    <span className="badge badge-pill badge-danger mr-1">Hidden</span>}
                                                {entry.dataDraftStatus === 0 &&
                                                    <span className="badge badge-pill badge-info mr-1">New save</span>}
                                                {entry.dataDraftStatus === 1 &&
                                                    <span className="badge badge-pill badge-primary mr-1">Submitted</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
        {/*
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>CreatedBy</th>
                        <th>Title</th>
                        <th>IsVisible</th>
                        <th>BrandDataStatus</th>
                        <th>DataDraftStatus</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((entry, i) =>
                        <tr key={i}>
                            <td>{entry.id}</td>
                            <td>{entry.createdBy}</td>
                            <td>{entry.title}</td>
                            <td>{entry.isVisible}</td>
                            <td>{ }</td>
                            <td>{ }</td>
                            <td>
                                <Link to={`/manage/${designerId}/store/products/${entry.id}/details`}>View</Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            */}
    }


    static renderEmptyMessage(designerId) {
        return (
            <div className="my-5 py-5 mx-auto text-center" style={{ maxWidth: '600px' }}>
                <h1 id="tabelLabel" className="my-4">STORE: <br/>Product Manager</h1>
                <div>
                    <p>Welcome to your product manager. Each brand is built uniquely to the next. You’ll see in our product manager that you are provided with many different selling options to support the strategy that works for you.</p>
                    <p>Here you can add, remove and manage your product and product details. Select ‘New Product’ to get started.</p>
                </div>
                <Link to={`/manage/${designerId}/store/products/new`} className="btn btn-dark btn-sm mb-4">New product</Link>
            </div>
        );
    }


    render() {
        const contents = this.state.loading
            ? (<div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>)
            : this.state.products && this.state.products.length > 0 ?
                ProductsList.renderDataTable(this.state.products, this.state.designerId) :
                ProductsList.renderEmptyMessage(this.state.designerId);

        const { designerId } = this.state;

        return (
            <div className="mt-3">
                {contents}
                <TransitionRoutes className="slideanim">
                    <AuthorizeRoute path='/manage/:designerId/store/products/new' component={AddProduct} />
                </TransitionRoutes>
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/store/products`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({ products: data, loading: false });
    }
}
