import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';

/* portal pages */
import { Manage } from './components/manage/Manage';
import { BrandStoryEditor } from './components/manage/brand/BrandStoryEditor';
import { CollectionStoryEditor } from './components/manage/collection/CollectionStoryEditor';
import { ProductStoryEditor } from './components/manage/store/ProductStoryEditor';

/* other pages */
import { SimpleLayout } from './components/simple/layout/SimpleLayout';
import { BrandSignup } from './components/brand-signup/BrandSignup';
import { BrandSignupComplete } from './components/brand-signup/BrandSignupComplete';
import { ReviewInProcessPage } from './components/simple/ReviewInProcessPage';
import { ApplicationRejectedPage } from './components/simple/ApplicationRejectedPage';

/* anonymous pages */
import { AnonymousLayout } from './components/anonymous/layout/AnonymousLayout';
import { Home } from './components/anonymous/Home';
import { Counter } from './components/anonymous/Counter';
import { ServiceAgreement } from './components/anonymous/ServiceAgreement';

import './custom.css'
import { TempAdminStory } from './components/tempadmin/TempAdminStory';

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <Router>
              <Switch>
                  <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

                  <AuthorizeRoute path='/manage/:designerId/brand/story/editor' component={BrandStoryEditor} />
                  <AuthorizeRoute path='/manage/:designerId/collections/:collectionId/story/editor' component={CollectionStoryEditor} />
                  <AuthorizeRoute path='/manage/:designerId/store/products/:productId/story/editor' component={ProductStoryEditor} />
                  <AuthorizeRoute path='/manage/:designerId?' component={Manage} />

                  <Route>
                      <SimpleLayout>
                          <Switch>
                              <AuthorizeRoute exact path='/new-brand-signup' component={BrandSignup} />
                              <AuthorizeRoute exact path='/new-brand-signup/complete' component={BrandSignupComplete} />
                              <AuthorizeRoute exact path='/r/review-in-process' component={ReviewInProcessPage} />
                              <AuthorizeRoute exact path='/r/application-rejected' component={ApplicationRejectedPage} />
                          </Switch>
                      </SimpleLayout>
                      <AnonymousLayout>
                          <Switch>
                              <Route exact path='/' component={Home} />
                              <Route path='/tempadmin/story/:designerId/:storyType' component={TempAdminStory} />
                              <Route path='/service-agreement' component={ServiceAgreement} />
                              <Route path='/counter' component={Counter} />
                          </Switch>
                      </AnonymousLayout>
                  </Route>
              </Switch>
          </Router>
    );
  }
}
