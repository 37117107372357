import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import ContentEditable from 'react-contenteditable';
import BrandDetailsHelp from './BrandDetailsHelp';

import { MContext } from '../../common/HelpProvider';


export class BrandDetailsForm extends Component {
    static displayName = BrandDetailsForm.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            data: props.data,
            loading: false,
            error: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentWillReceiveProps(props) {
        //this.setState({ data: props.data })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false });
    }

    handleInputChange(event) {
        //console.log(event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { data, loading } = this.state;
        //console.log(data.tagline);
        return (
            <MContext.Consumer>
                {(context) => (
                    <Form onSubmit={this.handleSubmit}>
                        <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                            <FormGroup>
                                <Label for="BrandTitle">Brand title <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <Input type="input" name="brandTitle" id="BrandTitle"
                                    placeholder="Brand title"
                                    value={data.brandTitle}
                                    maxLength="100"
                                    required readOnly
                                    className="bg-white"
                                    onChange={this.handleInputChange}
                                    onFocus={() => context.setHelp(BrandDetailsHelp.FocusType.BRAND_TITLE)}
                                    onBlur={() => context.setHelp(BrandDetailsHelp.FocusType.NONE)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="brandlink" className="mb-0">Flair URL</Label>
                                {data.isVisible &&
                                    (<div>
                                        <Badge color="success">Brand is live</Badge><br />
                                        <a href={`https://flair.fashion/${data.friendlyUrlName}`} target="_blank" id="brandlink" className="form-text text-muted">https://flair.fashion/{data.friendlyUrlName}</a>
                                        <FormText color="muted">
                                            Your official brand url on Flair. Share this link to direct followers and customers to your showcase and store.
                                        </FormText>
                                    </div>)
                                }
                                {!data.isVisible &&
                                    (<div>
                                        <Badge color="danger">Brand is not live</Badge>
                                    </div>)
                                }
                            </FormGroup>
                            <hr />
                            <FormGroup className="mt-4">
                                <Label for="DesignerName">Designer name(s) <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <Input type="input" name="designerName" id="DesignerName"
                                    placeholder="For example 'John John' or 'John John and Mike Mike'"
                                    value={data.designerName}
                                    maxLength="100" required
                                    onChange={this.handleInputChange}
                                    onFocus={() => context.setHelp(BrandDetailsHelp.FocusType.DESIGNER_NAME)}
                                    onBlur={() => context.setHelp(BrandDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="Tagline">Tagline <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <ContentEditable
                                    id="Tagline" name="tagline"
                                    className="w-100 form-control texteditor"
                                    html={data.tagline}
                                    disabled={false}
                                    onKeyDown={this.handleSingleLineTextArea}
                                    onChange={this.handleInputChange}
                                    autoFocus={true}
                                    maxLength="400" required
                                    onFocus={() => context.setHelp(BrandDetailsHelp.FocusType.TAGLINE)}
                                    onBlur={() => context.setHelp(BrandDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                    400 characters
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="BrandDescription">Description <span className="icon icon-help" role="img" aria-label="Help"></span></Label>
                                <ContentEditable
                                    id="BrandDescription" name="description"
                                    className="w-100 form-control texteditor"
                                    html={data.description}
                                    disabled={false}
                                    onChange={this.handleInputChange}
                                    autoFocus={true}
                                    maxLength="1000" required
                                    onFocus={() => context.setHelp(BrandDetailsHelp.FocusType.DESCRIPTION)}
                                    onBlur={() => context.setHelp(BrandDetailsHelp.FocusType.NONE)}
                                />
                                <FormText color="muted">
                                    1000 characters
                                </FormText>
                            </FormGroup>

                            <div className="p-4 mb-5 formbox-savebtn">
                                <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-5 text-right"><Button color="dark" size="sm" disabled={loading}>{!loading ? 'Save changes' : 'Loading..'}</Button></div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </MContext.Consumer>
        );
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true });

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const { data } = this.state;

        const response = await fetch(`/api/brand/details/${this.state.designerId}/edit`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                brandTitle: data.brandTitle,
                designerName: data.designerName,
                tagline: data.tagline,
                description: data.description
            })
        });


        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added
                console.log('Brand submitted');
                console.log(newDta);

                this.setState({ data: newDta.brand, loading: false });

                //this.props.scrollPageToTop();
                this.props.updateData(function () {
                    //update button state
                });
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }
}
