import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import authService from '../../api-authorization/AuthorizeService';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import { ProductDetailsForm } from './ProductDetailsForm';
import { ProductDetailsSizing } from './ProductDetailsSizing';
import { ProductDetailsDelivery } from './ProductDetailsDelivery';
import { DataDraftAlertMessage } from '../alert/DataDraftAlertMessage';

import ProductDetailsHelp from './ProductDetailsHelp';
import { ProductStory } from './ProductStory';
import { ProductDetailsGallery } from './ProductDetailsGallery';
import { HelpProvider } from '../../common/HelpProvider';
import { ProductSizeVariants } from './ProductSizeVariants';


export class ProductDetails extends Component {
    static displayName = ProductDetails.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            productId: props.match.params.productId,
            data: null,
            loading: true,
            error: null
        };
        this.populateData = this.populateData.bind(this);
        this.onSubmitToReview = this.onSubmitToReview.bind(this);
        this.onRevertBackData = this.onRevertBackData.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.error !== prevState.error &&
            this.state.error && this.state.error.length > 0) {
            this.scrollPageToTop();
        }
    }

    setError(error) {
        this.setState({
            error: error
        })
    }

    scrollPageToTop() {
        console.log('scroll up');
        setTimeout(function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 500);
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
        }

        const { data, designerId, productId } = this.state;

        if (!data) {
            return <Redirect to={`/manage/${designerId}/store/products`} />
        }

        return (
            <HelpProvider>
                <Row className="mt-3 mb-5">
                    <Col xs="8">
                        <h1><span className="dep-title">PRODUCT</span><span>{data.title}</span></h1>
                        <p>Edit the details of your product page.</p>
                        <DataDraftAlertMessage
                            onSubmitToReview={this.onSubmitToReview}
                            onRevertBackData={this.onRevertBackData}
                            dataDraftStatus={data.dataDraftStatus}
                            allowSubmitToReview={data.allowSubmitToReview}
                        />
                        <div className="mt-3 mb-5">
                            <ProductDetailsForm
                                data={data}
                                designerId={designerId}
                                productId={productId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <ProductDetailsGallery
                                data={data}
                                designerId={designerId}
                                productId={productId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <ProductSizeVariants
                                data={data}
                                designerId={designerId}
                                productId={productId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <ProductDetailsSizing
                                data={data}
                                designerId={designerId}
                                productId={productId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <ProductDetailsDelivery
                                data={data}
                                designerId={designerId}
                                productId={productId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <ProductStory storySetting={data.storySetting}
                                designerId={designerId}
                                productId={productId}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <div className="p-4 text-light bg-dark rounded box-shadow">
                                <h5>Product settings</h5>
                                <p></p>
                                <div className="mt-3">
                                    {/*<Button color="danger" size="sm" disabled={true} className="mr-2">Delete product</Button>*/}
                                    <Button color="danger" size="sm" disabled={true}>Disable product</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="4">
                        <ProductDetailsHelp />
                    </Col>
                </Row>
            </HelpProvider>
        );
    }

    async populateData(done) {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/details`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({
            data: data,
            loading: false
        });
        if (done) done();
    }


    async onSubmitToReview(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit/submittoreview`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({
                data: newDta.product,
                loading: false
            });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }

    async onRevertBackData(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit/revertback`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({
                data: newDta.product,
                loading: false
            });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }
}
