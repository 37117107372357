import React from 'react';
import { getMediaUrl } from '../utils/MediaConfigs';

export function Image(props) {
    return (
        <img src={getMediaUrl(props.fileName,
                              props.mediaConfig,
                              props.isDraft, props.version)}
            className={props.className}
        />
    );
}
