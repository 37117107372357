import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';

export class ProductStory extends Component {
    static displayName = ProductStory.name;

    constructor(props) {
        super(props);
    }

    render() {
        const { storySetting, designerId, productId } = this.props;

        if (!storySetting.hasApprovedStory && !storySetting.hasDraftStory) {
            return (
                <div>
                    <h3>Story</h3>
                    <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                        <div style={{ maxWidth: '400px' }} className="text-center p-4">
                            <p>Enter the editor to create and edit your product story's content, images, and text.</p>
                            <Link to={`/manage/${designerId}/store/products/${productId}/story/editor`} className="btn btn-dark mb-3" role="button">Get Started</Link>
                            <FormText color="muted">
                                This space is for all of the text, images, videos, even 3D assets that didn’t fit on your socials. Try to keep it visual, and bring your customer to understand the story behind the product or collection.
                            </FormText>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Fragment>
                <h3>Story</h3>
                <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                    <div style={{ maxWidth: '400px' }} className="text-center p-4">
                        <p>Enter the editor to modify and add content and text to your product story.</p>
                        <Link to={`/manage/${designerId}/store/products/${productId}/story/editor`} className="btn btn-dark mb-3" role="button">Edit Product Story</Link>
                        <FormText color="muted">
                            This space is for all of the text, images, videos, even 3D assets that didn’t fit on your socials. Try to keep it visual, and bring your customer to understand the story behind the product or collection.
                        </FormText>
                    </div>
                </div>
                {/*
                <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                    {
                        storySetting.hasApprovedStory &&
                        <Row>
                            <Col xs="8">
                                old story
                        </Col>
                            <Col xs="4">
                                <Link to={`/`} className="btn btn-primary" role="button">Preview</Link>
                            </Col>
                        </Row>
                    }
                    {
                        storySetting.hasDraftStory &&
                        <Row>
                            <Col xs="8">
                                new story
                        </Col>
                            <Col xs="4">
                                <Link to={`/`} className="btn btn-primary" role="button">Preview</Link>
                            </Col>
                        </Row>
                    }
                    <hr />
                    <Link to={`/manage/${designerId}/store/products/${productId}/story/editor`} className="btn btn-primary" role="button">Edit story</Link>
                </div>
                */}
            </Fragment>
        );
    }
}
