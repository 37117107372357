import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import { ProductSizeVariantAddEdit } from './ProductSizeVariantAddEdit';
import { SidePanel } from '../../common/SidePanel';
import { TransitionComponents } from '../../common/TransitionComponents';
import { isNumber } from '../../utils/Validation';

export class ProductSizeVariants extends Component {
    static displayName = ProductSizeVariants.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            productId: props.productId,
            data: props.data.sizeVariants || [],
            loading: false,
            error: null,
            editObject: null,
            showSlide: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
        this.showAddEditSlide = this.showAddEditSlide.bind(this);
        this.onSlideExited = this.onSlideExited.bind(this);
        this.updateData = this.updateData.bind(this);
        this.deleteData = this.deleteData.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(props) {
        //this.setState({ data: props.data })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false });
    }

    showAddEditSlide(index) {
        //console.log('id: ' + index);
        this.setState({
            showSlide: true,
            editObject: isNumber(index) && index >= 0 ? {
                ...this.state.data[index],
                index: index
            } : null
        });
    }

    onSlideExited() {
        this.setState({
            showSlide: false,
            editObject: null
        });
    }

    updateData(obj) {
        console.log('saving:')
        console.log(obj)
        if (!obj) return;

        if (isNumber(obj.index) && obj.index >= 0 && this.state.data.length > obj.index) {
            //edit
            this.setState({
                data: this.state.data.map((item, index) => {
                    if (obj.index === index) {
                        item.sizeCode = obj.sizeCode;
                        item.sizeDisplayTitle = obj.sizeDisplayTitle;
                        //item.orderNum = obj.orderNum;
                        item.quantityInStock = obj.quantityInStock;
                    }
                    return item;
                })
            });
        } else {
            //add
            obj.quantitySold = 0;
            this.setState({
                data: [
                    ...this.state.data,
                    obj
                ]
            });
        }
    }

    deleteData(index) {
        if (isNumber(index) && index >= 0 && this.state.data.length > index) {
            console.log('deleting: ' + index);
            const arr = this.state.data;
            arr.splice(index, 1);
            this.setState({
                data: arr
            });
        }
    }


    render() {
        const { designerId, productId, data, loading, editObject, showSlide } = this.state;

        let variantItems = this.state.data.map((item, index) => {
            return (
                <tr key={index} className={!item.isInStock ? 'warning' : ''}>
                    <th scope="row">{item.sizeDisplayTitle}</th>
                    <td>{item.sizeCode}</td>
                    <td className="text-center">{isNumber(item.quantityInStock) ? item.quantityInStock : 'No limit'}</td>
                    <td className="text-center">{item.quantitySold}</td>
                    <td className="text-center">{item.isInStock ? 'In stock' : 'Not in stock'}</td>
                    <td className="d-flex flex-row justify-content-end">
                        <button type="button" className="btn btn-default btn-sm py-0 px-1 d-flex align-items-center" aria-label="Edit"
                            onClick={() => {
                                this.showAddEditSlide(index);
                            }}>
                            <svg height="16" viewBox="0 0 48 48" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z" /><path d="M0 0h48v48h-48z" fill="none" /></svg>
                        </button>
                        <button type="button" className="btn btn-default btn-sm py-0 px-1 d-flex align-items-center" aria-label="Delete"
                            onClick={() => {
                                this.deleteData(index);
                            }}>
                            <svg height="16" viewBox="0 0 48 48" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4v-24h-24v24zm26-30h-7l-2-2h-10l-2 2h-7v4h28v-4z" /><path d="M0 0h48v48h-48z" fill="none" /></svg>
                        </button>
                    </td>
                </tr>
            );
        });

        //console.log(showSlide);
        return (
            <div>
                <h3>Size variants</h3>
                <div className="bg-white rounded box-shadow p-4 mb-4">
                    <div className="mb-1">Size variants list</div>
                    <FormText color="muted" className="text-left mb-3">
                        List of size variants available for your product
                    </FormText>

                    <Button color="dark" size="sm" className="mb-3" disabled={loading} onClick={() => this.showAddEditSlide()}>Add new variant</Button>

                    <div className="table-responsive">
                        <table className="table table-hover table-sm table-borderless table-striped">
                            <caption>List of size variants</caption>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Variant title</th>
                                    <th scope="col">Code</th>
                                    <th className="text-center" scope="col">Quantity in stock</th>
                                    <th className="text-center" scope="col">Quantity sold</th>
                                    <th className="text-center" scope="col">Status</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {variantItems}
                            </tbody>
                        </table>
                    </div>

                    <div className="p-4 mb-5 formbox-savebtn">
                        <div className="row">
                            <div className="col-7"></div>
                            <div className="col-5 text-right">
                                <Button color="dark" size="sm" disabled={loading} onClick={() => this.handleSubmit()}>{!loading ? 'Save changes' : 'Loading..'}</Button>
                            </div>
                        </div>
                    </div>

                    <TransitionComponents className="slideanim"
                        show={loading === false && showSlide}>
                        <ProductSizeVariantAddEdit
                            designerId={designerId}
                            productId={productId}
                            data={editObject}
                            onExited={this.onSlideExited}
                            update={this.updateData}
                        />
                    </TransitionComponents>
                </div>
            </div>
        );
    }

    async handleSubmit() {
        this.setState({ loading: true });

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const { data } = this.state;

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit/sizevariants`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(data)
        });


        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added
                console.log('Brand submitted');
                console.log(newDta);

                this.setState({ data: newDta.productSizeVariants, loading: false });

                //this.props.scrollPageToTop();
                this.props.updateData(function () {
                    //update button state
                });
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }

}
