import React from 'react';
import { useSortable, defaultAnimateLayoutChanges} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ProductDetailsGalleryItem } from './ProductDetailsGalleryItem';

function animateLayoutChanges(args) {
    return defaultAnimateLayoutChanges({ ...args, wasDragging: true });
    /*
    const { isSorting, wasSorting } = args;

    if (isSorting || wasSorting) {
        return defaultAnimateLayoutChanges(args);
    }

    return true;*/
}

export function ProductDetailsGallerySortableItem(props) {

    const {
        active,
        attributes,
        listeners,
        setNodeRef,
        isSorting,
        transform,
        transition,
    } = useSortable({
        animateLayoutChanges,
        id: props.id
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isSorting && active.id === props.id ? '0.5' : '1',
    };

    return (
        <div className="position-relative dnd-sortableitem"
            ref={setNodeRef}
            style={style}
            {...attributes}>
            <ProductDetailsGalleryItem file={props.file} />
            <div {...listeners} className="dnd-movebtn">Move</div>
            <button
                type="button"
                className="close dnd-removebtn"
                aria-label="Remove"
                onClick={() => props.removeItem(props.id)}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
}