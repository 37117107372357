import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useHistory } from 'react-router-dom'
import './SidePanel.css';

export function SidePanel(props) {
    const [showPage, setShowPage] = useState(true);

    let history = useHistory();

    function handleClosePanel() {
        if (props.pageLoading) return;
        setShowPage(false);
    }

    function exit() {
        if (props.closeUrl) {
            //redirect
            history.push(props.closeUrl)
        } else if (props.onExited) {
            props.onExited();
        }
    }

    return (
        <CSSTransition
            in={showPage}
            timeout={300}
            classNames="slideanim2"
            unmountOnExit
            onExited={() => exit()}
        >
            <div className="side-panel">
                <div className="side-panel__overlay" onClick={handleClosePanel} />
                <div className="side-panel__content">
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={handleClosePanel}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {props.children}
                </div>
            </div>
        </CSSTransition>

    );
}
