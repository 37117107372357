import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { Button, Form, FormGroup, Label, Input, FormText, Alert } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService'
import uploadFileToBlob from '../utils/AzureBlob';
import { validateImage } from '../utils/Validation';
import ContentEditable from 'react-contenteditable';

export class BrandSignup extends Component {
    static displayName = BrandSignup.name;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            complete: false,
            brandTitle: '',
            designerName: '',
            tagline: '',
            description: '',
            brandWebsite: '',
            brandInstagram: '',
            serviceAgreement: false,
            logo: null,
            portrait: null,
            uploadPercentage: null
        };

        this.setError = this.setError.bind(this);
        this.resetError = this.resetError.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.uploadProgress = this.uploadProgress.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.error !== prevState.error &&
            this.state.error && this.state.error.length > 0) {
            console.log('scroll up');
            setTimeout(function () {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }, 500);
        }
    }


    setError(error) {
        this.setState({
            error: error
        })
    }

    resetError() {
        this.setState({
            error: null
        })
    }


    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.resetError();

        if (!this.state.serviceAgreement) {
            this.setError(`Please indicate that you have read and agree to the Service Agreement.`);
            return;
        }

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        console.log('submitting..');
        console.log(this.state);

        this.setState({
            loading: true, uploadPercentage: 0
        });

        const logoValid = await validateImage(this.state.logo, 20, 20);
        console.log(logoValid);
        if (!logoValid.result) {
            this.setError(logoValid.error);
            return;
        }

        const portraitValid = await validateImage(this.state.portrait, 1000, 1000);
        console.log(portraitValid);
        if (!portraitValid.result) {
            this.setError(portraitValid.error);
            return;
        }


        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch('/api/brand/new-brand-signup', {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                brandTitle: this.state.brandTitle,
                designerName: this.state.designerName,
                tagline: this.state.tagline,
                description: this.state.description,
                brandWebsite: this.state.brandWebsite,
                brandInstagram: this.state.brandInstagram,
                logoFileName: this.state.logo.name,
                portraitFileName: this.state.portrait.name
            })
        });

        try {
            const data = await response.json();
            if (data && data.result) {
                //added
                console.log('Brand submitted');
                await this.uploadImages(data);
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }

    async uploadImages(data) {
        console.log('Uploading images');

        //upload logo
        await uploadFileToBlob(
            data.azureStorage.accountUrl,
            data.azureStorage.containerName,
            data.logo.storageSasToken,
            this.state.logo,
            data.logo.fileName,
            data.userId,
            this.uploadProgress,
            function() {
                //success
                //upload portrait
                uploadFileToBlob(
                    data.azureStorage.accountUrl,
                    data.azureStorage.containerName,
                    data.portrait.storageSasToken,
                    this.state.portrait,
                    data.portrait.fileName,
                    data.userId,
                    this.uploadProgress,
                    function() {
                        //success
                        this.setState({
                            complete: true
                        });
                    }.bind(this),
                    function() {
                        //failed
                        this.setError(`Failed to upload portrait`);
                    }.bind(this),
                );
            }.bind(this),
            function() {
                //failed
                this.setError(`Failed to upload logo`);
            }.bind(this)
        );
    }
  
    uploadProgress(ev, percentage) {
        this.setState({ uploadPercentage: Math.round(percentage) });
        console.log(`${percentage}%`);
    }

    render() {
        if (this.state.complete) {
            return <Redirect to="/new-brand-signup/complete" />
        }

        const { brandTitle, designerName, tagline, description, brandWebsite, brandInstagram,
            error, loading, uploadPercentage } = this.state;

        return (
            <div className="main-sign mw-600px p-5">
                <h1 className="text-center"><span className="brand-lg d-inline-block">Flair Designer Portal</span></h1>
                <h4 className=" mt-3 text-center text-uppercase">Start your brand</h4>

                <Form onSubmit={this.handleSubmit}>

                    <Alert color="danger" isOpen={error} toggle={this.resetError}>
                        {error}
                    </Alert>

                    <FormGroup>
                        <Label for="BrandTitle" className="font-weight-bold">Brand name</Label>
                        <Input type="text" name="brandTitle" id="BrandTitle" placeholder="Your brand name"
                            maxLength="100" required
                            onChange={this.handleInputChange}
                            value={brandTitle} />
                        {/*
                        <FormText color="muted">
                            This is some placeholder block-level help text for the above input.
                            It's a bit lighter and easily wraps to a new line.
                        </FormText>
                        */}
                    </FormGroup>

                    <FormGroup>
                        <Label for="DesignerName" className="font-weight-bold">Designer name(s)</Label>
                        <Input type="text" name="designerName" id="DesignerName" placeholder="Names of designer(s) behind the brand"
                            maxLength="100" required
                            onChange={this.handleInputChange}
                            value={designerName} />
                        {/*
                        <FormText color="muted">
                            This is some placeholder block-level help text for the above input.
                            It's a bit lighter and easily wraps to a new line.
                        </FormText>
                        */}
                    </FormGroup>

                    <FormGroup>
                        <Label for="Tagline" className="font-weight-bold">Tagline</Label>
                        <Input type="textarea" name="tagline" id="Tagline" placeholder="Brand tagline"
                            maxLength="400" required
                            onKeyDown={this.handleSingleLineTextArea}
                            onChange={this.handleInputChange}
                            value={tagline} />
                        <FormText color="muted">
                            400 characters<br/>
                            Brand's tagline is a memorable motto or phrase that's designed to serve as a permanent expression of your company's greater purpose and mission.
                        </FormText>
                    </FormGroup>


                    <FormGroup>
                        <Label for="BrandDescription" className="font-weight-bold">Description</Label>
                        <ContentEditable
                            id="BrandDescription" name="description"
                            className="w-100 form-control texteditor"
                            html={description}
                            disabled={false}
                            onChange={this.handleInputChange}
                            autoFocus={true}
                            maxLength="1000" required
                        />
                        <FormText color="muted">
                            1000 characters<br />
                            2-3 paragraphs briefly describing your brand’s most notable details.
                        </FormText>
                    </FormGroup>

                    <hr />

                    <div>
                        <div className="row">
                            <div className="col-6">
                                <FormGroup>
                                    <Label for="BrandLogo" className="font-weight-bold">Brand logo</Label>
                                    <input type="file" name="logo" id="BrandLogo" onChange={this.handleInputChange} className="form-control-file" required />
                                    <FormText color="muted" className="mt-3">
                                        <ul>
                                            <li>Acceptable formats: <b>JPEG, PNG and SVG</b></li>
                                            <li>Max file size: <b>20MB</b></li>
                                            <li>Upload the highest image resolution, smaller images may become pixelated when they are resized.</li>
                                            <li>Make sure your logo extends to the edges of the image file.</li>
                                        </ul>
                                    </FormText>
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                <FormGroup>
                                    <Label for="BrandPortrait" className="font-weight-bold">Designer(s) portrait</Label>
                                    <input type="file" name="portrait" id="BrandPortrait" onChange={this.handleInputChange} required />
                                    <FormText color="muted" className="mt-3">
                                        <p>
                                            Upload the portrait of the designer(s). Allow consumers to see the face(s) behind your brand <b>(Recommended)</b>.
                                        </p>
                                        <ul>
                                            <li>Acceptable formats: <b>JPEG, PNG and SVG</b></li>
                                            <li>Max file size: <b>30MB</b></li>
                                            <li>Upload the highest image resolution, smaller images may become pixelated when they are resized.</li>
                                        </ul>
                                    </FormText>
                                </FormGroup>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <FormGroup>
                        <Label for="BrandWebsite" className="font-weight-bold">Website (optional)</Label>
                        <Input type="text" name="brandWebsite" id="BrandWebsite" placeholder="Link to brand's website"
                            maxLength="400" onChange={this.handleInputChange}
                            value={brandWebsite} />
                        <FormText color="muted">
                            Link to your brand's website. This will help Flair's team to review your application.
                        </FormText>
                    </FormGroup>

                    <FormGroup>
                        <Label for="BrandInstagram" className="font-weight-bold">Instagram (optional)</Label>
                        <Input type="text" name="brandInstagram" id="BrandInstagram" placeholder="Link to brand's instagram"
                            maxLength="200" onChange={this.handleInputChange}
                            value={brandInstagram} />
                        <FormText color="muted">
                            Link to your brand's Instagram account. This will help Flair's team to review your application.
                        </FormText>
                    </FormGroup>

                    <FormGroup className="mt-4" check>
                        <Label check>
                            <Input name="serviceAgreement" id="ServiceAgreement" onChange={this.handleInputChange}
                                type="checkbox" />{' '}
                            I have read and agree with the <a href="/service-agreement" target="_blank">Service Agreement</a>
                        </Label>
                    </FormGroup>

                    <Button className="btn-block mt-3 mb-5" color="dark" disabled={loading}>{!loading || uploadPercentage === null ? 'Submit your brand' : 'Uploading ' + uploadPercentage + '%'}</Button>
                </Form>
            </div>
        );
    }
}