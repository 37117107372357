import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './SideNavMenu.css';

export class SideNavMenu extends Component {
    static displayName = SideNavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { designerId } = this.props;
        return (
            <header>
                <Navbar className="navbar-expand-md navbar-toggleable-md ng-white border-bottom box-shadow mb-3 sidemenu" light>
                    <Container className="sidebar-sticky flex-md-column">
                        <NavbarBrand tag={Link} to="/">Flair Designer Portal</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-md-inline-flex flex-md-column w-100" isOpen={!this.state.collapsed} navbar>
                            <ul className="nav flex-column w-100">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}`}>Dashboard</NavLink>
                                </NavItem>
                            </ul>

                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-4 mt-2 mb-1 text-muted w-100">
                                <span>My Brand</span>
                            </h6>
                            <ul className="nav flex-column w-100">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}/brand`}>Brand Showcase</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}/collections`}>Collections</NavLink>
                                </NavItem>
                                {/*
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Brand Settings</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/counter">Settings</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                                </NavItem>
                                */}
                            </ul>

                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-4 mt-2 mb-1 text-muted w-100">
                                <span>My Store</span>
                            </h6>
                            <ul className="nav flex-column w-100">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}/store/products`}>Products</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}/store/orders`}>Orders</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}/store/settings`}>Store Settings</NavLink>
                                </NavItem>
                            </ul>

                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-4 mt-2 mb-1 text-muted w-100">
                                <span>Connection Hub</span>
                            </h6>
                            <ul className="nav flex-column w-100">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={`/manage/${designerId}/connection-hub`}>Home</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
