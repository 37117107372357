import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import { ProductDetailsGalleryUpload } from './ProductDetailsGalleryUpload';
import { ProductDetailsGallerySortable } from './ProductDetailsGallerySortable';

import { MContext } from '../../common/HelpProvider';

import './ProductDetailsGallery.css';

export class ProductDetailsGallery extends Component {
    static displayName = ProductDetailsGallery.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            productId: props.productId,
            //data: props.data,
            mediaList: props.data.mediaGallery,
            loading: false,
            error: null
        };

        //this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateMediaList = this.updateMediaList.bind(this);
        this.setError = this.setError.bind(this);
    }

    updateMediaList(media) {
        this.setState({ mediaList: media }, function () {
            if (!media || !media.length || media.length <= 0) {
                this.handleSubmit();
            }
        }.bind(this));
    }

    componentWillReceiveProps(props) {
        console.log("updated gallery");
        console.log(props.data.mediaGallery);
        this.setState({
            //data: props.data,
            mediaList: props.data.mediaGallery,
        })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false });
    }

    /*
    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    }
    */

    render() {
        const { mediaList, loading, designerId, productId } = this.state;

        if (!mediaList || mediaList.length <= 0) {
            return (
                <div>
                    <h3>Gallery</h3>
                    <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center" style={{minHeight:'150px'}}>
                        <ProductDetailsGalleryUpload
                            media={mediaList}
                            designerId={designerId}
                            productId={productId}
                            setError={this.props.setError}
                            updateData={this.props.updateData}
                        />
                    </div>
                </div>
            );
        }

        return (
            <MContext.Consumer>
                {(context) => (
                    <div>
                        <h3>Gallery</h3>
                        <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                            <div className="row mb-3">
                                <div className="col-5">
                                    <ProductDetailsGalleryUpload
                                        media={mediaList}
                                        designerId={designerId}
                                        productId={productId}
                                        setError={this.props.setError}
                                        updateData={this.props.updateData}
                                    />
                                </div>
                                <div className="col-7">
                                    <ProductDetailsGallerySortable
                                        media={mediaList}
                                        designerId={designerId}
                                        productId={productId}
                                        setError={this.props.setError}
                                        updateMedia={this.updateMediaList}
                                    />
                                </div>
                            </div>
                            <div className="p-4 mb-5 formbox-savebtn">
                                <div className="row">
                                    <div className="col-7"></div>
                                    <div className="col-5 text-right">
                                        <Form onSubmit={this.handleSubmit}>
                                           <Button color="dark" size="sm" disabled={loading}>{!loading ? 'Save gallery' : 'Loading..'}</Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </MContext.Consumer>
        );
    }

    async handleSubmit(event) {
        if (event) {
            event.preventDefault();
        }

        this.setState({ loading: true });

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.props.setError(`Could not retrieve authentication`);
            return;
        }

        const { mediaList } = this.state;

        const newMedia = mediaList ?
            mediaList.map(item => ({
                fileId: item.fileId,
                fileName: item.file.fileName
            }))
            : null;

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit/save-gallery`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(newMedia)
        });

        try {
            const newDta = await response.json();

            if (newDta && newDta.result) {
                //added

                console.log('new image reorder submitted');
                console.log(newDta);

                this.setState({ mediaList: newDta.mediaGallery, loading: false, complete: true });
                this.props.updateData(function () {
                    //update button state
                });
            } else {
                this.props.setError(`Error`);
            }
        } catch (e) {
            this.props.setError(`Error`);
            console.log(e);
        }
    }
}
