import css from './Index.css';
import VideoBlockUI from './UI';
import VideoBlockTunes from './Tunes';
import Uploader from '../../utilities/uploader/Uploader';
import { format } from '../../utilities/Utilities';

export default class VideoBlock {
    static get draftBaseUrl() {
        return "https://ik.imagekit.io/flair/draft/%VIDEO%/ik-master.m3u8?tr=sr-360_480_720_1080";
    }
    static get liveBaseUrl() {
        return "https://ik.imagekit.io/flair/%VIDEO%/ik-master.m3u8?tr=sr-360_480_720_1080";
    }
    static get thumbnailDraftBaseUrl() {
        return "https://ik.imagekit.io/flair/draft/";
    }
    static get thumbnailLiveBaseUrl() {
        return "https://ik.imagekit.io/flair/";
    }
    static get isReadOnlySupported() {
        return true;
    }

    static get isReadOnlySupported() {
        return true;
    }

    static get toolbox() {
        return {
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.7,12c0,0.7-0.4,1.3-1,1.6l-17.9,9c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.7-0.1-0.9-0.3 c-0.5-0.3-0.8-0.9-0.8-1.5V3c0-1,0.8-1.8,1.8-1.8c0.3,0,0.6,0.1,0.8,0.2l17.9,9C22.4,10.7,22.7,11.3,22.7,12z"/></svg>',
            title: 'Video',
        };
    }

    static get DEFAULT_DATA() {
        return {
            input_placeholder: 'Text here...',
            stretched: true,
            showCaption: false,
            loop: false,
            showControls: true,
            videoStyle: 'full'
        }
    }

    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;

        /**
         * Tool's initial config
         */
        this.config = {
            endpoints: config.endpoints || '',
            additionalRequestData: config.additionalRequestData || {},
            additionalRequestHeaders: config.additionalRequestHeaders || {},
            field: config.field || 'video',
            types: config.types || 'video/*',
            captionPlaceholder: this.api.i18n.t(config.captionPlaceholder || VideoBlock.DEFAULT_DATA.input_placeholder),
            buttonContent: config.buttonContent || '',
            uploader: config.uploader || undefined,
            actions: config.actions || [],

            designerId: config.designerId,
            id: config.id,
            storyType: config.storyType,

        };

        /**
         * Module for file uploading
         */
        this.uploader = new Uploader({
            config: this.config,
            onUpload: (response) => this.onUpload(response),
            onError: (error) => this.uploadingFailed(error),
            onUploadProgress: (ev, percentage) => this.onUploadProgress(ev, percentage),
        });


        /**
         * Module for working with UI
         */
        this.ui = new VideoBlockUI({
            api,
            config: this.config,
            onSelectFile: () => {
                this.uploader.uploadSelectedFile({
                    onPreview: (src) => {
                        this.ui.showPreloader(src);
                    },
                });
            },
            readOnly,
        });

        /**
         * Module for working with tunes
         */
        this.tunes = new VideoBlockTunes({
            api,
            actions: this.config.actions,
            onChange: (tuneName, value) => this.tuneToggled(tuneName, value),
        });

        /**
         * Set saved state
         */
        this._data = {};
        this.data = data;
    }

    /**
     * Renders Block content
     *
     * @public
     *
     * @returns {HTMLDivElement}
     */
    render() {
        return this.ui.render(this.data);
    }

    /**
     * Validate data: check if Video exists
     *
     * @param {VideoBlockData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        return (savedData.file && savedData.file.url) ||
            (savedData.filedraft && savedData.filedraft.url);
    }

    /**
     * Return Block data
     *
     * @public
     *
     * @returns {VideoBlockData}
     */
    save() {
        const caption = this.ui.nodes.caption;

        this._data.caption = caption.innerHTML;

        return this.data;
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch Video
     *
     * @public
     *
     * @returns {Element}
     */
    renderSettings() {
        return this.tunes.render(this.data);
    }

    /**
     * Fires after clicks on the Toolbox Video Icon
     * Initiates click on the Select File button
     *
     * @public
     */
    appendCallback() {
        this.ui.nodes.fileButton.click();
    }

    /**
     * Specify paste substitutes
     *
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
     */
    static get pasteConfig() {
        return {
            /**
             * Paste HTML into Editor
             */
            tags: ['video'],

            /**
             * Paste URL of Video into the Editor
             */
            patterns: {
                video: /https?:\/\/\S+\.(mp4|m4v|ogg|mov)$/i,
            },

            /**
             * Drag n drop file from into the Editor
             */
            files: {
                mimeTypes: ['video/*'],
            },
        };
    }

    /**
     * Specify paste handlers
     *
     * @public
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @param {CustomEvent} event - editor.js custom paste event
     *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
     * @returns {void}
     */
    async onPaste(event) {
        switch (event.type) {
            case 'video': {
                const video = event.detail.data;

                /** Images from PDF */
                if (/^blob:/.test(video.src)) {
                    const response = await fetch(video.src);
                    const file = await response.blob();

                    this.uploadFile(file);
                    break;
                }

                this.uploadUrl(video.src);
                break;
            }
            case 'pattern': {
                const url = event.detail.data;

                this.uploadUrl(url);
                break;
            }
            case 'file': {
                const file = event.detail.file;

                this.uploadFile(file);
                break;
            }
        }
    }

    static get sanitize() {
        return {
            filedraft: {},
            file: {},
            thumbnail: {},
            caption: {
                br: {}
            },
            stretched: {},
            showCaption: {},
            showControls: {},
            loop: {},
            style: {}
        };
    }

    normalizeData(data) {
        const newData = {};

        if (typeof data !== 'object') {
            data = {};
        }

        newData.file = data.file && data.file.url ? {
            url: data.file.url
        } : {};

        newData.filedraft = data.filedraft && data.filedraft.url ? {
            url: data.filedraft.url
        } : {}

        newData.caption = data.caption || '';

        newData.thumbnail = data.thumbnail || null;

        newData.stretched = data.stretched !== undefined ? data.stretched : VideoBlock.DEFAULT_DATA.stretched;
        newData.showCaption = data.showCaption !== undefined ? data.showCaption : VideoBlock.DEFAULT_DATA.showCaption;
        newData.showControls = data.showControls !== undefined ? data.showControls : VideoBlock.DEFAULT_DATA.showControls;
        newData.loop = data.loop !== undefined ? data.loop : VideoBlock.DEFAULT_DATA.loop;

        newData.style = data.style !== undefined ? data.style : VideoBlock.DEFAULT_DATA.videoStyle;

        return newData;
    }

    /**
     * Stores all Tool's data
     *
     * @private
     *
     * @param {VideoBlockData} data - data in Video Tool format
     */
    set data(data) {
        this._data = Object.assign({}, this.data, data);
        this._data = this.normalizeData(this._data);

        if (this._data.filedraft && this._data.filedraft.url) {
            this.ui.fillVideo(format(VideoBlock.draftBaseUrl, { '%VIDEO%': this._data.filedraft.url }));
        } else if (this._data.file && this._data.file.url) {
            this.ui.fillVideo(format(VideoBlock.liveBaseUrl, { '%VIDEO%': this._data.file.url }));
        } else {
            //this.ui.fillBanner('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" style="background-color:%23333" width="16" height="7" viewBox="0 0 100 100"%3e%3c/svg%3e');
        }

        this.thumbnail = this._data.thumbnail;

        this.ui.fillCaption(this._data.caption);

        /*
        for (var key in VideoBlockTunes.tunes) {
            if (VideoBlockTunes.tunes.hasOwnProperty(key) &&
                (key !== 'styles' && key !== 'videoTunes')) {

                VideoBlockTunes.tunes[key].forEach(({ name: tune }) => {
                    const value = typeof this._data[tune] !== 'undefined' ? this._data[tune] === true || this._data[tune] === 'true' : false;

                    this.setTune(tune, value);
                });
            }
        }*/
        /*OLDOLDOLD
        VideoBlockTunes.tunes.forEach(({ name: tune }) => {
            const value = typeof data[tune] !== 'undefined' ? data[tune] === true || data[tune] === 'true' : false;

            this.setTune(tune, value);
        });*/

        this.setTune('style', this.tunes.confirmTuneValueOrGetFirstValue('style', this._data.style));
    }

    /**
     * Return Tool data
     *
     * @private
     *
     * @returns {VideoBlockData}
     */
    get data() {
        return this._data;
    }

    /**
     * Set new Video file
     *
     * @private
     *
     * @param {object} file - uploaded file data
     
    set banner(file) {
      this._data.file = file || {};
  
      if (file && file.url) {
        this.ui.fillBanner(file.url);
      }
    }*/
    set videoDraft(file) {
        if (file && file.url) {
            this._data.filedraft = file;
            this.ui.fillVideo(format(VideoBlock.draftBaseUrl, { '%VIDEO%': file.url }));

            this.thumbnail = null;

            this.api.notifier.show({
                message: this.api.i18n.t(`Video uploaded successfully. It will take some time to process and show. Save your changes now!`),
                style: 'success',
                time: 10000
            });
            
            this.api.notifier.show({
                message: this.api.i18n.t(`Video thumbnail has been reset.`),
                style: 'success',
                time: 10000
            });
        }
    }

    set thumbnail(path) {
        let url = null;
        this.data.thumbnail = path || null;

        if (this.data.thumbnail) {
            if (this.data.filedraft && this.data.filedraft.url) {
                url = VideoBlock.thumbnailDraftBaseUrl + this.data.thumbnail;
            } else if (this.data.file && this.data.file.url) {
                url = VideoBlock.thumbnailLiveBaseUrl + this.data.thumbnail;
            }
        }

        this.ui.fillThumbnail(url);
    }

    /**
     * File uploading callback
     *
     * @private
     *
     * @param {UploadResponseFormat} response - uploading server response
     * @returns {void}
     */
    onUpload(response) {
        if (response.success && response.file) {
            this.videoDraft = response.file;
        } else {
            this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
        }
    }

    onUploadProgress(ev, percentage) {
        this.ui.updateUploadProgress(ev, percentage);
    }


    /**
     * Handle uploader errors
     *
     * @private
     * @param {string} errorText - uploading error text
     * @returns {void}
     */
    uploadingFailed(errorText) {
        console.log('Video Tool: uploading failed because of', errorText);

        this.api.notifier.show({
            message: this.api.i18n.t('Couldn’t upload video. Please try another.'),
            style: 'error',
        });
        this.ui.hidePreloader();
    }

    /**
     * Callback fired when Block Tune is activated
     *
     * @private
     *
     * @param {string} tuneName - tune that has been clicked
     * @returns {void}
     */
    tuneToggled(tuneName, value) {
        // inverse tune state

        if (tuneName === 'style') {
            this.setTune(tuneName, this.tunes.confirmTuneValueOrGetFirstValue(tuneName, value));
        } else if (tuneName === 'uploadVideo' || tuneName === 'captureThumbnail') {
            this.setTune(tuneName, false);
        } else {
            this.setTune(tuneName, !this.data[tuneName]);
        }
    }

    /**
     * Set one tune
     *
     * @param {string} tuneName - {@link VideoBlockTunes.tunes}
     * @param {boolean} value - tune state
     * @returns {void}
     */
    setTune(tuneName, value) {
        this.data[tuneName] = value;

        this.ui.applyTune(tuneName, value);

        if (tuneName === 'stretched') {
            /**
             * Wait until the API is ready
             */
            Promise.resolve().then(() => {
                const blockId = this.api.blocks.getCurrentBlockIndex();

                this.api.blocks.stretchBlock(blockId, value);
            })
                .catch(err => {
                    console.error(err);
                });
        } else if (tuneName === 'uploadVideo') {
            this.ui.nodes.fileButton.click();
        } else if (tuneName === 'captureThumbnail') {
            this.captureThumbnail();
        } else if (this.ui.videoPlayerElement) {
            if (tuneName === 'showControls') {
                this.ui.videoPlayerElement.setControlBar(value);
            }
            else if (tuneName === 'loop') {
                this.ui.videoPlayerElement.setLoop(value);
            }
        }
    }

    /**
     * Show preloader and upload Video file
     *
     * @param {File} file - file that is currently uploading (from paste)
     * @returns {void}
     */
    uploadFile(file) {
        this.uploader.uploadByFile(file, {
            onPreview: (src) => {
                this.ui.showPreloader(src);
            },
        });
    }

    captureThumbnail() {
        let videoUrl = null;
        if (this.data.filedraft && this.data.filedraft.url) {
            videoUrl = this.data.filedraft.url;
        } else if (this.data.file && this.data.file.url) {
            videoUrl = this.data.file.url;
        }

        if (this.ui.videoPlayerElement && videoUrl) {
            //create path

            const time = Math.round(this.ui.videoPlayerElement.getCurrentTime() * 100) / 100;

            this.thumbnail = format(
                '%VIDEO%/ik-thumbnail.jpg?tr=so-%TIME%',
                {
                    '%VIDEO%': videoUrl,
                    '%TIME%': time
                }
            );

            this.api.notifier.show({
                message: this.api.i18n.t(`Screenshot of the video has been taken at ${time} seconds. It will take some time to process and show. Save your changes now!`),
                style: 'success',
                time: 10000
            });
        } else {
            this.api.notifier.show({
                message: this.api.i18n.t('No video to take screenshot of.'),
                style: 'error',
            });
        }
    }

    /**
     * Show preloader and upload Video by target url
     *
     * @param {string} url - url pasted
     * @returns {void}
     */
    uploadUrl(url) {
        this.ui.showPreloader(url);
        this.uploader.uploadByUrl(url);
    }
}
