import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SideNavMenu } from './navigation/SideNavMenu';
import { TopNavMenu } from './navigation/TopNavMenu';
import authService from '../../api-authorization/AuthorizeService'
import './ManagementLayout.css';

export class ManagementLayout extends Component {
    static displayName = ManagementLayout.name;

    render() {
        return (
            <div>
                <SideNavMenu designerId={this.props.designerId} />
                <div className="main">
                    <Container>
                        <TopNavMenu />
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}
