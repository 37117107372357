import React, { Component } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Redirect, Link } from 'react-router-dom'
import { SidePanel } from '../../common/SidePanel';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import uploadFileToBlob from '../../utils/AzureBlob';
import { validateImage } from '../../utils/Validation';

export class AddProduct extends Component {
    static displayName = AddProduct.name;

    constructor(props) {
        super(props);

        this.state = {
            designerId: props.match.params.designerId,
            error: null,
            complete: false,
            redirect: null,
            title: '',
            description: ''
        };

        this.setError = this.setError.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    setError(error) {
        this.setState({
            error: error
        })
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    async handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch(`/api/brand/${this.state.designerId}/store/products/new`, {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({
                title: this.state.title,
                description: this.state.description
            })
        });

        try {
            const data = await response.json();
            if (data && data.result) {
                //added
                console.log('Product submitted');
                console.log(data);
                this.setState({
                    redirect: `/manage/${data.designerId}/store/products/${data.id}/details`,
                    complete: true
                });
            } else {
                this.setError(`Error`);
            }
        } catch (e) {
            this.setError(`Error`);
            console.log(e);
        }
    }

    render() {
        const { designerId } = this.state;

        if (this.state.complete) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <SidePanel closeUrl={`/manage/${designerId}/store/products`}>
                <div>
                    <h1>New products</h1>
                    <p>Adding a new product.</p>
                    <Form onSubmit={this.handleSubmit}>

                        <FormGroup>
                            <Label for="Title">Title</Label>
                            <Input type="text" name="title" id="Title" placeholder="Title"
                                maxLength="100" required
                                onChange={this.handleInputChange}
                                value={this.state.title} />
                            <FormText color="muted">
                                This is some placeholder block-level help text for the above input.
                                It's a bit lighter and easily wraps to a new line.
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="Description">Description</Label>
                            <Input type="textarea" name="description" id="Description" placeholder="Description"
                                maxLength="400" required
                                onKeyDown={this.handleSingleLineTextArea}
                                onChange={this.handleInputChange}
                                value={this.state.description} />
                            <FormText color="muted">
                                This is some placeholder block-level help text for the above input.
                                It's a bit lighter and easily wraps to a new line.
                            </FormText>
                        </FormGroup>

                        <Button>Create</Button>
                    </Form>

                </div>
            </SidePanel>
        );
    }
}
