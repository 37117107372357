import React, { Component } from 'react';
export const MContext = React.createContext();  //exporting context object

export class HelpProvider extends Component {
    state = { help: null }
    render() {
        return (
            <MContext.Provider value={
                {
                    state: this.state,
                    setHelp: (value) => this.setState({
                        help: value
                    })
                }}>
                {this.props.children}
            </MContext.Provider>)
    }
}
