import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';

import { uploadSelectedFile } from '../../utils/FileUploader';
import { validateImage } from '../../utils/Validation';

import { Image } from '../../common/UIElements';
import { BrandMediaConfig } from '../../utils/MediaConfigs';


export class BrandDetailsPortrait extends Component {
    static displayName = BrandDetailsPortrait.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            data: props.data,
            loading: false,
            uploadPercentage: null,
            error: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadProgress = this.uploadProgress.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentWillReceiveProps(props) {
        //this.setState({ data: props.data })
    }

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false, uploadPercentage: null });
    }

    render() {
        const { data, loading, uploadPercentage } = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <h3>Portrait</h3>
                <div className="bg-white rounded box-shadow p-4 mb-4 mb-lg-0">
                    <FormGroup>
                        <Label for="NewPortrait">Designer portrait</Label>
                        <FormText color="muted">
                            <p>
                                Upload the portrait of the designer(s). Allow consumers to see the face(s) behind your brand <b>(Recommended)</b>.
                            </p>
                            <ul>
                                <li>Acceptable formats: <b>JPEG, PNG and SVG</b></li>
                                <li>Max file size: <b>30MB</b></li>
                                <li>Upload the highest image resolution, smaller images may become pixelated when they are resized.</li>
                            </ul>
                        </FormText>
                        <div className="mt-4 mb-3">
                            <Image fileName={data.portrait.fileName}
                                isDraft={data.portrait.isDraft}
                                mediaConfig={BrandMediaConfig.Portrait}
                                className="w-100"
                            />
                        </div>
                    </FormGroup>
                    <div className="p-4 mb-5 formbox-savebtn">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-10 text-right"><Button color="dark" size="sm" disabled={loading}>{!loading || uploadPercentage === null ? 'Replace image' : 'Uploading ' + uploadPercentage + '%'}</Button></div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }

    async handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const { data } = this.state;


        uploadSelectedFile({
            config: {
                url: `/api/brand/details/${this.state.designerId}/edit/image-upload`,
                reqBody: {
                    imageType: 2
                },
                types: 'image/*',
            },
            onValidate: function (file) {
                return validateImage(file, 10, 10);
            }.bind(this),
            onUploadStart: function () {
                this.setState({ loading: true, uploadPercentage: 0 });
            }.bind(this),
            onUploadDone: function (res) {
                console.log('new logo submitted');
                console.log(res);
                //success
                this.setState({ data: res.data.brand, loading: false, uploadPercentage: null, complete: true });
                //this.props.scrollPageToTop();
                this.props.updateData(function () {
                    //update button state
                });
            }.bind(this),
            onError: function (error) {
                this.setError(error);
                console.log(error);

            }.bind(this),
            onUploadProgress: this.uploadProgress
        });

    }


    uploadProgress(ev, percentage) {
        this.setState({ uploadPercentage: Math.round(percentage) });
        console.log(`${percentage}%`);
    }

}
