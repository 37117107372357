import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './TransitionRoutes.css';

//link

export function TransitionRoutes(props) {
    const location = useLocation();

    return (
        <Route>
            <TransitionGroup>
                <CSSTransition
                    timeout={300}
                    classNames={props.className}
                    key={location.key}>
                    <div>
                        <Switch location={location}>
                            {props.children}
                        </Switch>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Route>
    );
}
