import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from '../../../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../../../api-authorization/ApiAuthorizationConstants';
import './AccountNav.css';

export class AccountNav extends Component {
    static displayName = AccountNav.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            dropdownOpen: false
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const { isAuthenticated, userName, dropdownOpen } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath, dropdownOpen);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, dropdownOpen) {
        return (
            <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown} className="myaccount-menu">
                <DropdownToggle caret color="dark" className="btn-sm rounded-pill d-flex align-items-center">
                <svg height="16px" className="mr-2" version="1.1" viewBox="0 0 20 20" width="20px" xmlns="http://www.w3.org/2000/svg"><title /><desc /><defs /><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#fff" id="Core" transform="translate(-86.000000, -2.000000)"><g id="account-circle" transform="translate(86.000000, 2.000000)"><path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,3 C11.7,3 13,4.3 13,6 C13,7.7 11.7,9 10,9 C8.3,9 7,7.7 7,6 C7,4.3 8.3,3 10,3 L10,3 Z M10,17.2 C7.5,17.2 5.3,15.9 4,14 C4,12 8,10.9 10,10.9 C12,10.9 16,12 16,14 C14.7,15.9 12.5,17.2 10,17.2 L10,17.2 Z" id="Shape" /></g></g></g></svg>
                <span className="mr-1">{userName}</span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a" href={profilePath}>My account</DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag={Link} to={logoutPath}>Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
        );
    }
    
    anonymousView(registerPath, loginPath) {
        return (<Fragment />);
        /*
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
        */
    }
}
