import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'

export class ReviewInProcessPage extends Component {
    static displayName = ReviewInProcessPage.name;

    render() {
        return (
            <div className="main-sign mw-600px p-5 text-center">
                <h1><span className="brand-lg d-inline-block">Flair Designer Portal</span></h1>
                <h4>Your application is under review!</h4>
                <p>
                    Your application is under review. We will be reviewing all the details as soon as possible to give you access to Flair.
                </p>
                <p >We will be in contact with you over email.</p>
            </div>
        );
    }
}