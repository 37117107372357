import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import './SimpleQA.css';

export function SimpleQA(props) {
    const [isToggled, setToggled] = useState(props.initalState ? props.initalState : false);
    const toggleTrueFalse = (e) => { e.preventDefault(); setToggled(!isToggled); return false; }
    return (
        <section className="p-0 pb-2">
            <a href="#" onClick={toggleTrueFalse} className={`py-2 d-block q ${isToggled ? 'active' : ''}`}>Q: {props.question}</a>
            <Collapse isOpen={isToggled}>
                <div className="p-4 mb-3 a">
                    {props.children}
                </div>
            </Collapse>
        </section>
    );
}
