import { make } from '../../utilities/Utilities';
import Uploader from '../../utilities/uploader/Uploader';


export default class ImageItemsUI {

    static get draftBaseUrl() {
        return "https://ik.imagekit.io/flair/draft/tr:f-auto,q-80,w-1000,c-at_max/";
    }
    static get liveBaseUrl() {
        return "https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000,c-at_max/";
    }

    constructor({ api, config, itemData, imageUIIndex, setImageDraftData }) {

        this.api = api;
        this.setImageDraftData = setImageDraftData;
        this.imageUIIndex = imageUIIndex;

        this.uploader = new Uploader({
            config: config,
            onUpload: (response) => this.onUpload(response),
            onError: (error) => this.uploadingFailed(error),
        });

        this.nodes = {
            slideItemContainer: make('div', this.CSS.slideItemContainer),

            imageContainer: make('div', this.CSS.imageContainer),
            fileButton: this.createFileButton(),
            imageEl: undefined,
            imagePreloaderContainer: make('div', this.CSS.imagePreloaderContainer),
            imagePreloader: make('div', this.CSS.imagePreloader),
        };

        this.nodes.imagePreloaderContainer.appendChild(this.nodes.imagePreloader);

        this.nodes.imageContainer.appendChild(this.nodes.imagePreloaderContainer);
        this.nodes.imageContainer.appendChild(this.nodes.fileButton);

        this.nodes.slideItemContainer.appendChild(this.nodes.imageContainer);

        if (!itemData.file || Object.keys(itemData.file).length === 0) {
            this.toggleStatus(ImageItemsUI.status.EMPTY);
        } else {
            this.toggleStatus(ImageItemsUI.status.UPLOADING);
        }


        if (itemData.filedraft && itemData.filedraft.url) {
            this.fillImage(ImageItemsUI.draftBaseUrl + itemData.filedraft.url);
        } else if (itemData.file && itemData.file.url) {
            this.fillImage(ImageItemsUI.liveBaseUrl + itemData.file.url);
        } else {
            this.fillImage('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" style="background-color:%23333" width="7" height="7" viewBox="0 0 100 100"%3e%3c/svg%3e');
        }
    }

    /**
     * CSS classes
     *
     * @returns {object}
     */
    get CSS() {
        return {
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            button: this.api.styles.button,

            slideItemContainer: ['pl-4', 'pr-1'],
            imageContainer: ['imagescarousel-tool__image', 'position-relative'],
            fileButton: ['imagescarousel-tool__image-filebtn', 'd-flex', 'align-items-center'],
            imagePreloaderContainer: ['imagescarousel-tool__image-preloader', 'd-flex', 'align-items-center'],
            imagePreloader: 'imagescarousel-tool__image-preloader-inner',
            imageEl: ['imagescarousel-tool__image-picture', 'w-100', 'd-block']
        };
    };

    /**
     * Ui statuses:
     * - empty
     * - uploading
     * - filled
     *
     * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
     */
    static get status() {
        return {
            EMPTY: 'empty',
            UPLOADING: 'loading',
            FILLED: 'filled',
        };
    }

    render() {
        return this.nodes.slideItemContainer;
    }

    createFileButton() {
        //const button = make('div', [ this.CSS.button ]);
        const button = make('div', this.CSS.fileButton);

        //button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select a Banner d')}`;
        button.innerHTML = '<span class="cdx-button">Upload Image</span>';

        button.addEventListener('click', () => {
            this.uploader.uploadSelectedFile({
                onPreview: (src) => {
                    this.showPreloader(src);
                },
            });
        });

        return button;
    }

    /**
     * Shows uploading preloader
     *
     * @param {string} src - preview source
     * @returns {void}
     */
    showPreloader(src) {
        this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

        this.toggleStatus(ImageItemsUI.status.UPLOADING);
    }

    /**
     * Hide uploading preloader
     *
     * @returns {void}
     */
    hidePreloader() {
        this.nodes.imagePreloader.style.backgroundImage = '';
        this.toggleStatus(ImageItemsUI.status.EMPTY);
    }

    /**
     * Shows a banner
     *
     * @param {string} url - image source
     * @returns {void}
     */
    fillImage(url) {
        const tag = 'IMG';

        const attributes = {
            src: url,
        };

        let eventName = 'load';

        if (this.nodes.imageEl) {
            this.nodes.imageContainer.removeChild(this.nodes.imageEl);
        }

        this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

        /**
         * Add load event listener
         */
        this.nodes.imageEl.addEventListener(eventName, () => {
            this.toggleStatus(ImageItemsUI.status.FILLED);

            /**
             * Preloader does not exists on first rendering with presaved data
             */
            if (this.nodes.imagePreloader) {
                this.nodes.imagePreloader.style.backgroundImage = '';
            }
        });

        this.nodes.imageContainer.appendChild(this.nodes.imageEl);
    }


    /**
     * Changes UI status
     *
     * @param {string} status - see {@link BannerBlockUI.status} constants
     * @returns {void}
     */
    toggleStatus(status) {
        for (const statusType in ImageItemsUI.status) {
            if (Object.prototype.hasOwnProperty.call(ImageItemsUI.status, statusType)) {
                this.nodes.imageContainer.classList.toggle(`${this.CSS.imageContainer[0]}--${ImageItemsUI.status[statusType]}`, status === ImageItemsUI.status[statusType]);
            }
        }
    }

    isInloadingState() {
        //check if css in status

        return this.nodes.imageContainer.classList.contains(`${this.CSS.imageContainer[0]}--${ImageItemsUI.status.UPLOADING}`);
    }


    /**
     * Set new banner file
     *
     * @private
     *
     * @param {object} file - uploaded file data
     
    set banner(file) {
      this._data.file = file || {};
  
      if (file && file.url) {
        this.ui.fillBanner(file.url);
      }
    }*/
    set imageDraft(file) {
        if (file && file.url) {
            this.setImageDraftData(this.imageUIIndex, file)
            this.fillImage(ImageItemsUI.draftBaseUrl + file.url);
        }
    }



    /**
     * File uploading callback
     *
     * @private
     *
     * @param {UploadResponseFormat} response - uploading server response
     * @returns {void}
     */
    onUpload(response) {
        if (response.success && response.file) {
            this.imageDraft = response.file;
        } else {
            this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
        }
    }

    /**
     * Handle uploader errors
     *
     * @private
     * @param {string} errorText - uploading error text
     * @returns {void}
     */
    uploadingFailed(errorText) {
        console.log('Images Carousel Tool: uploading failed because of', errorText);

        this.api.notifier.show({
            message: this.api.i18n.t('Couldn’t upload the image. Please try another.'),
            style: 'error',
        });
        this.ui.hidePreloader();
    }

}