import React, { Component, Fragment } from 'react';
import { SidePanel } from '../../common/SidePanel';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { isNumber } from '../../utils/Validation';

export class ProductSizeVariantAddEdit extends Component {
    static displayName = ProductSizeVariantAddEdit.name;

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            error: null,
            complete: false,
            index: props.data?.index,
            id: props.data?.id,
            sizeCode: props.data?.sizeCode || '',
            sizeDisplayTitle: props.data?.sizeDisplayTitle || '',
            quantityInStock: isNumber(props.data?.quantityInStock) ? props.data.quantityInStock : ''
        };
        console.log(props.data)

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        const { index, id, sizeCode, sizeDisplayTitle, quantityInStock } = this.state;
        this.props.update({
            index,
            id,
            sizeCode,
            sizeDisplayTitle,
            quantityInStock: isNaN(parseInt(quantityInStock)) ? null : parseInt(quantityInStock)
        })

        this.props.onExited();
    }

    render() {
        return (
            <SidePanel onExited={this.props.onExited}>
                <div>
                    <h1>{isNaN(this.state.index) ? 'New size variant' : 'Edit size variant'}</h1>
                    <p>Add a new size variant to your product.</p>
                    <Form onSubmit={this.handleSubmit}>

                        <FormGroup>
                            <Label for="SizeDisplayTitle">Variant Title</Label>
                            <Input type="text" name="sizeDisplayTitle" id="SizeDisplayTitle" placeholder="Example: Medium"
                                maxLength="30" required
                                onChange={this.handleInputChange}
                                value={this.state.sizeDisplayTitle} />
                            <FormText color="muted">
                                The title of variant that will be displayed in the dropdown for the customer to select when they add your product to their basket.
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="SizeCode">Code</Label>
                            <Input type="text" name="sizeCode" id="SizeCode" placeholder="Example: M, Medium or S001"
                                maxLength="20" required
                                onChange={this.handleInputChange}
                                value={this.state.sizeCode} />
                            <FormText color="muted">
                                The variant code that you use internally to identify the variant in your production line. It could be a shortcut of the title or internal code name.
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="QuantityInStock">Quantity In Stock</Label>
                            <Input type="number" name="quantityInStock" id="QuantityInStock"
                                placeholder=""
                                value={this.state.quantityInStock}
                                maxLength="4"
                                max="2000"
                                min="0"
                                onChange={this.handleInputChange}
                                style={{ maxWidth: '140px' }}
                                className="simpletxt"
                            />
                            <FormText color="muted">
                                Optional: quantity of stock you have of this variant. Leaving this field blank/empty means there is no limit, good option for Made-to-order or bespoke products. However, leaving it at 0, means you don't have any more stock of this variant.
                            </FormText>
                        </FormGroup>

                        <Button className="mt-3" color="dark" size="sm">{isNaN(this.state.index) ? 'Add size variant' : 'Edit size variant'}</Button>
                    </Form>

                </div>
            </SidePanel>
        );
    }
}
