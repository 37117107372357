import React, { Component, Fragment } from 'react';
import { SidePanel } from '../../common/SidePanel';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { isNumber } from '../../utils/Validation';

export class BrandUniversityAddEdit extends Component {
    static displayName = BrandUniversityAddEdit.name;

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            universityList: props.universityList,
            error: null,
            complete: false,
            index: props.data?.index,
            id: props.data?.id,
            universityId: isNumber(props.data?.universityId) ? props.data?.universityId : '',
            courseTitle: props.data?.courseTitle || '',
            graduationYear: isNumber(props.data?.graduationYear) ? props.data?.graduationYear : ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    handleInputChange(event) {
        const target = event.target;

        let value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'file':
                value = target.files[0];
                break;
            default:
                value = target.value;
                break;
        }
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(event) {
        event.preventDefault();

        console.log('submitting..');
        console.log(this.state);

        const { index, id, universityId, courseTitle, graduationYear } = this.state;
        this.props.update({
            index,
            id,
            universityId: isNumber(universityId) ? parseInt(universityId) : null,
            courseTitle,
            graduationYear: isNumber(graduationYear) ? parseInt(graduationYear) : null
        })

        this.props.onExited();
    }

    render() {
        let universityOptionItems = this.state.universityList.map((item) =>
            <option key={item.universityId} value={item.universityId}>{item.name}</option>
        );

        return (
            <SidePanel onExited={this.props.onExited}>
                <div>
                    <h1>{!isNumber(this.state.index) ? 'Add university' : 'Edit university'}</h1>
                    <p>Link a new university to your brand showcase.</p>
                    <Form onSubmit={this.handleSubmit}>

                        <FormGroup>
                            <Label for="University">University</Label>
                            <select
                                value={this.state.universityId}
                                onChange={this.handleInputChange}
                                className="form-control"
                                id="University"
                                name="universityId" required>
                                <option value="">Select university</option>
                                {universityOptionItems}
                            </select>
                            <FormText color="muted">
                                Select the university you graduated from.
                            </FormText>
                        </FormGroup>


                        <FormGroup>
                            <Label for="CourseTitle">Course Title</Label>
                            <Input type="text" name="courseTitle" id="CourseTitle" placeholder="Course Title"
                                maxLength="100" required
                                onChange={this.handleInputChange}
                                value={this.state.courseTitle} />
                            <FormText color="muted">
                                The title of the course you graduated from.
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="GraduationYear">Graduation Year</Label>
                            <Input type="number" name="graduationYear" id="GraduationYear"
                                placeholder="Example 2022"
                                value={this.state.graduationYear}
                                maxLength="4"
                                max="2030"
                                min="1950"
                                onChange={this.handleInputChange}
                                style={{ maxWidth: '140px' }}
                                className="simpletxt"
                            />
                            <FormText color="muted">
                                Optional: The year you graduated
                            </FormText>
                        </FormGroup>

                        <Button className="mt-3" color="dark" size="sm">{!isNumber(this.state.index) ? 'Add university' : 'Edit university'}</Button>
                    </Form>

                </div>
            </SidePanel>
        );
    }
}
