import React from 'react'
import { CSSTransition } from "react-transition-group";

export function TransitionComponents(props) {
  return (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames={props.className}
      unmountOnExit>
      {props.children}
    </CSSTransition>
  );
}
