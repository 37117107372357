import './Index.css';
import { make } from '../../utilities/Utilities';

export default class TextColumns {

    static get DEFAULT_DATA() {
        return {
            input_placeholder: 'Text here...',
            text: '<p><br></p>',
        }
    }

    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;

        this._CSS = {
            block: this.api.styles.block,
            wrapper: ['ce-textcolumns', 'my-5', 'px-0'],
            container: ['container', 'px-0'],
            textHolder: ['txt-columns', 'cdx-textinput']
        };

        this.nodes = {
            wrapper: null,
            container: null,
            textHolder: null,
        };

        if (!this.readOnly) {
            this.onKeyUp = this.onKeyUp.bind(this);
        }

        this._placeholder = this.api.i18n.t(config.placeholder ? config.placeholder : TextColumns.DEFAULT_DATA.input_placeholder);

        this._data = {};
        this.data = data;

        this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;
    }

    onKeyUp(e) {
        e = e || window.event

        if (e.key === 'Enter' || e.keyCode === 13) {
            document.execCommand('formatBlock', false, 'p');
            return false;
        }

        /*
        if (e.code !== 'Backspace' && e.code !== 'Delete') {
            return;
        }

        const { textContent } = this._element;

        if (textContent === '') {
            this._element.innerHTML = '';
        }*/
    }

    render() {
        const wrapper = make('div', [this._CSS.block, ...this._CSS.wrapper]);

        const container = make('div', this._CSS.container);

        const textHolder = make('div', this._CSS.textHolder);
        textHolder.dataset.placeholder = this.api.i18n.t(this._placeholder);
        textHolder.contentEditable = false;
        if (!this.readOnly) {
            textHolder.contentEditable = true;
            textHolder.addEventListener('keyup', this.onKeyUp);
        }
        textHolder.innerHTML = this.data.text || TextColumns.DEFAULT_DATA.text;


        container.appendChild(textHolder);
        wrapper.appendChild(container);

        this.nodes.wrapper = wrapper;
        this.nodes.container = container;
        this.nodes.textHolder = textHolder;

        return wrapper;
    }

    merge(data) {
        let newData = {
            text: this.data.text + data.text
        };

        this.data = newData;
    }

    validate(savedData) {
        if (savedData.text.trim() === '' && !this._preserveBlank) {
            return false;
        }

        return true;
    }

    normalizeData(data) {
        const newData = {};

        if (typeof data !== 'object') {
            data = {};
        }

        newData.text = data.text || TextColumns.DEFAULT_DATA.text;
        return newData;
    }

    static get sanitize() {
        return {
            text: {
                p: {},
                br: {}
            }
        };
    }

    save(toolsContent) {
        if (!this.nodes.textHolder) {
            return this.data;
        }

        return Object.assign(this.data, {
            text: this.nodes.textHolder.innerHTML
        });
    }

    onPaste(event) {
        return Object.assign(this.data, {
            text: event.detail.data.innerHTML
        });

    }

    static get conversionConfig() {
        return {
            export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
            import: 'text' // to covert other block's exported string to Paragraph, fill 'text' property of tool data
        };
    }


    static get isReadOnlySupported() {
        return true;
    }

    static get enableLineBreaks() {
        return true;
    }

    get data() {
        return this._data;
    }

    set data(data) {
        this._data = Object.assign({}, this.data, data);
        this._data = this.normalizeData(this._data);

        if (this.nodes.textHolder) {
            this.nodes.textHolder.innerHTML = this._data.text || TextColumns.DEFAULT_DATA.text;
        }
    }


    static get pasteConfig() {
        return {
            tags: ['P']
        };
    }


    /**
     * Icon and title for displaying at the Toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve"><style type="text/css">.st0{fill:none;} .st1{fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;}</style><rect x="-38.2" y="-38.2" class="st0" width="333.2" height="333.2"/><path class="st1" d="M102.3,24.2v208.2c0,5.8-4.7,10.4-10.4,10.4H45.1c-5.8,0-10.4-4.7-10.4-10.4V24.2c0-5.8,4.7-10.4,10.4-10.4 h46.9C97.7,13.8,102.3,18.5,102.3,24.2z"/><path class="st1" d="M222.1,24.2v208.2c0,5.8-4.7,10.4-10.4,10.4h-46.9c-5.8,0-10.4-4.7-10.4-10.4V24.2c0-5.8,4.7-10.4,10.4-10.4 h46.9C217.4,13.8,222.1,18.5,222.1,24.2z"/></svg>',
            title: 'Text columns'
        };
    }
}