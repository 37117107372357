import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

export class BrandAnalytics extends Component {
    static displayName = BrandAnalytics.name;

    constructor(props) {
        super(props);
        this.state = {
            //designerId: props.designerId,
            data: props.data, loading: false
        };
        //this.populateData = this.populateData.bind(this);
    }

    /*
    componentDidMount() {
        this.populateData();
    }*/

    render() {
        if (this.state.loading)
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border my-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);

        const { data } = this.state;

        const chartConfig = {
            data: {
                datasets: [
                    {
                        label: 'Total Views Per Month',
                        data: data.totalViewsPerMonthData,
                        //borderColor: 'rgb(255, 99, 132)',
                        //backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        borderColor: '#343a40',
                        backgroundColor: '#343a40',
                    }
                ]
            },
            options: {
                parsing: {
                    xAxisKey: 'monthLabel',
                    yAxisKey: 'totalViews'
                },
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        min: 0,
                    }
                }
            }
        }

        return (
            <Row className="mt-3 mb-5">
                <Col xs="12">
                    <Line options={chartConfig.options} data={chartConfig.data} />
                </Col>
            </Row>
        );
    }

    /*
    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('/api/dashboard/analytics/' + this.state.designerId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log("anatlyics");
        console.log(data);
        this.setState({
            data: data,
            loading: false
        });
    }
    */
}
