import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'

export class BrandSignupComplete extends Component {
    static displayName = BrandSignupComplete.name;

    render() {
        return (
            <div className="main-sign mw-600px p-5 text-center">
                <h1><span className="brand-lg d-inline-block">Flair Designer Portal</span></h1>
                <h4>Congratz!</h4>
                <p>
                    Your brand's information has been submitted. We will be reviewing all the details as soon as possible to give you access to Flair.
                </p>
                <p >We will be in contact with you over email.</p>
            </div>
        );
    }
}