import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import { ManagementLayout } from './layout/ManagementLayout';
import { FetchData } from './FetchData';
import { BrandDetails } from './brand/BrandDetails';
import { BrandStory } from './brand/BrandStory';
import { Dashboard } from './dashboard/Dashboard';
import { CollectionsList } from './collection/CollectionsList';
import { CollectionDetails } from './collection/CollectionDetails';
import { ProductsList } from './store/ProductsList';
import { ProductDetails } from './store/ProductDetails';
import { StoreSettingsDetails } from './store/StoreSettingsDetails';
import { OrdersList } from './store-orders/OrdersList';

import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import authService from '../api-authorization/AuthorizeService'
import { ConnectionHubHome } from './connectionhub/Home';

export class Manage extends Component {
    static displayName = Manage.name;

    static brandInitiationStatus = {
        underReview: 0,
        initiated: 1,
        rejected: 2
    };

    constructor(props) {
        super(props);
        this.state = { managementAccess: null, loading: true };
    }

    componentDidMount() {
        this.checkManagementAccess();
    }

    async checkManagementAccess() {
        const token = await authService.getAccessToken();
        const response = await fetch('/api/manage/checkuserstatus', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        console.log(data);

        this.setState({ managementAccess: data, loading: false });
    }

    render() {
        const { managementAccess, loading } = this.state;

        if (loading) {
            return (<div></div>);
            /*
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
            */
        }

        if (!managementAccess.emailConfirmed) {
            //redirect to let users know they need to confirm their email
            return <Redirect to={`/r/confirm-email`} />;
        }

        if (!managementAccess.brands || managementAccess.brands.length <= 0) {
            //redirect to sign up a new brand
            return <Redirect to={`/new-brand-signup`} />;
        }


        if (managementAccess.brands.every((brand) => {
            return brand.initiationStatus == Manage.brandInitiationStatus.underReview ||
                brand.initiationStatus == Manage.brandInitiationStatus.rejected;
        })) {
            //if all brands in the user is not approved yet or rejected, redirect to waiting for review or reject page

            if (managementAccess.brands.find(brand => brand.initiationStatus === Manage.brandInitiationStatus.underReview)) {
                return <Redirect to={`/r/review-in-process`} />;
            } else {
                return <Redirect to={`/r/application-rejected`} />;
            }
        }

        const { designerId } = this.props.match.params;
        if (!designerId || !managementAccess.brands.find((brand) => {
            return brand.id.toString() === designerId.toString() && brand.initiationStatus === Manage.brandInitiationStatus.initiated;
        })) {
            //if the current selected brand is not approved yet or not in the user's brands list
            const defaultBrand = managementAccess.brands.find(brand => brand.initiationStatus === Manage.brandInitiationStatus.initiated);

            if (defaultBrand) {
                return <Redirect to={`/manage/${defaultBrand.id}`} />;
            } else {
                return <Redirect to={`/r/review-in-process`} />;
            }
        }


        /*
        const { designerId } = this.props.match.params;
        if (!managementAccess.brands.find((element) => {
            return element.designerId === designerId;
        })) {
            //designerId in params not in user's brands list
            const defaultBrand = managementAccess.brands.find(element => element.approved);
            return <Redirect to={`/management/${defaultBrand.designerId}`} />;
        }*/

        return (
            <Route>
                <ManagementLayout designerId={designerId}>
                    <Switch>
                        <AuthorizeRoute path='/manage/:designerId/collections/:collectionId/details' component={CollectionDetails} />
                        <AuthorizeRoute path='/manage/:designerId/collections' component={CollectionsList} />

                        <AuthorizeRoute path='/manage/:designerId/store/settings' component={StoreSettingsDetails} />

                        <AuthorizeRoute path='/manage/:designerId/store/orders/:orderPackageId/details' component={ProductDetails} />
                        <AuthorizeRoute path='/manage/:designerId/store/orders' component={OrdersList} />

                        <AuthorizeRoute path='/manage/:designerId/store/products/:productId/details' component={ProductDetails} />
                        <AuthorizeRoute path='/manage/:designerId/store/products' component={ProductsList} />

                        <AuthorizeRoute path='/manage/:designerId/connection-hub' component={ConnectionHubHome} />

                        <AuthorizeRoute path='/manage/:designerId/fetch-data' component={FetchData} />
                        <AuthorizeRoute path='/manage/:designerId/brand' component={BrandDetails} />
                        <AuthorizeRoute path='/manage/:designerId' component={Dashboard} />
                    </Switch>
                </ManagementLayout>
            </Route>
        );
    }
}
