import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import { BrandDetailsForm } from './BrandDetailsForm';
import { DataDraftAlertMessage } from '../alert/DataDraftAlertMessage';

import BrandDetailsHelp from './BrandDetailsHelp';
import { BrandDetailsLogo } from './BrandDetailsLogo';
import { BrandDetailsPortrait } from './BrandDetailsPortrait';
import { BrandStory } from './BrandStory';
import { HelpProvider } from '../../common/HelpProvider';
import { BrandUniversities } from './BrandUniversities';

export class BrandDetails extends Component {
    static displayName = BrandDetails.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            data: null,
            loading: true,
            error: null
        };
        this.populateData = this.populateData.bind(this);
        //this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmitToReview = this.onSubmitToReview.bind(this);
        this.onRevertBackData = this.onRevertBackData.bind(this);
        this.setError = this.setError.bind(this);
        this.updateDraftInfo = this.updateDraftInfo.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.error !== prevState.error &&
            this.state.error && this.state.error.length > 0) {
            this.scrollPageToTop();
        }
    }

    setError(error) {
        this.setState({
            error: error
        });
    }

    scrollPageToTop() {
        console.log('scroll up');
        setTimeout(function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 500);
    }

    updateDraftInfo(dataDraftStatus, allowSubmitToReview) {
        this.setState({
            dataDraftStatus: dataDraftStatus,
            allowSubmitToReview: allowSubmitToReview,
        });
    }

    /*
    handleInputChange(field, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [field]: value
            }
        }));
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }*/

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
        }

        const { data, designerId } = this.state;

        const storyBlock = (
            <BrandStory storySetting={data.storySetting}
                designerId={designerId} />
        );

        return (
            <HelpProvider>
                <Row className="mt-3 mb-5">
                    <Col xs="8">
                        <h1><span className="dep-title">BRAND</span><span>{data.brandTitle}</span></h1>
                        <p>Edit the details of your brand showcase page.</p>
                        <DataDraftAlertMessage
                            onSubmitToReview={this.onSubmitToReview}
                            onRevertBackData={this.onRevertBackData}
                            dataDraftStatus={data.dataDraftStatus}
                            allowSubmitToReview={data.allowSubmitToReview}
                        />
                        {
                            (!data.storySetting.hasApprovedStory && !data.storySetting.hasDraftStory) &&
                            <div className="mt-3 mb-5">
                                {storyBlock}
                            </div>
                        }
                        <div className="mt-3 mb-5">
                            {
                                (!data.storySetting.hasApprovedStory && !data.storySetting.hasDraftStory) &&
                                <h3>Details</h3>
                            }
                            <BrandDetailsForm
                                data={data}
                                designerId={designerId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                                //onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            (data.storySetting.hasApprovedStory || data.storySetting.hasDraftStory) &&
                            <div className="mt-3 mb-5">
                                {storyBlock}
                            </div>
                        }
                        <div className="mt-3 mb-5">
                            <div className="row">
                                <div className="col-6">
                                    <BrandDetailsLogo
                                        data={data}
                                        designerId={designerId}
                                        scrollPageToTop={this.scrollPageToTop}
                                        setError={this.setError}
                                        updateData={this.populateData}
                                    />
                                </div>
                                <div className="col-6">
                                    <BrandDetailsPortrait
                                        data={data}
                                        designerId={designerId}
                                        scrollPageToTop={this.scrollPageToTop}
                                        setError={this.setError}
                                        updateData={this.populateData}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 mb-5">
                            <BrandUniversities
                                data={data}
                                designerId={designerId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                    </Col>
                    <Col xs="4">
                        <BrandDetailsHelp />
                    </Col>
                </Row>
            </HelpProvider>
        );
    }

    async populateData(done) {
        const token = await authService.getAccessToken();
        const response = await fetch('/api/brand/details/' + this.state.designerId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({
            data: data,
            loading: false
        });
        if (done) done();
    }


    async onSubmitToReview(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/details/${this.state.designerId}/edit/submittoreview`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({
                data: newDta.brand,
                loading: false
            });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }

    async onRevertBackData(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/details/${this.state.designerId}/edit/revertback`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({
                data: newDta.brand,
                loading: false
            });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }
}
