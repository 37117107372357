import React, { Component } from 'react';
import { Button, Alert } from 'reactstrap';

export class DataRejectedAlertMessage extends Component {
    static displayName = DataRejectedAlertMessage.name;

    constructor(props) {
        super(props);
        this.onRevertBackData = this.onRevertBackData.bind(this);
    }

    onRevertBackData(event) {
        this.props.onRevertBackData(event);
    }

    render() {
        return (
            <Alert color="danger sticky-top">
                <h4 className="alert-heading">Data need to change!</h4>
                <p>
                    rejected
                    Aww yeah, you successfully read this important alert message. This example text is going
                    to run a bit longer so that you can see how spacing within an alert works with this kind
                    of content.
                </p>
                <hr />
                <p className="mb-0">
                    Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
                    <Button onClick={this.onRevertBackData}>Revert back data</Button>
                </p>
            </Alert>
        );
    }
}
