import React, { Component } from 'react';
import { Button, Alert } from 'reactstrap';

export class DataSavedAlertMessage extends Component {
    static displayName = DataSavedAlertMessage.name;

    constructor(props) {
        super(props);
        this.onSubmitToReview = this.onSubmitToReview.bind(this);
        this.onRevertBackData = this.onRevertBackData.bind(this);
    }

    onSubmitToReview(event) {
        this.props.onSubmitToReview(event);
    }

    onRevertBackData(event) {
        this.props.onRevertBackData(event);
    }

    render() {
        return (
            <Alert color="warning sticky-top">
                <h5 className="alert-heading">Data saved!</h5>
                <p>
                    Your changes are saved, but not yet submitted. Once you are ready for these changes to go live, please select 'submit for review'.
                </p>
                <hr />
                <div className="mb-0 d-flex align-items-center">
                    <Button size="sm" color="dark" className="mr-2" disabled={!this.props.allowSubmitToReview} onClick={this.onSubmitToReview}>Submit for review</Button>
                    <Button size="sm" color="danger" outline onClick={(event) => { if (window.confirm('Are you sure you wish to discard these new changes? This action will revert the page back to its previous live state.')) this.onRevertBackData(event) }}>Discard changes</Button>
                </div>
            </Alert>
        );
    }
}
