import { make } from '../../utilities/Utilities';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class BannerBlockUI {

    /**
     * @param {object} ui - Banner tool Ui module
     * @param {object} ui.api - Editor.js API
     * @param {BannerConfig} ui.config - user config
     * @param {Function} ui.onSelectFile - callback for clicks on Select file button
     * @param {boolean} ui.readOnly - read-only mode flag
     */
    constructor({ api, config, onSelectFile, readOnly }) {
        this.api = api;
        this.config = config;
        this.onSelectFile = onSelectFile;
        this.readOnly = readOnly;
        this.nodes = {
            wrapper: make('div', [this.CSS.baseClass, ...this.CSS.wrapper]),
            blockContainer: make('div', this.CSS.blockContainer),
            bannerContainer: make('div', [this.CSS.bannerContainer]),
            fileButton: this.createFileButton(),
            bannerEl: undefined,
            bannerPreloaderContainer: make('div', this.CSS.bannerPreloaderContainer),
            bannerPreloader: make('div', this.CSS.bannerPreloader),
            captionContainer: make('div', this.CSS.captionContainer),
            caption: make('small', [this.CSS.input, this.CSS.caption], {
                contentEditable: !this.readOnly,
            }),
        };

        /**
         * Create base structure
         *  <wrapper>
         *    <image-container>
         *      <image-preloader />
         *      <image-filebutton />
         *    </image-container>
         *    <caption />
         *    <select-file-button />
         *  </wrapper>
         */
        this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
        this.nodes.captionContainer.appendChild(this.nodes.caption);

        this.nodes.bannerPreloaderContainer.appendChild(this.nodes.bannerPreloader);

        this.nodes.bannerContainer.appendChild(this.nodes.bannerPreloaderContainer);
        this.nodes.bannerContainer.appendChild(this.nodes.fileButton);

        this.nodes.blockContainer.appendChild(this.nodes.bannerContainer);
        this.nodes.blockContainer.appendChild(this.nodes.captionContainer);

        this.nodes.wrapper.appendChild(this.nodes.blockContainer);
        //this.nodes.wrapper.appendChild(this.nodes.fileButton);
    }

    /**
     * CSS classes
     *
     * @returns {object}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            button: this.api.styles.button,

            /**
             * Tool's classes
             */
            wrapper: ['banner-tool', 'my-5'],
            blockContainer: ['px-0'],
            bannerContainer: 'banner-tool__image',
            fileButton: ['banner-tool__image-filebtn', 'd-flex', 'align-items-center'],
            bannerPreloaderContainer: ['banner-tool__image-preloader', 'd-flex', 'align-items-center'],
            bannerPreloader: 'banner-tool__image-preloader-inner',
            bannerEl: ['banner-tool__image-picture', 'w-100', 'd-block'],
            captionContainer: ['banner-tool__caption', 'my-1', 'mx-4'],
            caption: 'text-muted',
        };
    };

    /**
     * Ui statuses:
     * - empty
     * - uploading
     * - filled
     *
     * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
     */
    static get status() {
        return {
            EMPTY: 'empty',
            UPLOADING: 'loading',
            FILLED: 'filled',
        };
    }

    /**
     * Renders tool UI
     *
     * @param {BannerToolData} toolData - saved tool data
     * @returns {Element}
     */
    render(toolData) {
        if (!toolData.file || Object.keys(toolData.file).length === 0) {
            this.toggleStatus(BannerBlockUI.status.EMPTY);
        } else {
            this.toggleStatus(BannerBlockUI.status.UPLOADING);
        }

        return this.nodes.wrapper;
    }

    /**
     * Creates upload-file button
     *
     * @returns {Element}
     */
    createFileButton() {
        //const button = make('div', [ this.CSS.button ]);
        const button = make('div', this.CSS.fileButton);

        //button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select a Banner d')}`;
        button.innerHTML = '<span class="cdx-button">Upload Image</span>';

        button.addEventListener('click', () => {
            this.onSelectFile();
        });

        return button;
    }

    /**
     * Shows uploading preloader
     *
     * @param {string} src - preview source
     * @returns {void}
     */
    showPreloader(src) {
        this.nodes.bannerPreloader.style.backgroundImage = `url(${src})`;

        this.toggleStatus(BannerBlockUI.status.UPLOADING);
    }

    /**
     * Hide uploading preloader
     *
     * @returns {void}
     */
    hidePreloader() {
        this.nodes.bannerPreloader.style.backgroundImage = '';
        this.toggleStatus(BannerBlockUI.status.EMPTY);
    }

    /**
     * Shows a banner
     *
     * @param {string} url - image source
     * @returns {void}
     */
    fillBanner(url) {
        /**
         * Check for a source extension to compose element correctly: video tag for mp4, img — for others
         */
        const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

        const attributes = {
            src: url,
        };

        /**
         * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
         * - IMG: load
         * - VIDEO: loadeddata
         *
         * @type {string}
         */
        let eventName = 'load';

        /**
         * Update attributes and eventName if source is a mp4 video
         */
        if (tag === 'VIDEO') {
            /**
             * Add attributes for playing muted mp4 as a gif
             *
             * @type {boolean}
             */
            attributes.autoplay = true;
            attributes.loop = true;
            attributes.muted = true;
            attributes.playsinline = true;

            /**
             * Change event to be listened
             *
             * @type {string}
             */
            eventName = 'loadeddata';
        }

        /**
         * Compose tag with defined attributes
         *
         * @type {Element}
         */

        if (this.nodes.bannerEl) {
            this.nodes.bannerContainer.removeChild(this.nodes.bannerEl);
        }

        this.nodes.bannerEl = make(tag, this.CSS.bannerEl, attributes);

        /**
         * Add load event listener
         */
        this.nodes.bannerEl.addEventListener(eventName, () => {
            this.toggleStatus(BannerBlockUI.status.FILLED);

            /**
             * Preloader does not exists on first rendering with presaved data
             */
            if (this.nodes.bannerPreloader) {
                this.nodes.bannerPreloader.style.backgroundImage = '';
            }
        });

        this.nodes.bannerContainer.appendChild(this.nodes.bannerEl);
    }

    /**
     * Shows caption input
     *
     * @param {string} text - caption text
     * @returns {void}
     */
    fillCaption(text) {
        if (this.nodes.caption) {
            this.nodes.caption.innerHTML = text;
        }
    }

    /**
     * Changes UI status
     *
     * @param {string} status - see {@link BannerBlockUI.status} constants
     * @returns {void}
     */
    toggleStatus(status) {
        for (const statusType in BannerBlockUI.status) {
            if (Object.prototype.hasOwnProperty.call(BannerBlockUI.status, statusType)) {
                this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${BannerBlockUI.status[statusType]}`, status === BannerBlockUI.status[statusType]);
            }
        }
    }

    /**
     * Apply visual representation of activated tune
     *
     * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
     * @param {boolean} status - true for enable, false for disable
     * @returns {void}
     */
    applyTune(tuneName, status) {
        if (tuneName === 'stretched') {
            this.nodes.blockContainer.classList.toggle('container', !!!status);
            this.nodes.blockContainer.classList.toggle('container-fluid', !!status);
        }

        this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${tuneName}`, status);
    }
}
