//https://stackoverflow.com/questions/40029867/trying-to-implement-a-simple-promise-in-reactjs
//https://www.cluemediator.com/validate-image-content-in-reactjs

export const validateImage = (imageFile, minWidth, minHeight) => {
    console.log('checking: ' + imageFile.name)
    return new Promise((resolve, reject) => {
        if (!imageFile) {
            resolve({
                result: false,
                error: 'Please select image.'
            });
            return false;
        }

        if (!imageFile.name.toLowerCase().match(/\.(jpg|jpeg|png|heic)$/)) {
            resolve({
                result: false,
                error: 'Please select valid image.'
            });
            return false;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                if (img.width < minWidth || img.height < minHeight) {
                    resolve({
                        result: false,
                        error: `Image is too small. Make sure it's min ${minWidth}x${minHeight}px.`
                    });
                    return false;
                }

                resolve({
                    result: true
                });
                return true;
            };
            img.onerror = () => {
                resolve({
                    result: false,
                    error: `Please select valid image.`
                });
                return false;
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
    });
}


export const isNumber = (value) => {
    var x;
    if (isNaN(value)) {
        return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
}