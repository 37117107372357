import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import authService from '../../api-authorization/AuthorizeService';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import { CollectionDetailsForm } from './CollectionDetailsForm';
import { CollectionStory } from './CollectionStory';
import { DataDraftAlertMessage } from '../alert/DataDraftAlertMessage';

import CollectionDetailsHelp from './CollectionDetailsHelp';
import { CollectionDetailsThumbnail } from './CollectionDetailsThumbnail';
import { HelpProvider } from '../../common/HelpProvider';

export class CollectionDetails extends Component {
    static displayName = CollectionDetails.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.match.params.designerId,
            collectionId: props.match.params.collectionId,
            data: null,
            loading: true,
            error: null
        };
        this.populateData = this.populateData.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        //this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmitToReview = this.onSubmitToReview.bind(this);
        this.onRevertBackData = this.onRevertBackData.bind(this);
        this.setError = this.setError.bind(this);
        this.updateDraftInfo = this.updateDraftInfo.bind(this);
        //this.uploadImages = this.uploadImages.bind(this);
        //this.uploadProgress = this.uploadProgress.bind(this);
        //this.renderForm = this.renderForm.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.error !== prevState.error &&
            this.state.error && this.state.error.length > 0) {
            this.scrollPageToTop();
        }
    }

    setError(error) {
        this.setState({
            error: error
        })
    }

    scrollPageToTop() {
        console.log('scroll up');
        setTimeout(function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 500);
    }

    updateDraftInfo(dataDraftStatus, allowSubmitToReview) {
        this.setState({
            dataDraftStatus: dataDraftStatus,
            allowSubmitToReview: allowSubmitToReview,
        });
    }

    /*
    handleInputChange(field, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [field]: value
            }
        }));
    }

    handleSingleLineTextArea = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }
    */


    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
        }

        const { data, designerId, collectionId } = this.state;

        if (!data) {
            return <Redirect to={`/manage/${this.state.designerId}/collections`} />
        }

        const storyBlock = (
            <CollectionStory storySetting={data.storySetting}
                designerId={designerId}
                collectionId={collectionId} />
        );

        return (
            <HelpProvider>
                <Row className="mt-3 mb-5">
                    <Col xs="8">
                        <h1><span className="dep-title">COLLECTION</span><span>{data.title}</span></h1>
                        <p>Edit the details of your collection page.</p>
                        <DataDraftAlertMessage
                            onSubmitToReview={this.onSubmitToReview}
                            onRevertBackData={this.onRevertBackData}
                            dataDraftStatus={data.dataDraftStatus}
                            allowSubmitToReview={data.allowSubmitToReview}
                        />
                        {
                            (!data.storySetting.hasApprovedStory && !data.storySetting.hasDraftStory) &&
                            <div className="mt-3 mb-5">
                                {storyBlock}
                            </div>
                        }
                        <div className="mt-3 mb-5">
                            {
                                (!data.storySetting.hasApprovedStory && !data.storySetting.hasDraftStory) &&
                                <h3>Details</h3>
                            }
                            <CollectionDetailsForm
                                data={data}
                                designerId={designerId}
                                collectionId={collectionId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                                //onChange={this.handleInputChange}
                            />
                        </div>
                        <div className="mt-3 mb-5">
                            <CollectionDetailsThumbnail
                                data={data}
                                designerId={designerId}
                                collectionId={collectionId}
                                scrollPageToTop={this.scrollPageToTop}
                                setError={this.setError}
                                updateData={this.populateData}
                            />
                        </div>
                        {
                            (data.storySetting.hasApprovedStory || data.storySetting.hasDraftStory) &&
                            <div className="mt-3 mb-5">
                                {storyBlock}
                            </div>
                        }
                        <div className="mt-3 mb-5">
                            <div className="p-4 text-light bg-dark rounded box-shadow">
                                <h5>Collection settings</h5>
                                <p></p>
                                <div className="mt-3">
                                    {/*<Button color="danger" size="sm" disabled={true} className="mr-2">Delete collection</Button>*/}
                                    <Button color="danger" size="sm" disabled={true}>Hide collection</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="4">
                        <CollectionDetailsHelp />
                    </Col>
                </Row>
            </HelpProvider>
        );
    }

    async populateData(done) {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/collections/${this.state.collectionId}/details`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({ data: data, loading: false });
        if (done) done();
    }

    async onSubmitToReview(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/${this.state.designerId}/collections/${this.state.collectionId}/edit/submittoreview`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({ data: newDta.collection, loading: false });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }

    async onRevertBackData(event) {
        event.preventDefault();

        const token = await authService.getAccessToken();
        if (!token) {
            this.setError(`Could not retrieve authentication`);
            return;
        }

        const response = await fetch(`/api/brand/${this.state.designerId}/collections/${this.state.collectionId}/edit/revertback`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${token}`
            },
        });

        const newDta = await response.json();
        console.log(newDta);

        if (newDta && newDta.result) {
            this.setState({ data: newDta.collection, loading: false });
            this.scrollPageToTop();
        } else {
            this.setError(`Error`);
        }
    }
}
