import { make } from '../../utilities/Utilities';

/**
 * Working with Block Tunes
 */
export default class VideoBlockTunes {
    /**
     * @param {object} tune - image tool Tunes managers
     * @param {object} tune.api - Editor API
     * @param {object} tune.actions - list of user defined tunes
     * @param {Function} tune.onChange - tune toggling callback
     */
    constructor({ api, actions, onChange }) {
        this.api = api;
        this.actions = actions;
        this.onChange = onChange;
        //this.buttons = [];
    }

    /**
     * Available Video tunes
     *
     * @returns {{name: string, icon: string, title: string}[]}
     */
    static get tunes() {
        return [
            {
                name: 'uploadVideo',
                icon: '<svg fill="none" style="fill:none !important;" height="20" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M22,15.3v4.4c0,1.2-1,2.2-2.2,2.2H4.2C3,22,2,21,2,19.8v-4.4"/><polyline points="17.6,7.6 12,2 6.4,7.6 "/><line x1="12" y1="2" x2="12" y2="15.3"/></svg>',
                title: 'Upload video',
            },
            {
                name: 'captureThumbnail',
                icon: '<svg height="24" width="24" style="enable-background:new 0 0 30 30;" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g><path d="M6,19V17c0-0.552-0.448-1-1-1H5c-0.552,0-1,0.448-1,1V19c0,0.552,0.448,1,1,1H5C5.552,20,6,19.552,6,19z"/><path d="M10,5L10,5c0,0.553,0.448,1,1,1H13c0.552,0,1-0.448,1-1V5c0-0.552-0.448-1-1-1H11C10.448,4,10,4.448,10,5z"/><path d="M5,14L5,14c0.553,0,1-0.448,1-1V11c0-0.552-0.448-1-1-1H5c-0.552,0-1,0.448-1,1V13C4,13.552,4.448,14,5,14z"/><path d="M23,6h1l0,1c0,0.552,0.448,1,1,1h0c0.552,0,1-0.448,1-1V6c0-1.105-0.895-2-2-2h-1c-0.552,0-1,0.448-1,1v0   C22,5.552,22.448,6,23,6z"/><path d="M16,5L16,5c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v0c0-0.552-0.448-1-1-1h-2C16.448,4,16,4.448,16,5z"/><path d="M7,24H6v-1c0-0.552-0.448-1-1-1H5c-0.552,0-1,0.448-1,1v1c0,1.105,0.895,2,2,2h1c0.552,0,1-0.448,1-1V25   C8,24.448,7.552,24,7,24z"/><path d="M6,7V6h1c0.552,0,1-0.448,1-1V5c0-0.552-0.448-1-1-1H6C4.895,4,4,4.895,4,6v1c0,0.552,0.448,1,1,1H5C5.552,8,6,7.552,6,7z"/><path d="M24,11l0,2.001c0,0.552,0.448,1,1,1h0c0.552,0,1-0.448,1-1V11c0-0.552-0.448-1-1-1h0C24.448,10,24,10.448,24,11z"/></g><g><path d="M25,16h-1.764c-0.758,0-1.45-0.428-1.789-1.106l-0.171-0.342C21.107,14.214,20.761,14,20.382,14h-4.764   c-0.379,0-0.725,0.214-0.894,0.553l-0.171,0.342C14.214,15.572,13.521,16,12.764,16H11c-0.552,0-1,0.448-1,1v8c0,0.552,0.448,1,1,1   h14c0.552,0,1-0.448,1-1v-8C26,16.448,25.552,16,25,16z M18,25c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4   C22,23.209,20.209,25,18,25z"/><circle cx="18" cy="21" r="2"/></g></svg>',
                title: 'Capture thumbnail from video timeline',
            },
            {
                name: 'showControls',
                toggle: true,
                icon: '<svg aria-labelledby="controlsAltIconTitle" fill="currentColor" height="24px" stroke="currentColor" stroke-linecap="square" stroke-linejoin="miter" stroke-width="1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"><title id="controlsAltIconTitle"/><circle cx="9" cy="6" r="2"/><path d="M4 6H7"/><path d="M11 6H20"/><circle cx="9" cy="18" r="2"/><path d="M4 18H7"/><path d="M11 18H20"/><circle cx="15" cy="12" r="2"/><path d="M4 12H13"/><path d="M17 12L20 12"/></svg>',
                title: 'Show controls',
            },
            {
                name: 'loop',
                toggle: true,
                icon: '<svg height="20" width="20" viewBox="0 0 30 17.31419" xmlns="http://www.w3.org/2000/svg"><g><g><path d="M22.40381,2.81419c-3.35352,0-6.13672,2.18066-8.50879,6.666-1.89844,3.58887-3.9585,5.334-6.29883,5.334A4.93784,4.93784,0,0,1,2.5,10.06419a4.93784,4.93784,0,0,1,5.09619-4.75,6.19983,6.19983,0,0,1,1.0202.09619L7.5752,5.66282a1.25,1.25,0,0,0,.29345,2.46484,1.22727,1.22727,0,0,0,.29541-.03515l4.87207-1.18164a1.25062,1.25062,0,0,0,.79981-1.81934L11.38184.64524a1.25011,1.25011,0,1,0-2.18848,1.209L9.902,3.13792a9.009,9.009,0,0,0-2.30585-.32373A7.43737,7.43737,0,0,0,0,10.06419a7.43738,7.43738,0,0,0,7.59619,7.25c3.35352,0,6.13672-2.18067,8.50879-6.666,1.89844-3.58886,3.9585-5.334,6.29883-5.334a4.93784,4.93784,0,0,1,5.09619,4.75,4.93784,4.93784,0,0,1-5.09619,4.75,6.887,6.887,0,0,1-2.68018-.58106A1.24985,1.24985,0,0,0,18.7373,16.53a9.40358,9.40358,0,0,0,3.66651.78418A7.43738,7.43738,0,0,0,30,10.06419,7.43737,7.43737,0,0,0,22.40381,2.81419Z"/></g></g></svg>',
                title: 'Loop video',
            },
            {
                name: 'style',
                toggle: 'style',
                value: 'half_left',
                icon: '<svg x="0px" y="0px" height="18" width="18" viewBox="0 0 1792 1792" fill="currentColor"><path d="M0,1432.3v-1073h905.9v1073H0z M1988,448.8c35.7,0,66.2,17.5,91.5,52.4c25.3,34.9,37.9,77.1,37.9,126.4v536.5 c0,49.4-12.6,91.5-37.9,126.4c-25.3,34.9-55.8,52.4-91.5,52.4v223.5c0,61.5-15.8,114.1-47.5,157.9c-31.7,43.8-69.8,65.7-114.2,65.7 H-50.2c-44.5,0-82.6-21.9-114.2-65.7c-31.7-43.8-47.5-96.4-47.5-157.9V225.3c0-61.5,15.8-114.1,47.5-157.9S-94.7,1.7-50.2,1.7 h1876.4c44.5,0,82.6,21.9,114.2,65.7c31.7,43.8,47.5,96.4,47.5,157.9V448.8z M1988,1164.1V627.6h-129.4V225.3c0-13-3-23.8-9.1-32.1 c-6.1-8.4-13.8-12.6-23.3-12.6H-50.2c-9.4,0-17.2,4.2-23.3,12.6c-6.1,8.4-9.1,19.1-9.1,32.1v1341.2c0,13,3,23.8,9.1,32.1 c6.1,8.4,13.8,12.6,23.3,12.6h1876.4c9.4,0,17.2-4.2,23.3-12.6c6.1-8.4,9.1-19.1,9.1-32.1v-402.4H1988z"/></svg>',
                title: 'Half size player to left',
            },
            {
                name: 'style',
                toggle: 'style',
                value: 'half',
                icon: '<svg x="0px" y="0px" height="18" width="18" viewBox="0 0 1792 1792" fill="currentColor"><path d="M444.9,1432.3v-1073h905.9v1073H444.9z M1988,448.8c35.7,0,66.2,17.5,91.5,52.4c25.3,34.9,37.9,77.1,37.9,126.4v536.5 c0,49.4-12.6,91.5-37.9,126.4c-25.3,34.9-55.8,52.4-91.5,52.4v223.5c0,61.5-15.8,114.1-47.5,157.9c-31.7,43.8-69.8,65.7-114.2,65.7 H-50.2c-44.5,0-82.6-21.9-114.2-65.7c-31.7-43.8-47.5-96.4-47.5-157.9V225.3c0-61.5,15.8-114.1,47.5-157.9S-94.7,1.7-50.2,1.7 h1876.4c44.5,0,82.6,21.9,114.2,65.7c31.7,43.8,47.5,96.4,47.5,157.9V448.8z M1988,1164.1V627.6h-129.4V225.3c0-13-3-23.8-9.1-32.1 c-6.1-8.4-13.8-12.6-23.3-12.6H-50.2c-9.4,0-17.2,4.2-23.3,12.6c-6.1,8.4-9.1,19.1-9.1,32.1v1341.2c0,13,3,23.8,9.1,32.1 c6.1,8.4,13.8,12.6,23.3,12.6h1876.4c9.4,0,17.2-4.2,23.3-12.6c6.1-8.4,9.1-19.1,9.1-32.1v-402.4H1988z"/></svg>',
                title: 'Half size video player',
            },
            {
                name: 'style',
                toggle: 'style',
                value: 'half_right',
                icon: '<svg x="0px" y="0px" height="18" width="18" viewBox="0 0 1792 1792" fill="currentColor"><path d="M888.3,1432.3v-1073h905.9v1073H888.3z M1988,448.8c35.7,0,66.2,17.5,91.5,52.4c25.3,34.9,37.9,77.1,37.9,126.4v536.5 c0,49.4-12.6,91.5-37.9,126.4c-25.3,34.9-55.8,52.4-91.5,52.4v223.5c0,61.5-15.8,114.1-47.5,157.9c-31.7,43.8-69.8,65.7-114.2,65.7 H-50.2c-44.5,0-82.6-21.9-114.2-65.7c-31.7-43.8-47.5-96.4-47.5-157.9V225.3c0-61.5,15.8-114.1,47.5-157.9S-94.7,1.7-50.2,1.7 h1876.4c44.5,0,82.6,21.9,114.2,65.7c31.7,43.8,47.5,96.4,47.5,157.9V448.8z M1988,1164.1V627.6h-129.4V225.3c0-13-3-23.8-9.1-32.1 c-6.1-8.4-13.8-12.6-23.3-12.6H-50.2c-9.4,0-17.2,4.2-23.3,12.6c-6.1,8.4-9.1,19.1-9.1,32.1v1341.2c0,13,3,23.8,9.1,32.1 c6.1,8.4,13.8,12.6,23.3,12.6h1876.4c9.4,0,17.2-4.2,23.3-12.6c6.1-8.4,9.1-19.1,9.1-32.1v-402.4H1988z"/></svg>',
                title: 'Half size player to right',
            },
            {
                name: 'style',
                toggle: 'style',
                value: 'full',
                icon: '<svg x="0px" y="0px" height="18" width="18" viewBox="0 0 1792 1792" fill="currentColor"><path d="M0,1432.3v-1073h1792v1073H0z M1988,448.8c35.7,0,66.2,17.5,91.5,52.4c25.3,34.9,37.9,77.1,37.9,126.4v536.5 c0,49.4-12.6,91.5-37.9,126.4c-25.3,34.9-55.8,52.4-91.5,52.4v223.5c0,61.5-15.8,114.1-47.5,157.9c-31.7,43.8-69.8,65.7-114.2,65.7 H-50.2c-44.5,0-82.6-21.9-114.2-65.7c-31.7-43.8-47.5-96.4-47.5-157.9V225.3c0-61.5,15.8-114.1,47.5-157.9S-94.7,1.7-50.2,1.7 h1876.4c44.5,0,82.6,21.9,114.2,65.7c31.7,43.8,47.5,96.4,47.5,157.9V448.8z M1988,1164.1V627.6h-129.4V225.3c0-13-3-23.8-9.1-32.1 c-6.1-8.4-13.8-12.6-23.3-12.6H-50.2c-9.4,0-17.2,4.2-23.3,12.6c-6.1,8.4-9.1,19.1-9.1,32.1v1341.2c0,13,3,23.8,9.1,32.1 c6.1,8.4,13.8,12.6,23.3,12.6h1876.4c9.4,0,17.2-4.2,23.3-12.6c6.1-8.4,9.1-19.1,9.1-32.1v-402.4H1988z"/></svg>',
                title: 'Full size video player',
            },
            {
                name: 'stretched',
                toggle: true,
                icon: '<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>',
                title: 'Stretched',
            },
            {
                name: 'showCaption',
                toggle: true,
                icon: '<?xml version="1.0" ?><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11,9 L9,9 L9,10 L7,10 L7,7 L17,7 L17,10 L15,10 L15,9 L13,9 L13,15 L14,15 L14,17 L10,17 L10,15 L11,15 L11,9 Z M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M4,4 L4,20 L20,20 L20,4 L4,4 Z" fill-rule="evenodd"/></svg>',
                title: 'Toggle caption',
            },
        ];
    }

    render(toolData) {
        const tunes = VideoBlockTunes.tunes.concat(this.actions);

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            toggle: tune.toggle,
            isActive: (tune.value && (toolData[tune.name] === tune.value)) || (tune.toggle === true && toolData[tune.name]),
            onActivate: () => {
                if (typeof tune.action === 'function') {
                    tune.action(tune.name, tune.value);
                    return;
                }
                this.onChange(tune.name, tune.value);
            },
        }));
    }

    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        const confirmed = VideoBlockTunes.tunes.find(item => item.name === tuneName && item.value === tuneValue);
        if (confirmed !== undefined) {
            return confirmed.value;
        }
        return VideoBlockTunes.tunes.filter(item => item.name === tuneName)[0].value;
    }



    /**
     * Styles
     *
     * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
     */
    /*
    get CSS() {
        return {
            wrapper: 'cdx-settings-container',
            buttonBase: this.api.styles.settingsButton,
            button: 'video-tool__tune',
            buttonActive: this.api.styles.settingsButtonActive,
            tunerSectionWrapper: 'cdx-settings-inner',
            tunerSectionTitle: ['small', 'text-muted', 'pt-1', 'pr-1', 'pl-1', 'pb-0', 'text-center'],
        };
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch Video
     *
     * @param {VideoToolData} toolData - generate Elements of tunes
     * @returns {Element}
     */
    /*
    render(toolData) {
        this.buttons = [];

        const wrapper = make('div', this.CSS.wrapper);

        wrapper.appendChild(make('div', this.CSS.tunerSectionTitle, {
            innerHTML: 'Video'
        }));    

        this.renderSection(
            VideoBlockTunes.tunes.videoTunes.concat(this.actions),
            toolData,
            wrapper);

        wrapper.appendChild(make('div', this.CSS.tunerSectionTitle, {
            innerHTML: 'Player control'
        }));

        this.renderSection(
            VideoBlockTunes.tunes.playerSettings.concat(this.actions),
            toolData,
            wrapper);

        wrapper.appendChild(make('div', this.CSS.tunerSectionTitle, {
            innerHTML: 'Player style'
        }));

        this.renderSection(
            VideoBlockTunes.tunes.styles.concat(this.actions),
            toolData,
            wrapper);

        this.renderSection(
            VideoBlockTunes.tunes.general.concat(this.actions),
            toolData,
            wrapper);

        return wrapper;
    }

    renderSection(tunes, toolData, wrapper) {
        const btnsContainer = wrapper.appendChild(make('div', this.CSS.tunerSectionWrapper));

        tunes.forEach(tune => {
            const title = this.api.i18n.t(tune.title);
            const el = make('div', [this.CSS.buttonBase, this.CSS.button], {
                innerHTML: tune.icon,
                title,
            });

            el.addEventListener('click', () => {
                this.tuneClicked(tune.name, tune.value, tune.action);
            });

            el.dataset.tuneName = tune.name;
            el.dataset.tuneValue = tune.value;

            el.classList.toggle(this.CSS.buttonActive,
                (tune.value === undefined && this.confirmTuneValueOrGetFirstValue(tune.name, toolData[tune.name]) === true) ||
                (tune.value === this.confirmTuneValueOrGetFirstValue(tune.name, toolData[tune.name]))
            );
            //el.classList.toggle(this.CSS.buttonActive, toolData[tune.name]);

            this.buttons.push(el);

            this.api.tooltip.onHover(el, title, {
                placement: 'top',
            });

            btnsContainer.appendChild(el);
        });

        wrapper.appendChild(btnsContainer);
        wrapper.appendChild(make('HR', 'cdx-settings-divider'));
    }

    /**
     * Clicks to one of the tunes
     *
     * @param {string} tuneName - clicked tune name
     * @param {Function} customFunction - function to execute on click
     */
    /*
    tuneClicked(tuneName, tuneValue, customFunction) {
        if (typeof customFunction === 'function') {
            if (!customFunction(tuneName)) {
                return false;
            }
        }

        if (tuneName === 'style') {
            this.buttons.forEach(button => {
                if (button.dataset.tuneName === tuneName) {
                    button.classList.toggle(this.CSS.buttonActive, button.dataset.tuneValue === tuneValue);
                }
            });
        } else if (tuneName === 'uploadVideo' || tuneName === 'captureThumbnail') {
            //don't highlight button
        } else {
            const button = this.buttons.find(el => el.dataset.tuneName === tuneName);
            button.classList.toggle(this.CSS.buttonActive, !button.classList.contains(this.CSS.buttonActive));
        }

        this.onChange(tuneName, tuneValue);
    }



    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        if (tuneName === 'style') {
            return this._getTuneValueOrFirstValue(
                VideoBlockTunes.tunes.styles,
                tuneValue
            );
        } else {
            if (tuneValue !== undefined)
                return tuneValue === true;
            return false;
        }
    }

    _getTuneValueOrFirstValue(tunes, value) {
        if (!Array.isArray(tunes) || tunes.length <= 0) {
            throw 'Tune array is invalid or empty';
        }

        for (let i = 0; i < tunes.length; i++) {
            if (value === tunes[i].value) {
                return tunes[i].value;
            }
        }

        return tunes[0].value;
    }*/
}