import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthorizeRoute from '../../api-authorization/AuthorizeRoute';
import authService from '../../api-authorization/AuthorizeService';
import { AddCollection } from './AddCollection';
import { TransitionRoutes } from '../../common/TransitionRoutes';

import { Image } from '../../common/UIElements';
import { CollectionMediaConfig } from '../../utils/MediaConfigs';

export class CollectionsList extends Component {
    static displayName = CollectionsList.name;

    constructor(props) {
        super(props);
        this.state = {
            collections: [], loading: true,
            designerId: props.match.params.designerId
        };
        this.populateData = this.populateData.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderDataTable(list, designerId) {
        return (
            <div>
                <h1><span className="dep-title">BRAND</span><span>Collections Vault</span></h1>
                <div style={{ maxWidth: '800px' }}>
                    <p>Keep your customers up to date with your current collections and projects while preserving previous seasons or installments.</p>
                </div>
                <Link to={`/manage/${designerId}/collections/new`} className="btn btn-dark btn-sm mb-4">New collection</Link>
                <div className="row mt-4">
                    {list.map((entry, i) =>
                        <div key={i} className="col-lg-3 col-md-6">
                            <div className="card mb-5 bg-white rounded box-shadow">
                                <div className="pb-3">
                                    <div className="mb-3 w-100 overflow-hidden position-relative text-center">
                                        <Link to={`/manage/${designerId}/collections/${entry.id}/details`}>
                                            <Image fileName={entry.thumbnail.fileName}
                                                isDraft={entry.thumbnail.isDraft}
                                                mediaConfig={CollectionMediaConfig.Thumbnail}
                                                className="d-block position-relative w-100 pt-1 px-1"
                                            />
                                        </Link>
                                    </div>
                                    <div className="d-flex no-block align-items-center">
                                        <div className="px-3">
                                            <div className="mb-0 text-weight-bold">{entry.title}</div>
                                            <div>
                                                {entry.isVisible &&
                                                    <span className="badge badge-pill badge-success mr-1">Live</span>}
                                                {!entry.isVisible &&
                                                    <span className="badge badge-pill badge-danger mr-1">Hidden</span>}
                                                {entry.dataDraftStatus === 0 &&
                                                    <span className="badge badge-pill badge-info mr-1">New save</span>}
                                                {entry.dataDraftStatus === 1 &&
                                                    <span className="badge badge-pill badge-primary mr-1">Submitted</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );

            {/*
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>CreatedBy</th>
                        <th>CollectionTitle</th>
                        <th>IsVisible</th>
                        <th>BrandDataStatus</th>
                        <th>DataDraftStatus</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((entry, i) =>
                        <tr key={i}>
                            <td>{entry.id}</td>
                            <td>{entry.createdBy}</td>
                            <td>{entry.title}</td>
                            <td>{entry.isVisible}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>
                                <Link to={`/manage/${designerId}/collections/${entry.id}/details`}>View</Link>
                            </td>
                        </tr>
                    )}
                </tbody>                
            </table>*/}
    }

    static renderEmptyMessage(designerId) {
        return (
            <div className="my-5 py-5 mx-auto text-center" style={{ maxWidth: '600px' }}>
                <h1 id="tabelLabel" className="my-4">Collection Vault</h1>
                <div>
                    <p>Welcome to the Collection Vault! Your brand is constantly developing and evolving. The collection archive gives you the opportunity to keep customers up to date with your current collections and projects while preserving the old ones.</p>
                    <p>Where your brand showcase page will remain relatively constant, the collection reflects the ever-changing life of your brand. This might include but is not limited to seasonal collections, non-seasonal product installments, or large projects.</p>
                </div>
                <Link to={`/manage/${designerId}/collections/new`} className="btn btn-dark btn-sm mb-4">New collection</Link>
            </div>
        );
    }

    render() {
        const contents = this.state.loading
            ? (<div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>)
            : this.state.collections && this.state.collections.length > 0 ?
                CollectionsList.renderDataTable(this.state.collections, this.state.designerId) :
                CollectionsList.renderEmptyMessage(this.state.designerId);

        const { designerId } = this.state;

        return (
            <div className="mt-3">
                {contents}
                <TransitionRoutes className="slideanim">
                    <AuthorizeRoute path='/manage/:designerId/collections/new' component={AddCollection} />
                </TransitionRoutes>
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/collections`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({ collections: data, loading: false });
    }
}
