import ajax from '@codexteam/ajax';
import uploadFileToBlob from '../../../utils/AzureBlob';
import authService from '../../../api-authorization/AuthorizeService';

/**
 * Module for file uploading. Handle 3 scenarios:
 *  1. Select file from device and upload
 *  2. Upload by pasting URL
 *  3. Upload by pasting file from Clipboard or by Drag'n'Drop
 */
export default class Uploader {
    /**
     * @param {object} params - uploader module params
     * @param {BannerConfig} params.config - banner tool config
     * @param {Function} params.onUpload - one callback for all uploading (file, url, d-n-d, pasting)
     * @param {Function} params.onError - callback for uploading errors
     */
    constructor({ config, onUpload, onError, onUploadProgress }) {
        this.config = config;
        this.onUpload = onUpload;
        this.onError = onError;
        this.onUploadProgress = onUploadProgress;
    }

    /**
    * Handle clicks on the upload file button
    * Fires ajax.transport()
    *
    * @param {Function} onPreview - callback fired when preview is ready
    */
    uploadSelectedFile({ onPreview }) {
        const preparePreview = function (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = (e) => {
                onPreview(e.target.result);
            };
        };


        Uploader.selectFiles({ accept: this.config.types }).then(function (files) {
            preparePreview(files[0]);

            this.AjaxPost(files[0])
                .then(
                    function (data) {
                        if (data && data.result) {
                            //added
                            console.log('upload submitted');
                            console.log(data);
                            this.uploadMediaFile(data, files[0])
                                .then(function() {
                                }.bind(this));
                        } else {
                            console.warn("Server error");
                            this.onError("Error uploading your media.");
                        }
                    }.bind(this)
                ).catch(
                    function (error) {
                        console.warn(error);
                        this.onError("Error uploading media file");
                    }.bind(this)
                );
        }.bind(this));
    }
            


    /**
     * Handle clicks on the upload file button
     * Fires ajax.post()
     *
     * @param {string} url - image source url
     */
    uploadByUrl(url) {
        let upload;

        /**
         * Custom uploading
         */
        if (this.config.uploader && typeof this.config.uploader.uploadByUrl === 'function') {
            upload = this.config.uploader.uploadByUrl(url);

            if (!isPromise(upload)) {
                console.warn('Custom uploader method uploadByUrl should return a Promise');
            }
        } else {
            /**
             * Default uploading
             */
            upload = ajax.post({
                url: this.config.endpoints.byUrl,
                data: Object.assign({
                    url: url,
                }, this.config.additionalRequestData),
                type: ajax.contentType.JSON,
                headers: this.config.additionalRequestHeaders,
            }).then(response => response.body);
        }

        upload.then((response) => {
            this.onUpload(response);
        }).catch((error) => {
            this.onError(error);
        });
    }

    /**
     * Handle clicks on the upload file button
     * Fires ajax.post()
     *
     * @param {File} file - file pasted by drag-n-drop
     * @param {Function} onPreview - file pasted by drag-n-drop
     */
    uploadByFile(file, { onPreview }) {
        /**
         * Load file for preview
         *
         * @type {FileReader}
         */
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = (e) => {
            onPreview(e.target.result);
        };

        let upload;

        /**
         * Custom uploading
         */
        if (this.config.uploader && typeof this.config.uploader.uploadByFile === 'function') {
            upload = this.config.uploader.uploadByFile(file);

            if (!isPromise(upload)) {
                console.warn('Custom uploader method uploadByFile should return a Promise');
            }
        } else {
            /**
             * Default uploading
             */
            const formData = new FormData();

            formData.append(this.config.field, file);

            if (this.config.additionalRequestData && Object.keys(this.config.additionalRequestData).length) {
                Object.entries(this.config.additionalRequestData).forEach(([name, value]) => {
                    formData.append(name, value);
                });
            }

            upload = ajax.post({
                url: this.config.endpoints.byFile,
                data: formData,
                type: ajax.contentType.JSON,
                headers: this.config.additionalRequestHeaders,
            }).then(response => response.body);
        }

        upload.then((response) => {
            this.onUpload(response);
        }).catch((error) => {
            this.onError(error);
        });
    }





    AjaxPost(file) {
        return new Promise(function (resolve, reject) {
            authService.getAccessToken()
                .then(token => {
                    if (!token) {
                        //reject("Could not retrieve authentication");
                        //return;
                        throw new Error(`Could not retrieve authentication`);
                    }

                    const reqHeader = {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        'Authorization': `Bearer ${token}`
                    };

                    resolve(
                        fetch(`/api/brand/${this.config.designerId}/story/edit/media-upload/${this.config.id ? this.config.id : ''}`, {
                            method: 'post',
                            headers: reqHeader,
                            body: JSON.stringify({
                                storyType: this.config.storyType,
                                fileName: file.name
                            }),
                        }).then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error ${response.status}`);
                            }

                            return response.json();
                        })
                    );

                })
                .catch(error => {
                    reject("Could not retrieve authentication");
                });
        }.bind(this));
    }






    uploadMediaFile(data, fileObj) {
        return new Promise((resolve, reject) => {
            console.log('Uploading');
            if (!data.uploadInfo) {
                reject(new Error("No upload data"));
            }

            resolve(
                //upload logo

                uploadFileToBlob(
                    data.uploadInfo.azureStorage.accountUrl,
                    data.uploadInfo.azureStorage.containerName,
                    data.uploadInfo.media.storageSasToken,
                    fileObj,
                    data.uploadInfo.media.fileName,
                    data.userId,
                    this.uploadProgress.bind(this),
                    function () {
                        //success
                        this.onUpload({
                            "success": 1,
                            "file": {
                                "url": data.uploadInfo.media.fileName,
                                // ... and any additional fields you want to store, such as width, height, color, extension, etc
                            }
                        });
                    }.bind(this),
                    function () {
                        //failed
                        this.onError("Failed to upload media");
                    }.bind(this)
                )
            );
        });
    }

    uploadProgress(ev, percentage) {
        console.log(`${percentage}%`);
        if (this.onUploadProgress) {
            this.onUploadProgress(ev, percentage);
        }
    }


    static selectFiles(config = {}) {
        return new Promise((resolve, reject) => {
            /**
             * Create a new INPUT element
             * @type {HTMLElement}
             */
            let inputElement = document.createElement('INPUT');

            /**
             * Set a 'FILE' type for this input element
             * @type {string}
             */
            inputElement.type = 'file';

            if (config.multiple) {
                inputElement.setAttribute('multiple', 'multiple');
            }

            if (config.accept) {
                inputElement.setAttribute('accept', config.accept);
            }

            /**
             * Do not show element
             */
            inputElement.style.display = 'none';

            /**
             * Append element to the body
             * Fix using module on mobile devices
             */
            document.body.appendChild(inputElement);

            /**
             * Add onchange listener for «choose file» pop-up
             */
            inputElement.addEventListener('change', event => {
                /**
                 * Get files from input field
                 */
                const files = event.target.files;

                /**
                 * Return ready to be uploaded files array
                 */
                resolve(files);

                /**
                 * Remove element from a DOM
                 */
                document.body.removeChild(inputElement);
            }, false);

            /**
             * Fire click event on «input file» field
             */
            inputElement.click();
        });
    }



}

/**
 * Check if passed object is a Promise
 *
 * @param  {*}  object - object to check
 * @returns {boolean}
 */
function isPromise(object) {
  return object && typeof object.then === "function";
}
