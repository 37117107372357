import ajax from '@codexteam/ajax';
import uploadFileToBlob from './AzureBlob';
import authService from '../api-authorization/AuthorizeService';


export function uploadSelectedFile({ config, onUploadStart, onUploadDone, onUploadProgress, onPreview, onValidate, onError }) {
    authService.getAccessToken()
        .then(token => {
            if (token) {

                const preparePreview = !onPreview ? null :
                    function (file) {
                        const reader = new FileReader();

                        reader.readAsDataURL(file);
                        reader.onload = (e) => {
                            onPreview(e.target.result);
                        };
                    };

                selectFiles({ accept: config.types }).then(function (files) {
                    preparePreview && preparePreview(files[0]);

                    const validate = onValidate || new Promise((resolve, reject) => {
                        resolve({
                            result: true
                        });
                    });

                    validate(files[0])
                            .then(function (isValid) {
                                if (isValid && isValid.result) {
                                    if (onUploadStart) { onUploadStart(); }
                                    AjaxPost(config, token, files[0])
                                        .then(
                                            function (data) {
                                                if (data && data.result) {
                                                    //added
                                                    console.log('upload submitted');
                                                    console.log(data);
                                                    uploadToCloud(data, files[0],
                                                        onUploadDone,
                                                        onUploadProgress,
                                                        onError);
                                                } else {
                                                    console.warn("Server error");
                                                    onError("Error uploading your media.");
                                                }
                                            }
                                        ).catch(error => {
                                            console.warn(error);
                                            onError("Error uploading media file");
                                        });
                                } else {
                                    console.warn("Media not valid");
                                    onError("Media not valid");
                                }
                            }).catch(error => {
                                console.warn(error);
                                onError("Media not valid");
                            });
                });

            } else {
                onError('Could not retrieve authentication');
            }
        })
        .catch(error => {
            onError(error);
        });
}


function AjaxPost(config, token, file) {
    return new Promise(function (resolve, reject) {
        if (!token) {
            //reject("Could not retrieve authentication");
            //return;
            throw new Error(`Could not retrieve authentication`);
        }

        const reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': `Bearer ${token}`
        };

        resolve(
            fetch(config.url, {
                method: 'post',
                headers: reqHeader,
                body: JSON.stringify({
                    ...config.reqBody,
                    fileName: file.name
                }),
            }).then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }

                return response.json();
            })
        );
    });
}






function uploadToCloud(data, fileObj, onUploadDone, onUploadProgress, onError) {
    console.log('Uploading');
    if (!data.uploadInfo) {
        onError("No upload data");
        return;
    }

    console.log(data);

    uploadFileToBlob(
        data.uploadInfo.azureStorage.accountUrl,
        data.uploadInfo.azureStorage.containerName,
        data.uploadInfo.media.storageSasToken,
        fileObj,
        data.uploadInfo.media.fileName,
        data.userId,
        onUploadProgress,
        function () {
            //success
            setTimeout(
                function () {
                    onUploadDone({
                        "success": 1,
                        "data": data,
                        "newFile": {
                            "url": data.uploadInfo.media.fileName,
                            // ... and any additional fields you want to store, such as width, height, color, extension, etc
                        }
                    });
                }.bind(this),
                500
            );
        },
        function () {
            //failed
            onError("Failed to upload media");
        }
    );
}


function selectFiles(config = {}) {
    return new Promise((resolve, reject) => {
        /**
         * Create a new INPUT element
         * @type {HTMLElement}
         */
        let inputElement = document.createElement('INPUT');

        /**
         * Set a 'FILE' type for this input element
         * @type {string}
         */
        inputElement.type = 'file';

        if (config.multiple) {
            inputElement.setAttribute('multiple', 'multiple');
        }

        if (config.accept) {
            inputElement.setAttribute('accept', config.accept);
        }

        /**
         * Do not show element
         */
        inputElement.style.display = 'none';

        /**
         * Append element to the body
         * Fix using module on mobile devices
         */
        document.body.appendChild(inputElement);

        /**
         * Add onchange listener for «choose file» pop-up
         */
        inputElement.addEventListener('change', event => {
            /**
             * Get files from input field
             */
            const files = event.target.files;

            /**
             * Return ready to be uploaded files array
             */
            resolve(files);

            /**
             * Remove element from a DOM
             */
            document.body.removeChild(inputElement);
        }, false);

        /**
         * Fire click event on «input file» field
         */
        inputElement.click();
    });
}

/**
 * Check if passed object is a Promise
 *
 * @param  {*}  object - object to check
 * @returns {boolean}
 */
function isPromise(object) {
    return object && typeof object.then === "function";
}
