import { default as React, Component, Fragment } from 'react';
import { Prompt } from 'react-router-dom'

import EditorJS from '@editorjs/editorjs';
import TextBox from './blocks/textbox/Index'; 
//import SimpleImage from './blocks/SimpleImage'; 
import Header from './blocks/header/Header'; 
//import TextColumns from './blocks/TextColumns';
//import LeadText from './blocks/LeadText';
import TextColumns from './blocks/textcolumns/Index';

import BannerBlock from './blocks/banner/Index';
import TextWithMediaBlock from './blocks/textwithmedia/Index';
import LeadTextBlock from './blocks/leadtext/Index';
import VideoBlock from './blocks/video/Index';
import ImagesCarouselBlock from './blocks/imagescarousel/Index';
import ImagesGridBlock from './blocks/imagesgrid/Index';


export class BlockEditor extends Component {
    static displayName = BlockEditor.name;

    static EDITTOR_HOLDER_ID = 'storyeditorjs';

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            designerId: props.designerId,
            id: props.id,
            storyType: props.storyType,
            loading: true,
            error: null,
            datachanged: false
        };
        this.triggerSaveData = this.triggerSaveData.bind(this);
        this.dataSavedSuccessful = this.dataSavedSuccessful.bind(this);
        this.beforePageUnload = this.beforePageUnload.bind(this);
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.beforePageUnload);
        if (!this.ejInstance) {
            this.initEditor(this.props.data);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforePageUnload);
        if (this.ejInstance) {
            this.ejInstance.destroy();
            this.ejInstance = null;
        }
    }

    initEditor(data) {
        console.log(data);
        console.log(this.state.designerId);
        const editor = new EditorJS({
            holder: BlockEditor.EDITTOR_HOLDER_ID,
            logLevel: "ERROR",
            data: data,
            onReady: () => {
                this.ejInstance = editor;
            },
            //onChange: async () => {
            //let content = await this.editorjs.saver.save();
            // Put your logic here to save this data to your DB
            //setEditorData(content);
            //console.log(content);
            //},

            onChange: (api, event) => {
                console.log('Now the Editor\'s content changed!', event)
                this.setState({
                    datachanged: true
                });
            },
            autofocus: false,
            defaultBlock: 'textBox',
            tools: {
                paragraph: false,
                textBox: {
                    class: TextBox,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
                textColumns: {
                    class: TextColumns
                },
                header: Header,
                //simpleImage: SimpleImage,
                //textColumnss: TextColumns,
                //leadText: LeadText,
                banner: {
                    class: BannerBlock,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
                textWithMedia: {
                    class: TextWithMediaBlock,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
                leadText: {
                    class: LeadTextBlock,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
                video: {
                    class: VideoBlock,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
                imagesCarousel: {
                    class: ImagesCarouselBlock,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
                imagesGrid: {
                    class: ImagesGridBlock,
                    config: {
                        designerId: this.state.designerId,
                        id: this.state.id,
                        storyType: this.state.storyType,
                    }
                },
            },
        });
        console.log(editor);
        window.onscroll = null;
    };

    triggerSaveData() {
        console.log('saving editor data');
        if (this.ejInstance) {
            this.ejInstance.save().then((outputData) => {
                console.log('Editor data: ', outputData)
                this.props.saveData(outputData);
            }).catch((error) => {
                console.log('Saving failed: ', error)
            });
        } else {
            console.log('Saving failed: No editor')
        }
    }

    dataSavedSuccessful() {
        //data saved
        this.setState({
            datachanged: false
        });
    }

    beforePageUnload(e) {
        if (this.state.datachanged === true) {
            e.preventDefault();
            e.returnValue = "Are you sure you want to leave before saving?";
        }
    }

    render() {
        return (
            <Fragment>
                <div id={BlockEditor.EDITTOR_HOLDER_ID}> </div>
                <Prompt
                    when={this.state.datachanged === true}
                    message="Are you sure you want to leave before saving?"
                />
            </Fragment>
        );
    }
}
export default BlockEditor;
