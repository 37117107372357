import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import './ServiceAgreement.css'

export class ServiceAgreement extends Component {
    static displayName = ServiceAgreement.name;

    render() {
        return (
            <div id="service-agreement" className="main-sign p-5">
                <section>
                    <h1 className="text-center mb-3"><span className="brand-lg d-inline-block">Flair Designer Portal</span></h1>
                    <h4 className="text-center mb-3">Flair Service Agreement</h4>
                    <p>
                        Flair Fashion Limited, with registered address at 71-75 Shelton Street, London, Greater London, United Kingdom, WC2H 9JQ (“FF”), provides an online marketplace service that allows vendors (“Designers”) to list and sell their products to buyers (“Marketplace”). FF does not act as the agent for either buyers or Designers. Our service is solely to provide a platform for these transactions to take place.
                    </p>
                    <p>
                        In order to list and sell items on <a href="https://flair.fashion" target="_blank">http://flair.fashion</a> (the “Website”), you must agree to this FF Marketplace Designer agreement outlined below, which includes our privacy policy and any other terms and conditions we may put in place from time to time.
                    </p>
                    <p>
                        By using this Website and the Marketplace, you agree to be bound by all terms and conditions of this Designer Service Agreement, including any changes. Changes to this Designer Service Agreement are effective when the changes are posted to the Designers or sent by mail. Continuing to sell items on the Website will constitute your agreement to the new FF Designer Service Agreement. It is your responsibility to review the changes and decide if you want to continue to use the Website.
                    </p>
                    <ol className="ml-0 pl-0">
                        <li className="mb-4">
                            <h3 className="h5">
                                ACCOUNT SET-UP AND PROFILE
                            </h3>
                            <p>
                                You must complete the Marketplace registration process to create an account with a username and password (the “Account”) in order to list and sell on the FF Marketplace. You are responsible for protecting your password. You must always provide accurate, current and complete information, and you must update such information in a timely manner to maintain its accuracy and completeness. Any use of the Marketplace through your Account will be deemed as being used by you. FF is entitled to rely on the contact and other information that is supplied to us through your Account. Your Account is non-transferable and non-assignable.
                            </p>
                            <p>
                                You will provide FF with photos, videos and other information to upload on your Designer profile. You may request any of this to be changed at any time by providing FF with 7 days written notice.
                            </p>
                            <p>
                                Where you would like to add new products, you will need to provide us with 14 days’ notice. Where you would like to discontinue products, you will need to provide us with at least 30 days’ notice.
                            </p>
                            <p>
                                Designer has the right to choose how they want to offer their products, whether they are ready-made or made-to-order.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                ELIGIBILITY REQUIREMENTS
                            </h3>
                            <p>
                                Listing items for sale on the Website is limited to parties that can lawfully enter into a contract. Minors are not allowed to buy or sell on the website. To list items for sale you must supply your details during registration.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                MARKETPLACE FEES
                            </h3>
                            <p>
                                FF takes a 12% flat commission only when there is a sale via the Marketplace. Listing and showcasing on FF is free.<br/>
                                Our third-party secure payment processing company, Stripe, also takes commission for processing payments as follows: 1.2%+20p (European) or up to 2.9%+20p (non-European) card payment handling fee. There is also a 0.25%+10p handling fee when we process the pay-out to the Designer at the end of the month.
                            </p>
                            <p>
                                Fees may be reviewed at any time for any reason with 14 days’ notice via email. The fee in effect on the date of sale of the item shall govern the transaction. By listing an item for sale in the FF Marketplace Platform, you agree to pay the applicable fees and any collection costs related to non-payment of fees. Designers can exit FF Marketplace at any time by providing at least 30 days’ notice to the management of FF. All images, content, prices shall be unpublished immediately from the Marketplace after approval is given to the FF administration to strike them off the Marketplace, however each Designer agrees that we may archive their images, content and prices offline, unless there is a specific request to the contrary. Deleted Designers can register back into the FF Marketplace at a later date if they so desire.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                PAYMENTS MADE BY BUYERS
                            </h3>
                            <p>
                                All FF Marketplace payment transactions happen securely via third-party payment processing provider, Stripe. Stripe is fully PCI compliant. FF will not store any payment sensitive data on the platform.
                            </p>
                            <p>
                                Income made on products sold will be paid out to the Designer on a monthly basis and after the product has passed its initial return period, if it qualifies for a refund.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                REQUIRED COMMUNICATION
                            </h3>
                            <p>
                                FF requires you to provide timely support to the Marketplace customers. All messages should ideally be replied to within 24hrs. Please, resolve all customer issues related to the Marketplace within 48hrs if possible. You are also required to notify us when you change the status of an order. Changes in status could include but are not limited to: cancelling an order, refunding an order or alerting a customer about a missing item from their order, or changing your location to a new one.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                SHIPPING EXPECTATIONS
                            </h3>
                            <p>
                                You must state all shipping fees and delivery timeframes clearly. All orders should be shipped to customers within the timeframe Designers have committed to in their products details. It is your responsibility to deliver the items contained within an order to the customer. If you are unable to arrange for your own shipping we can assist with this, at prices to be agreed, with notification when you join FF.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                DESIGNERS RESTRICTIONS
                            </h3>
                            <p>
                                Designers cannot list or sell items that: infringe upon intellectual property rights or are illegal, fake, counterfeit, or stolen. Items listed for sale must be listed on the correct item page and an accurate description of the product’s description must be given in the product’s category.
                            </p>
                            <p>
                                FF reserves the right to prohibit the sale of any item on the FF Marketplace. At our sole discretion, products may be removed at any time without notice. The way listings appear in the Marketplace may change without notice to users of the Marketplace.
                            </p>
                            <p>
                                Designers are expected to only list items and quantities they are capable and willing to sell in the delivery timeframe they specify. Designers are required to sell items at the price they listed to customers that meet the Designer’s terms. By listing an item on our Marketplace, you agree that you have the right to sell the item, that you have access to the item and that your description provided in your listing is complete and accurate. Failure to ship an order by the provided date, without a cogent reason and effective communication to the parties involved, may be grounds for termination of your account.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                INTELLECTUAL PROPERTY
                            </h3>
                            <p>
                                All intellectual property in the FF Marketplace remains with FF.
                            </p>
                            <p>
                                All intellectual property produced by the Designer shall reside with the Designer. The Designer shall provide FF with a revocable, worldwide, royalty free licence to use the Designer intellectual property on the marketplace for the promotion of the Designer.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                PROMOTION
                            </h3>
                            <p>
                                The Designer agrees that FF has right to promote the Designer in different activities such as advertisements, editorial, social media and newsletter, however FF thinks best in their sole discretion.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                TAXES
                            </h3>
                            <p>
                                All products on FF Marketplace must be shown as inclusive of VAT and final. It is the responsibility of the Designer to pay any taxes and VAT as applicable for their products.<br/>
                                For any Designer residing outside of the UK, the Designer will remain liable for any import VAT and Customs Duty when the goods are first imported into the UK and delivered to customers.<br />
                                The Designer will need to take this into account when calculating their final product prices shown on FF Marketplace.<br />
                                In situations where FF will have to pay VAT or tax on products on behalf of the designer, FF will work with the Designer to meet the UK HMRC requirements and payment may be deducted.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                RETURN POLICY
                            </h3>
                            <p>
                                For all of your products you will accept, and process returns, refunds and adjustments in accordance with our refund policy. The Designer is responsible for calculating the amount of all refunds and adjustments (including any taxes, shipping and handling or other charges) or other amounts to be paid by Designer to customers in connection with the Marketplace purchases and work directly with Stripe. You will promptly provide refunds and adjustments that you are obligated to provide and as required by law, and in no case later than thirty (30) days after the obligation arises.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                TERMINATION
                            </h3>
                            <p>
                                FF reserves the right to monitor Designer accounts to determine if policies or laws are being broken. Any breach of these terms or any law may result in termination of the Designer account and these terms. Any illegal or fraudulent activity may be reported by FF to law enforcement or other third parties. Upon termination, Designer must pay FF any fees that were incurred prior to the effective date of termination.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                WARRANTY
                            </h3>
                            <p>
                                The Marketplace is provided on an “as is” and without warranty. FF makes no warranties of any kind, expressed or implied. FF will provide best effort to ensure the Marketplace is available 24 hours-a-day 365 days- a-year, but we do not guarantee the Marketplace will always be available. FF will not be liable for any loss due to the Marketplace not being available. FF will not be liable for any loss due to errors in our software or the software of others that we use.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                INDEMNITY/LIMITATION OF LIABILITY
                            </h3>
                            <p>
                                You will defend, indemnify and hold harmless FF and its affiliates (and their respective employees, directors, agents and representatives) from and against any and all claims, costs, losses, damages, judgments, penalties, interest and expenses (including reasonable attorneys' fees) arising out of any claim, action, audit, investigation, inquiry or other proceeding instituted by a person or entity that arises out of or relates to: (i) any actual or alleged breach of your representations, warranties, or obligations set forth in this service agreement; or (ii) your own website or other sales channels, the products you sell, any content you provide, the advertisement, offer, sale or return of any products you sell, any actual or alleged infringement of any intellectual property or proprietary rights by any products you sell or content you provide, or the collection, payment or failure to collect or pay any taxes.
                            </p>
                            <p>
                                FF will not be liable for any damages of any kind, including without limitation direct, indirect, incidental, punitive, and consequential damages, arising out of or in connection with this service agreement, the site, the inability to use the site or the marketplace, or those resulting from any goods or services purchased or obtained or messages received or transactions entered into via the Marketplace.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                DISPUTES
                            </h3>
                            <p>
                                You agree to release FF from claims, demands, and damages related to transaction disputes not directly related to the FF Marketplace. If a dispute arises, customers and Designers are expected to deal with each other to come to a resolution. In some cases, FF management can intervene in a dispute with a view to resolving them, however for the avoidance of doubt, FF is under no obligation to do so.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                JURISDICTION
                            </h3>
                            <p>
                                Any claim against FF in relation to the Marketplace shall be adjudicated in an English court and you agree to exclusive jurisdiction of these courts. This agreement shall be governed by English law, without regard to conflict of laws principles thereof.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                CONTENT AGREEMENT
                            </h3>
                            <p>
                                By agreeing to this agreement, you grant the royalty-free use of any content you submit to us. You represent that you own any content you submit to us or you have the right to distribute submitted content. You will be responsible for any violation of rights for unauthorized content.
                            </p>
                        </li>
                        <li className="mb-4">
                            <h3 className="h5">
                                SEVERABILITY
                            </h3>
                            <p>
                                If any section of this agreement is deemed unlawful, void or unenforceable, then that section shall be deemed severable and the remainder of the agreement will remain in force. Failure to enforce any section of this agreement by FF does not constitute a waiver of FF’s right to enforce such section or any other section in the future.
                            </p>
                        </li>
                    </ol>
                </section>
                <footer className="footer">
                    <div className="px-4 my-5 pt-5 px-md-5 ml-md-5">
                        <div className="px-4 mt-5 pb-3 container-fluid">
                            <ul className="nav">
                                <li className="copy nav-item"><div className="">&copy; 2023 Flair Fashion Limited. All rights reserved.</div></li>
                                <li className="nav-item"><a className="nav-link nav-icon" href="https://www.instagram.com/flair.fashionofficial/" target="_blank"><svg data-name="Layer 1" className="ty-social-icon mx-2" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path className="social-cls-1" d="M83,23a22,22,0,0,1,22,22V83a22,22,0,0,1-22,22H45A22,22,0,0,1,23,83V45A22,22,0,0,1,45,23H83m0-8H45A30.09,30.09,0,0,0,15,45V83a30.09,30.09,0,0,0,30,30H83a30.09,30.09,0,0,0,30-30V45A30.09,30.09,0,0,0,83,15Z"></path><path className="social-cls-1" d="M90.14,32a5.73,5.73,0,1,0,5.73,5.73A5.73,5.73,0,0,0,90.14,32Z"></path><path className="social-cls-1" d="M64.27,46.47A17.68,17.68,0,1,1,46.6,64.14,17.7,17.7,0,0,1,64.27,46.47m0-8A25.68,25.68,0,1,0,90,64.14,25.68,25.68,0,0,0,64.27,38.47Z"></path></svg></a></li>
                            </ul>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}