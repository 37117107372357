import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants';
import authService from '../api-authorization/AuthorizeService';
import './Home.css';
import { FeaturedDesigners } from './FeaturedDesigners';
import { SimpleQA } from '../common/SimpleQA';
import { Collapse } from 'reactstrap';
import { HomeFlairPillar } from './HomeFlairPillar';


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            authenticated: false,
            featured: null
        };
        //this.setBreadcrumbs(props);
        this.fetchData = this.fetchData.bind(this);
    }

    /*
    setBreadcrumbs(props) {
        props.setBreadcrumbs(
            <Fragment>
                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
            </Fragment>
        );
    }
    */

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.redirectLoggedInUsers();
        this.fetchData();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async redirectLoggedInUsers() {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated: isAuthenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.redirectLoggedInUsers();
    }

    async fetchData() {
        const response = await fetch(`api/home`, {
        });
        const data = await response.json();
        console.log(data);
        this.setState({ featured: data });

        //console.log(data);
    }


    render() {
        //console.log(this.props.setBreadcrumbs);
        const { ready, authenticated } = this.state;

        if (!ready) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>);
        }

        if (authenticated) {
            return <Redirect to="/manage" />
        }

        const signupUrl = `${ApplicationPaths.Register}`;
        const loginUrl = `${ApplicationPaths.Login}`;

        return (
            <div>
                <header className="sticky-top">
                    <nav className="navbar navbar-light bg-light sticky-top shadow-sm" id="mainNav">
                        <div className="container px-0 px-md-5">
                            <a className="navbar-brand d-inline-block" href="/">Flair Designer Portal</a>
                            <Link to={loginUrl} className="btn btn-dark rounded-pill btn-sm px-3 mb-2 mb-lg-0">
                                <span className="d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" viewBox="0 0 16 16" width="16" height="16"><path fill="#fff" fillRule="evenodd" d="M1.5 3.25c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5A1.75 1.75 0 015.75 7.5h-2.5A1.75 1.75 0 011.5 5.75v-2.5zM3.25 3a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h2.5A.25.25 0 006 5.75v-2.5A.25.25 0 005.75 3h-2.5zM1.5 10.25c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 01-1.75 1.75h-2.5a1.75 1.75 0 01-1.75-1.75v-2.5zM3.25 10a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-2.5zM8.5 3.25c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 01-1.75 1.75h-2.5A1.75 1.75 0 018.5 5.75v-2.5zM10.25 3a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-2.5zM8.5 10.25c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 01-1.75 1.75h-2.5a1.75 1.75 0 01-1.75-1.75v-2.5zm1.75-.25a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-2.5z"></path></svg>
                                    <span className="text-uppercase">Enter Portal</span>
                                </span>
                            </Link>
                        </div>
                    </nav>
                </header>
                <section id="mainbanner">
                    <div className="carousel-home mb-5 mb-md-0 homep-b">
                        <div className="homep-b-txt d-flex align-items-center">
                            <div>
                                <div className="header-style">
                                    
                                    <p>An ecosystem for the future of fashion, connecting customer to creator like never before. <span className="hl">We are Flair.</span></p>
                                </div>
                                <div className="mt-5">
                                    <p>Join our waiting list to get started with Flair, accessing the portal and all of Flair's ecosystem.</p>
                                    <Link to={signupUrl} className="btn btn-dark rounded-pill btn-sm px-3">Request Early Access</Link>
                                    <div className="mt-2">
                                        <Link to={loginUrl}>Already have an account? Enter Portal</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <picture className="w-100">
                            <source media="(orientation: portrait) and (max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/margn-banner_m_3vl1px71Y.jpg" />
                            <source media="(orientation: landscape) and (max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" />
                            <source media="(orientation: portrait) and (max-width: 1030px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1500/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" />
                            <source media="(max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" />
                            <source media="(max-width: 991.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1600/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" />
                            <source media="(max-width: 1499.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1920/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" />
                            <source media="(min-width: 1500px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-2000/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" />
                            <img src="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/margn-banner_d_O1GwxxgyT.jpg" className="w-100" alt="MARGN" />
                        </picture>
                        <div className="small text-muted homep-b-credit right">Photo by designer <a href="https://flair.fashion/margn" target="_blank" className="text-muted">MARGN</a></div>
                    </div>
                </section>
                <section id="whatisflair">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 p-0 order-last order-md-first d-flex align-items-center">
                                <picture className="w-100">
                                    <source media="(orientation: portrait) and (max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <source media="(orientation: landscape) and (max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <source media="(orientation: portrait) and (max-width: 1030px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1500/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <source media="(max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <source media="(max-width: 991.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1600/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <source media="(max-width: 1499.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1920/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <source media="(min-width: 1500px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-2000/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" />
                                    <img src="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000/Designer_Portal/Home/EssinB_2_profile_kvcTCKkUL.jpeg" className="w-100" alt="MARGN" />
                                </picture>
                                <div className="small text-muted homep-b-credit right">Photo by designer <a href="https://flair.fashion/essin-baris" target="_blank" className="text-muted">Essin Baris</a></div>
                            </div>
                            <div className="col-md-6 order-first order-md-last">
                                <div className="text-inner sticky-top my-5 pb-5">
                                    <h1 className="h2">What is Flair?</h1>
                                    <div className="mb-5">Flair is an ecosystem for emerging designers at the forefront of innovation in design, sustainability and technology. At the front end, it combines marketplace and showcasing with an emphasis on storytelling. Beneath the surface, we’re building a connection hub to bridge emerging talents with the supply chain services they need to grow their brand.</div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <HomeFlairPillar title="The Marketplace">
                                                <p>All designers have the opportunity to sell through the Flair marketplace, especially tailored to accommodate the needs of an emerging designer. Non-exclusive, free to join, flexible selling options, and storytelling enriched with emerging technology to allow your customers to experience your product like never before.</p>
                                            </HomeFlairPillar>
                                            <HomeFlairPillar title="The Showcase Vault">
                                                <p>At Flair, we know that an incredible level of passion and innovation goes into building a brand and collection. Through storytelling paired with cutting-edge technology, the showcase vault offers a new way for designers and fashion graduates to display the unique story, values, and creative processes behind their brand. We believe this is a pivotal key to connecting emerging brands to new customers.</p>
                                            </HomeFlairPillar>
                                            <HomeFlairPillar title="The Connection Hub">
                                                <p>Flair is building a Connection Hub to pair designers with a set of tools and supply chain services they need to establish and grow their brands. Our mission is to democratise the supply chain and help more brands with their production, access to tools and knowledge.</p>
                                            </HomeFlairPillar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="features">
                    <div className="container mb-0 py-5">
                        <div className="row gx-5 align-items-center">
                            <div className="col-lg-8 order-lg-0 mb-5 mb-lg-0">
                                <div className="container-fluid px-5">
                                    <h1 className="text-center mb-4 h2">Designer Benefits</h1>
                                    <div className="row">
                                        <div className="col-md-6 mb-5">
                                            <div className="text-left">
                                                <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                                                <h3 className="h5">Tell your story</h3>
                                                <p className="text-muted mb-0">We give you the platform, you control how you use it. We believe storytelling is the key connection point between you and your customer. By allowing you to showcase your brand or graduation work with our easy-to-use tools, we give you the flexibility to do it your way; whether that’s with text, images, videos, 3D, VR, AR or all of the above.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-5 mb-md-0">
                                            <div className="text-left">
                                                <i className="bi-gift icon-feature text-gradient d-block mb-3"></i>
                                                <h3 className="h5">Sell your products</h3>
                                                <p className="text-muted mb-0">Ready to sell your products? Great! By signing up to the marketplace you can sell directly to consumers ready to discover and connect with your brand. Allow for a greater experience in viewing your products using Augmented Reality.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-5">
                                            <div className="text-left">
                                                <i className="bi-camera icon-feature text-gradient d-block mb-3"></i>
                                                <h3 className="h5">Access to supply chain</h3>
                                                <p className="text-muted mb-0">With Flair's <strong>Connection Hub</strong>, we’re building an eco-system, giving you access to a network of services in the supply chain, empowering you to accelerate your brand at cheaper costs, and through more sustainable means.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-left">
                                                <i className="bi-patch-check icon-feature text-gradient d-block mb-3"></i>
                                                <h3 className="h5">Grow your brand</h3>
                                                <p className="text-muted mb-0">Flair was born out of a desire to effectively support emerging designers to both sell with ease and grow their brand from a strong foundation. With the expectation of diversity in each brand’s offerings, desires and needs we have uniquely tailored our platform to accommodate this. The eco-system we are building will give you access to a network of trusted services in the supply chain, so that you don’t have to navigate everything on your own. Above all, it is completely free of charge to showcase and sell, with a 12% flat rate fee on sales.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-lg-1">
                                <div className="features-device-mockup">
                                    <div className="device-wrapper">
                                        <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                                            <div className="screen bg-black">
                                                <video muted="muted" autoPlay={true} loop="loop" style={{ maxWidth: '100%', height: '100%' }}><source src="https://res.cloudinary.com/flairfashion/video/upload/v1732182771/DesignerPortal/Home/jack-e-hebden-jumper-3d.mp4" type="video/mp4" /></video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="users" className="test">
                    <div className="container mb-5 py-5" style={{ maxWidth: "900px" }}>
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <div className=" p-4">
                                    <h3 className="h4 font-weight-bold text-center">For<br/> brands and designers</h3>
                                    <p className="mb-0">We provide the platform, you control how you use it. Showcase your brand and manage your products on the Flair Marketplace with our easy-to-use tools and features. Access a network of services in the supply chain through the Flair Connection Hub to grow your business (coming soon). Built to support independent brands, Flair gives you the flexibility of selling options like small batch production, made-to-order and custom orders, with no joining nor listing fees, and a competitive commission rate on sales.</p>
                                </div>
                            </div>
                            <div className="col-md-6 text-left">
                                <div className=" p-4">
                                    <h3 className="h4 font-weight-bold text-center">For<br /> fashion graduates</h3>
                                    <p className="mb-0">Storytelling is at the heart of Flair. Showcase your brand or graduate collection with our space and tools to share your designs your way; whether you’re experimenting with 3D, crafting a sustainable future, exploring new techniques, or all of the above. Begin by joining the Flair showcase, and when you are ready to sell, our platform is built to get you started.</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 pt-5 text-center">
                            <p>Join our waiting list to be onboarded. We are processing new designers as quickly as we can.</p>
                            <Link to={signupUrl} className="btn btn-dark rounded-pill btn-sm px-3">Request Early Access</Link>
                            <div className="mt-2">
                                <Link to={loginUrl} className="link-light">Already have an account? Enter Portal</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="faq">
                    <div className="simple-qa mx-auto">
                        <h1 className="text-center mb-5 h2">Frequently Asked Questions</h1>
                        <SimpleQA
                            question="How does Flair work?">
                            <p>
                                Flair is a marketplace connecting customers directly with you, the designer - providing you with a platform to showcase your brand and advertise your products.
                                When your product is sold on Flair, you get notified to package and send it directly to the customer.
                            </p>
                            <p>
                                By joining Flair, you will work with a team who are focussed on relaying your brand story through a unique user experience.
                                We also look after marketing, customer service and other bits and pieces so you don't have to.
                            </p>
                            <p>Designers and fashion graduates are also welcome to showcase on Flair without selling their products.</p>
                        </SimpleQA>
                        <SimpleQA
                            question="Does it cost anything to join Flair?">
                            <p>We know how hard it is to start a brand. Therefore, we’ve made it completely free to join and showcase your brand, products and collections. We only take commission based on sales, making it risk free for designers to join.</p>
                            <p>For sales made through Flair, our commission rate is a 12% flat fee. Other fees include small payment handling fees by our secure third-party payment provider, Stripe, ranging between 1%-3%. These are typical standard fees by any online payment providers, and are not handled by Flair.</p>
                            <p>You are not required to sell products on the Flair marketplace to showcase your brand on Flair.</p>
                        </SimpleQA>
                        <SimpleQA
                            question="I'm just starting out my brand - can Flair help me with manufacturing?">
                            <p>
                                We are building a network across the supply chain, partnering with key organisations and institutions to extend these connections to our designers. If you have a specific need in mind, get in contact with our team and let us know what stage you are at so we can help get you started. 
                            </p>
                            <p>Designers on Flair are not forced to used our Connection Hub service. Designers on Flair are under no obligation to use our Connection Hub service, this is an optional tool should you need it. You are free to use any production service as long it provides good quality and customer satisfaction.</p>
                        </SimpleQA>
                        <SimpleQA
                            question="I don't have any products yet, but can I still showcase my concept and brand on Flair?">
                            <p>Yes! You can showcase on Flair without selling on our Marketplace. We know that building a brand and refining a product takes time — our aim is to empower fashion graduates and emerging designers to showcase their innovation, craft and process. You can begin by creating a brand page with your story and collections, including your final year graduate collection.</p>
                            <p>If you decide you would like to sell your product on Flair, we have built our designer portal to help you seamlessly join our Marketplace if and when it suits you.</p>
                        </SimpleQA>
                        <SimpleQA
                            question="In which market does Flair sell?">
                            <p>
                                At this early stage our focus is currently on the UK market, in order to control the experience and customer service - it's very important for us to get it right. We will eventually expand outside of the UK and further details will be announced in the near future.
                            </p>
                        </SimpleQA>
                        <SimpleQA
                            question="I'm a designer based outside of the UK. Can I still sell to the UK market?">
                            <p>Yes, you can be based anywhere in the world.</p>
                            <p>If you are already delivering to the UK and are using a DDP (Delivered Duty Paid) model, you can continue to do so, and we can include those charges within your advertised product price on Flair.</p>
                            <p>Otherwise, if you're expecting the customer to be responsible for paying any import tax and duties, we can include a message to the customer in the Delivery tab on your product pages, to inform them.</p>
                            <p>We're more than happy to discuss and explain this further, if you reach out to our team.</p>
                        </SimpleQA>
                        <SimpleQA
                            question="Can I sell based on made-to-order or limited runs?">
                            <p>Yes, you can. Products that are made-to-order, custom, pre-order or have limited runs will be highlighted in the product description to notify the customer. Other information, including making and delivery times, will also be included in the description.</p>
                            <p>We encourage the diversity in the making process of our designers and we do not want to limit or change the way you work.</p>
                        </SimpleQA>
                        <SimpleQA
                            question="How is the return of products handled?">
                            <p>To instil customers with confidence in buying from emerging designers, it's important for us to unify the return policies on the platform rather than tailor it to each designer. Our general policy for returns is a 14-day full refund/exchange for ready-made products. Made-to-order or custom products will be partially refundable, of 50%, to cover the cost of material and labour, as the product is made for the customer.</p>
                            <p>Once the customer enquires about a refund and meets the requirements in our policy, they will receive the return address you provide. The customer will cover the cost of return shipping within the UK.</p>
                            <p>
                                Please refer to our <Link to="/return-policy">Return Policy</Link> page for more details and reach out if you have any questions.
                            </p>
                        </SimpleQA>
                        <SimpleQA
                            question="How do I join?">
                            <p>It’s great that you are looking to join Flair. We have simplified the process with the portal, enabling you to manage your entire brand and what can be done within Flair.</p>
                            <p>The steps are as follows:</p>
                            <ol>
                                <li className="mb-2"><Link to={signupUrl}>Sign up</Link> to create your Flair account, you will receive an email which you’ll need to click to verify your email address.</li>
                                <li className="mb-2">After that, fill the form to sign up your brand with Flair.</li>
                                <li className="mb-2">Once the Flair team have verified your details, you’ll be free to manage your account within the portal.</li>
                                <li className="mb-2">Once you complete your content, you can click to submit it for review and go live.</li>
                            </ol>
                            <p>If you have any issues, feel free to contact Flair team on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>.</p>
                        </SimpleQA>
                    </div>
                </section>



                <footer className="footer">
                    <div className="px-4 my-5 pt-5 px-md-5 ml-md-5">
                        <div className="px-4 mt-5 pb-3 container-fluid">
                            <ul className="nav">
                                <li className="copy nav-item"><div className="">&copy; 2023 Flair Fashion Limited. All rights reserved.</div></li>
                                <li className="nav-item"><a className="nav-link nav-icon" href="https://www.instagram.com/flair.fashionofficial/" target="_blank"><svg data-name="Layer 1" className="ty-social-icon mx-2" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path className="social-cls-1" d="M83,23a22,22,0,0,1,22,22V83a22,22,0,0,1-22,22H45A22,22,0,0,1,23,83V45A22,22,0,0,1,45,23H83m0-8H45A30.09,30.09,0,0,0,15,45V83a30.09,30.09,0,0,0,30,30H83a30.09,30.09,0,0,0,30-30V45A30.09,30.09,0,0,0,83,15Z"></path><path className="social-cls-1" d="M90.14,32a5.73,5.73,0,1,0,5.73,5.73A5.73,5.73,0,0,0,90.14,32Z"></path><path className="social-cls-1" d="M64.27,46.47A17.68,17.68,0,1,1,46.6,64.14,17.7,17.7,0,0,1,64.27,46.47m0-8A25.68,25.68,0,1,0,90,64.14,25.68,25.68,0,0,0,64.27,38.47Z"></path></svg></a></li>
                            </ul>
                        </div>
                    </div>
                </footer>

            </div>
        );
    }

    async populateAuthenticationState() {
    }

}