import { make } from '../../utilities/Utilities';

/**
 * Working with Block Tunes
 */
export default class ImagesGridBlockTunes {
    /**
     * @param {object} tune - image tool Tunes managers
     * @param {object} tune.api - Editor API
     * @param {object} tune.actions - list of user defined tunes
     * @param {Function} tune.onChange - tune toggling callback
     */
    constructor({ api, actions, onChange }) {
        this.api = api;
        this.actions = actions;
        this.onChange = onChange;
        this.buttons = [];
    }

    /**
     * Available Banner tunes
     *
     * @returns {{name: string, icon: string, title: string}[]}
     */
    static get tunes() {
        return [
            {
                name: 'addImage',
                icon: '<svg fill="#fff" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L15.5 15.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 19H19M22 19H19M19 19V16M19 19V22" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'New image',
            },
            {
                name: 'addThreeImages',
                icon: '<svg fill="#fff" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L15.5 15.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 19H19M22 19H19M19 19V16M19 19V22" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Add 3 images to end',
            },
            {
                name: 'removeImage',
                icon: '<svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L14 14.8182" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.8786 21.1213L19 19M21.1213 16.8787L19 19M19 19L16.8786 16.8787M19 19L21.1213 21.1213" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                title: 'Remove last image',
            },
            {
                value: 2,
                name: 'totalPerRow',
                toggle: 'totalPerRow',
                title: 'Two per row',
                icon: '<svg fill="none" height="16" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L21 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
                value: 3,
                name: 'totalPerRow',
                toggle: 'totalPerRow',
                title: 'Three per row',
                icon: '<svg fill="none" height="20" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L21 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
                value: 4,
                name: 'totalPerRow',
                toggle: 'totalPerRow',
                title: 'Four per row',
                icon: '<svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M3 16L10 13L21 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path fill="#fff" d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            },
            {
                name: 'itemsAlignment',
                toggle: 'itemsAlignment',
                value: 'top',
                icon: '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.5,2H1.5a.5.5,0,0,1,0-1h21a.5.5,0,0,1,0,1Z"/><path d="M18,15H14V4h4Zm.5-12h-5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,18.5,3Z"/><path d="M10,22H6V4h4Zm.5-19h-5a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,10.5,3Z"/></svg>',
                title: 'Alignment to top',
            },
            {
                name: 'itemsAlignment',
                toggle: 'itemsAlignment',
                value: 'middle',
                icon: '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.5,11.5H19v-6a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v6H11V1.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v10H1.5a.5.5,0,0,0,0,1H5v10a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-10h2v6a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-6h3.5a.5.5,0,0,0,0-1ZM10,22H6V2h4Zm8-4H14V6h4Z"/></svg>',
                title: 'Alignment to middle',
            },
            {
                name: 'itemsAlignment',
                toggle: 'itemsAlignment',
                value: 'bottom',
                icon: '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.5,23H1.5a.5.5,0,0,1,0-1h21a.5.5,0,0,1,0,1Z"/><path d="M18,20H14V9h4Zm.5-12h-5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V8.5A.5.5,0,0,0,18.5,8Z"/><path d="M10,20H6V2h4Zm.5-19h-5a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V1.5A.5.5,0,0,0,10.5,1Z"/></svg>',
                title: 'Alignment to bottom',
            }
        ];
    }

    /**
     * Makes buttons with tunes: add background, add border, stretch banner
     *
     * @param {BannerToolData} toolData - generate Elements of tunes
     * @returns {Element}
     */
    render(toolData) {
        const tunes = ImagesGridBlockTunes.tunes.concat(this.actions);

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            toggle: tune.toggle,
            isActive: tune.value && toolData[tune.name] === tune.value,
            onActivate: () => {
                if (typeof tune.action === 'function') {
                    tune.action(tune.name);
                    return;
                }
                this.onChange(tune.name, tune.value);
            },
        }));
    }

    confirmTuneValueOrGetFirstValue(tuneName, tuneValue) {
        const confirmed = ImagesGridBlockTunes.tunes.find(item => item.name === tuneName && item.value === tuneValue);
        if (confirmed !== undefined) {
            return confirmed.value;
        }
        return ImagesGridBlockTunes.tunes.filter(item => item.name === tuneName)[0].value;
    }
}
