import React from 'react';
import ReactDOM from 'react-dom';
import { make } from '../../utilities/Utilities';
import ImageItemsUI from './ImageItem';
import CarouselSlider from '../../../common/CarouselSlider'


/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class ImagesCarouselBlockUI {

    /**
     * @param {object} ui - Banner tool Ui module
     * @param {object} ui.api - Editor.js API
     * @param {BannerConfig} ui.config - user config
     * @param {Function} ui.onSelectFile - callback for clicks on Select file button
     * @param {boolean} ui.readOnly - read-only mode flag
     */
    constructor({ api, config, data, setImageDraftData, readOnly }) {
        this.api = api;
        this.config = config;
        this.setImageDraftData = setImageDraftData;
        this.readOnly = readOnly;
        this.nodes = {
            wrapper: make('div', [this.CSS.baseClass, ...this.CSS.wrapper]),
            blockContainer: make('div', this.CSS.blockContainer),

            sliderContainer: make('div', this.CSS.sliderContainer),

            arrowsContainer: make('div', this.CSS.arrowsContainer),
            arrowsInner: make('div', this.CSS.arrowsInner),
            arrowPrev: make('button', this.CSS.arrowPrev, {
                type: 'button'
            }),
            arrowNext: make('button', this.CSS.arrowNext, {
                type: 'button'
            }),
        };

        this.nodes.arrowsInner.appendChild(this.nodes.arrowPrev);
        this.nodes.arrowsInner.appendChild(this.nodes.arrowNext);
        this.nodes.arrowsContainer.appendChild(this.nodes.arrowsInner);

        this.nodes.blockContainer.appendChild(this.nodes.sliderContainer);
        this.nodes.blockContainer.appendChild(this.nodes.arrowsContainer);

        this.nodes.wrapper.appendChild(this.nodes.blockContainer);

        this.imageItems = [];
        this.imagesCarouselElement = undefined;
        this.initImagesCarousel(data);
    }

    /**
     * CSS classes
     *
     * @returns {object}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            button: this.api.styles.button,

            /**
             * Tool's classes
             */
            wrapper: ['imagescarousel-tool', 'story-images-carousel', 'my-5'],
            blockContainer: ['px-0'],

            sliderContainer: ['position-relative'],

            arrowsContainer: ['d-flex', 'justify-content-center', 'slider-controller', 'my-4'],
            arrowsInner: ['btn-group', 'ml-1'],
            arrowPrev: ['btn', 'btn-outline-dark', 'slider-prev', 'p-3'],
            arrowNext: ['btn', 'btn-outline-dark', 'slider-next', 'p-3'],
        };
    };


    /**
     * Renders tool UI
     *
     * @param {BannerToolData} toolData - saved tool data
     * @returns {Element}
     */
    render(toolData) {
        /*
        if (this.imagesCarouselElement === undefined) {
            this.initImagesCarousel(toolData);
        }*/

        return this.nodes.wrapper;
    }

    initImagesCarousel(data) {
        if (this.imagesCarouselElement) {
            return;
        }

        const sliderOptions = Object.assign({},
            {
                itemsAlignment: data.itemsAlignment,
                fullPageWidth: true,
                scrollLock: false,
                exactWidth: true,
                slidesToShow: "auto",
                sliderClassName: "pr-4",
                //wrapperClassName: "w-full",
                sliderNextButton: this.nodes.arrowNext,
                sliderPreviousButton: this.nodes.arrowPrev,
            }, this.getCarouselSizeOptions(data.size));

        const options = {
            className: 'w-full',
            sliderOptions: sliderOptions
        };

        const slider = React.createElement(CarouselSlider, options);

        this.imagesCarouselElement = ReactDOM.render(slider, this.nodes.sliderContainer);

        if (data.images && data.images.length > 0) {
            for (let i = 0; i < data.images.length; i++) {
                this.addImage(data.images[i]);
            }
        }
    }

    getCarouselSizeOptions(size) {
        const offset = 20;
        if (size === 'small') {
            return {
                itemWidth: 200 - offset,
                responsive:
                    [
                        {
                            breakpoint: 992,
                            settings: {
                                itemWidth: 420 - offset
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                itemWidth: 350 - offset
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                itemWidth: 260 - offset
                            }
                        }
                    ]
            };
        } else if (size === 'large') {
            return {
                itemWidth: 400 - offset,
                responsive:
                    [
                        {
                            breakpoint: 992,
                            settings: {
                                itemWidth: 620 - offset
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                itemWidth: 550 - offset
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                itemWidth: 460 - offset
                            }
                        }
                    ]
            };
        } else {
            return {
                itemWidth: 300 - offset,
                responsive:
                    [
                        {
                            breakpoint: 992,
                            settings: {
                                itemWidth: 520 - offset
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                itemWidth: 450 - offset
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                itemWidth: 360 - offset
                            }
                        }
                    ]
            };
        }
    }

    addImage(itemData) {
        if (this.imagesCarouselElement === undefined) {
            return false;
        }

        const imageItem = new ImageItemsUI({
            api: this.api,
            config: this.config,
            setImageDraftData: this.setImageDraftData,
            imageUIIndex: this.imageItems.length,
            itemData: itemData,
        });

        this.imageItems.push(imageItem);

        this.imagesCarouselElement.addItem(imageItem.render());

        return imageItem;
    }


    removeLastImage() {
        if (this.imagesCarouselElement === undefined) {
            return false;
        }

        if (!this.imageItems || !Array.isArray(this.imageItems) ||
            this.imageItems.length <= 1) {
            return false;
        }

        if (this.imageItems[this.imageItems.length - 1].isInloadingState === true) {
            return false;
        }

        this.imagesCarouselElement.removeItem(this.imageItems.length - 1);
        this.imageItems.pop()
        return true;
    }


    /**
     * Apply visual representation of activated tune
     *
     * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
     * @param {boolean} status - true for enable, false for disable
     * @returns {void}
     */
    applyTune(tuneName, status) {
        if (tuneName === 'addImage' || tuneName === 'addThreeImages' || tuneName === 'removeImage') {
            return;
        }

        if (tuneName === 'size') {
            this.imagesCarouselElement.setItemsSize(this.getCarouselSizeOptions(status));
        }

        if (tuneName === 'itemsAlignment') {
            this.imagesCarouselElement.setItemsAlignment(status);
        }


        if (tuneName === 'stretched') {
            this.nodes.blockContainer.classList.toggle('container', !!!status);
            this.nodes.blockContainer.classList.toggle('container-fluid', !!status);
        }


        //this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${tuneName}`, status);
    }

    anyImageItemLoading() {
        for (var i = 0; i < this.nodes.imageItems.length; i++) {
            if (this.nodes.imageItems.inloadingState === true) {
                return true;
            }
        }
        return false;
    }
}
