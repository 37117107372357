import React, { Component, Fragment } from 'react';

import { MContext } from '../../common/HelpProvider';

export default class ProductDetailsHelp extends Component {

    constructor(props) {
        super(props);
    }

    static get FocusType() {
        return {
            NONE: null,
            TITLE: 'title',
            TAGLINE: 'tagline',
            PRICING: 'pricing',
            PRODUCTTYPE: 'producttype',
            RETURNS: 'returns',
            DESCRIPTION: 'desc',
            SIZEVARIANTS: 'sizevariants',
            SIZING: 'sizing',
            DELIVERY: 'delivery',
            DELIVERYSHORT: 'deliveryshort',
            GALLERY: 'gallery',
            STORY: 'story',
        };
    }

    renderInitalPageHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product</h5>
                    <p>Here you can manage your product and product details. You can select different selling options, return policy, size variants, etc. Add as much detail as possible.</p>
                    <p>It's recommended to create a story for the product, to allow customers to connect with your product. You can e.g. share details about the making process, dyeing, etc. This section is optional but recommended.</p>
                    <p>Hit the 'Save changes' button after you make changes to each section of the product. And once all sections are complete, click the 'Submit for review' button for content to be reviewed and published.</p>
                </div>
            </Fragment>
        );
    }

    renderTitleHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product</h5>
                    <p>Here you can manage your product and product details. You can select different selling options, return policy, size variants, etc. Add as much detail as possible.</p>
                    <p>It's recommended to create a story for the product, to allow customers to connect with your product. You can e.g. share details about the making process, dyeing, etc. This section is optional but recommended.</p>
                    <p>Hit the 'Save changes' button after you make changes to each section of the product. And once all sections are complete, click the 'Submit for review' button for content to be reviewed and published.</p>
                </div>
            </Fragment>
        );
    }

    renderTaglineHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product Tagline</h5>
                    <p>A short, punchy paragraph to highlight the essence of your product.</p>
                </div>
            </Fragment>
        );
    }

    renderPricingHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product Pricing</h5>
                    <p>The price (in GBP) that will be displayed to customers.</p>
                    <p>Flair is currently open to the UK only, but we are working to open the marketplace up to Europe and other regions of the world, and including different currencies. We will also help our designers with calculating import tax, and delivering internationally.</p>
                </div>
            </Fragment>
        );
    }

    renderProductTypeHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product Type</h5>
                    <p>Flair offers designers flexible options for selling their products. We want to celebrate the diversity of the designer making process and not restrict it. This is where you specify whether the product is ready-made, made-to-order, custom made and so on.</p>
                    <p>Providing customers with details of the product and the story behind it, will allow greater acceptance for longer delivery/lead times.</p>
                    <ul>
                        <li>Ready-made: product is already manufactured and can be shipped quickly.</li>
                        <li>Made-to-order: product is made when orders are made and can lead to a longer lead time.</li>
                        <li>Custom-made: product is made to customers' requirement or measurements.</li>
                        <li>Pre-order: product is set to launch, with a launch date specified in the product description and delivery.</li>
                    </ul>
                </div>
            </Fragment>
        );
    }

    renderReturnsHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Return Policy</h5>
                    <p>To simplify and instil confidence and trust into a wider range of potential customers in buying from emerging designers, Flair has a standardised return policy options. You can specify what type of return this product is eligible for - whether it's a full refund, partial refund or no refund.</p>
                    <p>Depending on your product and the making process, we strongly recommend full refund for a better customer experience and wider brand reach.</p>
                    <ul>
                        <li>Full refund: product is eligible for a full refund within 14 days of the purchase date. This does not include the shipping fee.</li>
                        <li>Half refund: product is eligible for a 50% refund. The other 50% goes towards the designer's material and labour costs.</li>
                        <li>No refund: product is not eligible for a refund.</li>
                    </ul>
                </div>
            </Fragment>
        );
    }

    renderDescHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product Description</h5>
                    <p>Provide a descriptive summary about the product - it could include details such as material, colours and similar. Add as much detail as possible.</p>
                </div>
            </Fragment>
        );
    }

    renderGalleryHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Gallery</h5>
                    <p>Add pictures of your product, preferably e-comm shots from different angles to give the customer a full view.</p>
                    <p>Ideal shots include:</p>
                    <ul>
                        <li>Full product view with model</li>
                        <li>Product in a full look</li>
                        <li>Back side shot</li>
                        <li>Front side shot</li>
                        <li>Product close-up/details</li>
                    </ul>
                    <p>Reach out to the Flair team for help and advice on this.</p>
                    <p>Make sure the image quality is good and resolution high.</p>
                </div>
            </Fragment>
        );
    }

    renderSizeVariantsHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Size variants</h5>
                    <p>Include all the size variants available for your product (and optionally stock availability)</p>
                </div>
            </Fragment>
        );
    }
    renderSizingInformationHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Sizing information</h5>
                    <p>Size is tricky when buying online. Please provide detailed size information including charts and information to enable customers to measure at home.</p>
                    <p>including detailed sizing information about your product helps build trust among customers.</p>
                </div>
            </Fragment>
        );
    }
    renderDeliveryHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Delivery message</h5>
                    <p>Include as much detail as possible about the delivery. Lead times can vary depending on the product type (e.g. if it's ready-made or custom order) but also depending on where in the world you are based.</p>
                    <p>Be as descriptive as possible to set the right expectations for the customer.</p>
                    <p>If the delivery information is the same for all your products, you can set a standardised message via the Store settings tab (in the main menu) instead, saving you adding it manually for each individual product.</p>
                </div>
            </Fragment>
        );
    }

    renderDeliveryShortHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Short delivery message</h5>
                    <p>A short summary of the delivery that will be displayed in customers' basket and order details. E.g. 'Standard free delivery'</p>
                    <p>If the delivery information is the same for all your products, you can set a standardised message via the Store settings tab (in the main menu) instead, saving you adding it manually for each individual product.</p>
                </div>
            </Fragment>
        );
    }

    renderStoryHelp() {
        return (
            <Fragment>
                <p className="text-center">Example:</p>
                <img src="https://ik.imagekit.io/flair/tr:w-600,f-auto/Designer_Portal/Tips/Brand/brand_gXwm8H7M2" className="w-100" />
                <div className="mt-4 px-3">
                    <h5>Tip: Product Story</h5>
                    <p>It is recommended to create a story for the product, to allow customers to connect with your product. You can e.g. share details about the making process, dyeing, etc.</p>
                    <p>This section is optional but recommended. You can upload content such as text, images, videos.</p>
                </div>
            </Fragment>
        );
    }

    render() {
        const { focus } = this.props;
        return (
            <div className="sticky-top pt-5">
                <MContext.Consumer>
                    {(context) => (
                        <div>
                            {context.state.help === ProductDetailsHelp.FocusType.NONE &&
                                this.renderInitalPageHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.TITLE &&
                                this.renderTitleHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.PRICING &&
                                this.renderPricingHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.PRODUCTTYPE &&
                                this.renderProductTypeHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.RETURNS &&
                                this.renderReturnsHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.TAGLINE &&
                                this.renderTaglineHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.DESCRIPTION &&
                                this.renderDescHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.SIZEVARIANTS &&
                                this.renderSizeVariantsHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.SIZING &&
                                this.renderSizingInformationHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.DELIVERYSHORT &&
                                this.renderDeliveryShortHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.DELIVERY &&
                                this.renderDeliveryHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.GALLERY &&
                                this.renderGalleryHelp()
                            }
                            {context.state.help === ProductDetailsHelp.FocusType.STORY &&
                                this.renderStoryHelp()
                            }
                        </div>
                    )}
                </MContext.Consumer>
            </div>
        );
    }
}
