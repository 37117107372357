import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authService from '../../api-authorization/AuthorizeService';

import { Image } from '../../common/UIElements';
import { ProductMediaConfig } from '../../utils/MediaConfigs';

export class OrdersList extends Component {
    static displayName = OrdersList.name;

    constructor(props) {
        super(props);
        this.state = {
            orders: [], loading: true,
            designerId: props.match.params.designerId
        };
        this.populateData = this.populateData.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderDataTable(list, designerId) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>CreatedBy</th>
                        <th>CollectionTitle</th>
                        <th>IsVisible</th>
                        <th>BrandDataStatus</th>
                        <th>DataDraftStatus</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((entry, i) =>
                        <tr key={i}>
                            <td>{entry.id}</td>
                            <td>{entry.createdBy}</td>
                            <td>{entry.title}</td>
                            <td>{entry.isVisible}</td>
                            <td>{ }</td>
                            <td>{ }</td>
                            <td>
                                <Link to={`/manage/${designerId}/collections/${entry.id}/details`}>View</Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }


    static renderEmptyMessage(designerId) {
        return (
            <div className="my-5 py-5 mx-auto text-center" style={{ maxWidth: '600px' }}>
                <h1 id="tabelLabel" className="my-4">STORE: <br />Customer Orders</h1>
                <div>
                    <p>Once you start receiving orders for your products, they will appear here. You can overview and manage your orders, updating their status throughout the process.</p>
                    <p>To help increase customer engagement with your brand store, we recommend that you build your <Link to={`/manage/${designerId}/brand`}>brand story</Link> for customers to connect with your brand.</p>
                    <p>You can also <Link to={`/manage/${designerId}/store/products/new`}>add new products</Link> and <Link to={`/manage/${designerId}/store/products`}>manage your products</Link> as you grow.</p>
                </div>
            </div>
        );
    }


    render() {
        const { orders, designerId } = this.state;

        if (orders == null || orders.length <= 0) {
            return OrdersList.renderEmptyMessage(designerId);
        }

        const contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : OrdersList.renderDataTable(orders, designerId);

        return (
            <div>
                <h1 id="tabelLabel">Orders list</h1>
                <p>Orders fetched from server.</p>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/brand/${this.state.designerId}/store/orders`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({ orders: data, loading: false });
    }
}
