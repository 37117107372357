import { make } from '../../utilities/Utilities';
import ImageItemsUI from './ImageItem';


/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class ImagesGridBlockUI {

    /**
     * @param {object} ui - Banner tool Ui module
     * @param {object} ui.api - Editor.js API
     * @param {BannerConfig} ui.config - user config
     * @param {Function} ui.onSelectFile - callback for clicks on Select file button
     * @param {boolean} ui.readOnly - read-only mode flag
     */
    constructor({ api, config, data, setImageDraftData, readOnly }) {
        this.api = api;
        this.config = config;
        this.setImageDraftData = setImageDraftData;
        this.readOnly = readOnly;
        this.nodes = {
            wrapper: make('div', [this.CSS.baseClass, ...this.CSS.wrapper]),
            blockContainer: make('div', this.CSS.blockContainer),
        };

        this.nodes.wrapper.appendChild(this.nodes.blockContainer);
        
        this.applyItemsAlignment(data.itemsAlignment);

        this.imageItems = [];
        this.initImagesGrid(data);
    }

    /**
     * CSS classes
     *
     * @returns {object}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            button: this.api.styles.button,

            /**
             * Tool's classes
             */
            wrapper: ['imagesgrid-tool', 'story-images-grid', 'my-5'],
            blockContainer: ['row', 'px-0'],
        };
    };


    /**
     * Renders tool UI
     *
     * @param {BannerToolData} toolData - saved tool data
     * @returns {Element}
     */
    render(toolData) {
        /*
        if (this.imagesGridElement === undefined) {
            this.initImagesGrid(toolData);
        }*/

        return this.nodes.wrapper;
    }

    initImagesGrid(data) {
        if (data.images && data.images.length > 0) {
            for (let i = 0; i < data.images.length; i++) {
                this.addImage(data.images[i], data.totalPerRow);
            }
        }
    }

    addImage(itemData, size) {
        const imageItem = new ImageItemsUI({
            api: this.api,
            config: this.config,
            setImageDraftData: this.setImageDraftData,
            imageUIIndex: this.imageItems.length,
            itemData: itemData,
            gridSize: size
        });

        this.imageItems.push(imageItem);

        this.nodes.blockContainer.appendChild(imageItem.render());

        return imageItem;
    }


    removeLastImage() {
        if (!this.imageItems || !Array.isArray(this.imageItems) ||
            this.imageItems.length <= 1) {
            return false;
        }

        if (this.imageItems[this.imageItems.length - 1].isInloadingState === true) {
            return false;
        }

        this.imageItems[this.imageItems.length - 1].deleteElement();
        this.imageItems.pop()
        return true;
    }


    /**
     * Apply visual representation of activated tune
     *
     * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
     * @param {boolean} status - true for enable, false for disable
     * @returns {void}
     */
    applyTune(tuneName, status) {
        if (tuneName === 'addImage' || tuneName === 'addThreeImages' || tuneName === 'removeImage') {
            return;
        }

        if (tuneName === 'totalPerRow') {
            if (this.imageItems && this.imageItems.length > 0) {
                for (let i = 0; i < this.imageItems.length; i++) {
                    this.imageItems[i].updateGridSize(status);
                }
            }
        }

        if (tuneName === 'itemsAlignment') {
            this.updateItemsAlignment(status);
        }


        //if (tuneName === 'stretched') {
            //this.nodes.blockContainer.classList.toggle('container', !!!status);
            //this.nodes.blockContainer.classList.toggle('container-fluid', !!status);
        //}


        //this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper[0]}--${tuneName}`, status);
    }

    anyImageItemLoading() {
        for (var i = 0; i < this.nodes.imageItems.length; i++) {
            if (this.nodes.imageItems.inloadingState === true) {
                return true;
            }
        }
        return false;
    }


    applyItemsAlignment(alignment) {
        if(alignment === 'middle') {
            this.nodes.blockContainer.classList.add("align-items-center");
        } else if(alignment === 'bottom') {
            this.nodes.blockContainer.classList.add("align-items-end");
        } else {
            this.nodes.blockContainer.classList.add("align-items-start");
        }
    }

    updateItemsAlignment(alignment) {
        this.nodes.blockContainer.classList.remove("align-items-center", "align-items-end", "align-items-start");
        this.applyItemsAlignment(alignment);
    }

}
