import React, { Component, Fragment } from 'react';
import authService from '../../api-authorization/AuthorizeService'
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';

import { uploadSelectedFile } from '../../utils/FileUploader';
import { validateImage } from '../../utils/Validation';

import { ProductMediaConfig, getMediaUrl } from '../../utils/MediaConfigs';

export class ProductDetailsGalleryUpload extends Component {
    static displayName = ProductDetailsGalleryUpload.name;

    constructor(props) {
        super(props);
        this.state = {
            designerId: props.designerId,
            productId: props.productId,
            //media: props.media,
            loading: false,
            uploadPercentage: null,
            error: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadProgress = this.uploadProgress.bind(this);
        this.setError = this.setError.bind(this);
    }

    //componentWillReceiveProps(props) {
        //this.setState({ media: props.media })
    //}

    setError(error) {
        this.props.setError(error);
        this.setState({ loading: false, uploadPercentage: null });
    }

    render() {
        const { data, loading, uploadPercentage } = this.state;

        if (!this.props.media || this.props.media.length <= 0) {
            return (
                <div style={{ maxWidth: '400px' }} className="text-center p-4">
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="NewImage">Add photos of your product</Label>
                            <div className="mt-0 mb-3">
                                <Button color="dark" size="sm" disabled={loading}>{!loading || uploadPercentage === null ? 'Add new photo' : 'Uploading ' + uploadPercentage + '%'}</Button>
                            </div>
                            <FormText color="muted">
                                <ul>
                                    <li>Acceptable formats: <b>JPEG, PNG and SVG</b></li>
                                    <li>Max file size: <b>30MB</b></li>
                                    <li>Upload the highest image resolution, smaller images may become pixelated when they are resized.</li>
                                </ul>
                            </FormText>
                        </FormGroup>
                    </Form>
                </div>
            );
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label for="NewImage">Add photos of your product</Label>
                    <div className="mt-0 mb-3">
                        <Button color="dark" size="sm" disabled={loading}>{!loading || uploadPercentage === null ? 'Add new photo' : 'Uploading ' + uploadPercentage + '%'}</Button>
                    </div>
                    <FormText color="muted">
                        <ul>
                            <li>Acceptable formats: <b>JPEG, PNG and SVG</b></li>
                            <li>Max file size: <b>30MB</b></li>
                            <li>Upload the highest image resolution, smaller images may become pixelated when they are resized.</li>
                        </ul>
                    </FormText>
                </FormGroup>
            </Form>
        );
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (this.state.loading === true) {
            return false;
        }

        console.log('submitting..');
        console.log(this.state);

        const token = await authService.getAccessToken();
        if (!token) {
            this.props.setError(`Could not retrieve authentication`);
            return;
        }

        const { data } = this.state;

        uploadSelectedFile({
            config: {
                url: `/api/brand/${this.state.designerId}/store/products/${this.state.productId}/edit/image-upload`,
                reqBody: {
                },
                types: 'image/*',
            },
            onValidate: function (file) {
                return validateImage(file, 10, 10);
            }.bind(this),
            onUploadStart: function () {
                this.setState({ loading: true, uploadPercentage: 0 });
            }.bind(this),
            onUploadDone: function (res) {
                console.log('new product image submitted');
                console.log(res);
                //success
                this.setState({ loading: false, uploadPercentage: null, complete: true });
                //this.props.scrollPageToTop();
                this.props.updateData(function () {
                    //update button state
                });
            }.bind(this),
            onError: function (error) {
                this.setError(error);
                console.log(error);

            }.bind(this),
            onUploadProgress: this.uploadProgress
        });
    }
    
    uploadProgress(ev, percentage) {
        this.setState({ uploadPercentage: Math.round(percentage) });
        console.log(`${percentage}%`);
    }

}
