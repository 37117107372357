import React, { Component, Fragment } from 'react';
/*import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';


const routes = [
    { path: '/', breadcrumb: 'Dashboard' },
    { path: '/fetch-data', breadcrumb: 'Custom Example' },
];
*/

const BreadcrumbNav = () => {
    /*
    const breadcrumbs = useBreadcrumbs(routes);

    return (
        <ol className="breadcrumb px-0 py-2" aria-label="breadcrumb">
          {breadcrumbs.map(({
            match,
            breadcrumb
          }, index) => {
              if(index >= breadcrumbs.length - 1) {
                  return (
                    <li key={match.url} className="breadcrumb-item active" aria-current="page">
                        {breadcrumb}
                    </li>
                  );
              } else {
                  return (
                    <li key={match.url} className="breadcrumb-item">
                        <Link to={match.url}>{breadcrumb}</Link>
                    </li>
                  );
              }
          })}
        </ol>
    );
    */
    return (<Fragment />);
}
export default BreadcrumbNav;
